import React from 'react';
import Header from '../component/sharedComponents/header';
import FooterUser from '../component/sharedComponents/footer';
import ResetPasswordForm from '../component/auth/resetPasswordForm';
const ResetPasswordPage =()=>{
    return(
        <div>
<Header/>
<div className='mt-20'>
<ResetPasswordForm/>
</div>
<FooterUser/>
        </div>
    )
}

export default ResetPasswordPage;