import React from 'react';
import CustomLines from '../home/customLines';
import CustomBigText from '../sharedComponents/customBigText';
import CustomBlockCard from './customBlockCards';
import ImageBlockOne from '../../assets/images/blockOne.png';
import ImageBlockTwo from '../../assets/images/blockTwo.png';
import ImageBlockedThree from '../../assets/images/blockThree.png';
import ImageBlockedFour from '../../assets/images/blockFour.png';
import ImageBlockedFive from '../../assets/images/blockFive.png';
import ImageBlockedSix from '../../assets/images/no alcohol 2 1.png';
import { useTranslation } from 'react-i18next';
const BlockedItemsSection =()=>{
      const {t} = useTranslation();
    return (
        <div className="bg-white flex flex-col items-center justify-center text-center px-custom-padding ">
        <CustomLines />
     <div className=''>
     <CustomBigText title={t('customBigText.prohibitedItems')} />
      
     </div>
     <div className='md:flex md:space-x-6 mt-20 mb-20 w-full'>
   <div className='w-full md:w-1/6'>
   <CustomBlockCard
      image={ImageBlockOne}
      title={t('blockedItems.weaponsAndExplosives')}
      />
   </div>
      
   <div className='w-full md:w-1/6'>
   <CustomBlockCard
      image={ImageBlockTwo}
      title={t('blockedItems.preciousMaterials')}
      />
   </div>
   <div className='w-full md:w-1/6'>
   <CustomBlockCard
      image={ImageBlockedThree}
      title={t('blockedItems.medicalSupplies')}
      />
   </div>
   <div className='w-full md:w-1/6'>
    <CustomBlockCard
      image={ImageBlockedFour}
      title={t('blockedItems.fakeBrands')}
      />
    </div>
    <div className='w-full md:w-1/6'>
    <CustomBlockCard
      image={ImageBlockedFive}
      title={t('blockedItems.batteries')}
      />
   </div>
   <div className='w-full md:w-1/6'>
        <CustomBlockCard
      image={ImageBlockedSix}
      title={t('blockedItems.allLiquids')}
      /></div>
     </div>

          </div>
    )
}

export default BlockedItemsSection;