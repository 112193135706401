import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchMainEuropeanWebsites } from "../../redux/reducer/europaWebsitesMainReducer"; // Adjust path as necessary
import CustomLines from "../home/customLines";
import CustomBigText from "../sharedComponents/customBigText";
import CategoryCard from "./cardCategory";
import ImageSix from "../../assets/images/categorySix.png";
import ImageFive from "../../assets/images/categoryFive.png";
import ImageFour from "../../assets/images/categoryFour.png";
import ImageThree from "../../assets/images/categoryThree.png";
import ImageTwo from "../../assets/images/categoryTwo.png";
import ImageOne from "../../assets/images/categoryOne.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const ChooseCategoryEuropeanSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t,i18n} = useTranslation();
  // Static images for the categories
  const staticImages = [ImageFive,  ImageFour, ImageThree, ImageTwo, ImageOne, ImageSix];

  // Fetching state data for European websites
  const { mainEuropeanWebsites, status, error } = useSelector((state) => state.websitesEuropean);

  useEffect(() => {
    if (status === "idle" || !mainEuropeanWebsites.length) {
      dispatch(fetchMainEuropeanWebsites());
    }
  }, [dispatch, status, mainEuropeanWebsites.length]);

  // Handling loading, error, and empty data states
  if (status === "loading") return <p>Loading...</p>;
  if (status === "failed") return <p className="text-red-500">Error: {error}</p>;
  if (!mainEuropeanWebsites || mainEuropeanWebsites.length === 0) return <p>No websites available</p>;

  const handleClick = (name, websites) => {
    if (!websites || websites.length === 0) {
      console.error("No websites available for this category.");
      return;
    }
  
   // Navigate to the desired path
   navigate(`/ChooseShoppingEroupaPage/${encodeURIComponent(name)}`, { state: { websites } });

   // Force a full page reload
   window.location.reload();
  };
  
  return (
    <div className="bg-white flex flex-col items-center justify-center text-center px-4 lg:px-custom-padding">
      <CustomLines />
      <CustomBigText title={t("categoryTitle")}/>
      <div className="lg:px-custom-padding bg-white py-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-6">
          {mainEuropeanWebsites.map((website, index) => {
            const title = i18n.language === "ar" ? website.name?.ar : website.name?.en;
            return (
              <div
                key={website.id || website._id || index} // Fallback to index if no id is available
                className="flex flex-col items-center cursor-pointer"
                onClick={() => handleClick(title, website.websites)}
              >
                <CategoryCard
                  title={title || "Unnamed Website"}
                  image={staticImages[index % staticImages.length]}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ChooseCategoryEuropeanSection;
