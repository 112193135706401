import React, { useState } from "react";
import VisaImageOne from '../../assets/images/card (1).png';
import VisaImageTwo from '../../assets/images/card.png';
import VisaImageThree from '../../assets/images/visa.png';
import VisaImageFour from '../../assets/images/paypal.png';
import VisaImageFive from '../../assets/images/american-express.png';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createPayment } from "../../redux/reducer/paymentReducer"; // Import the action
// Import the v4 method from uuid
import { v4 as uuidv4 } from 'uuid';

const PaymentForm = () => {

// Generate a UUID and print it to the console
const sessionId = uuidv4();
  const [formData, setFormData] = useState({
    cardNumber: "",
    cvv: "",
    expiryMonth: "",
    expiryYear: "",

  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const paymentState = useSelector((state) => state.payment); // Access the payment state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Dispatch the createPayment action with formData as the payload
    dispatch(createPayment(formData));
  };

  return (
    <div className="my-5 flex flex-col items-center">
      <form
        className="w-full"
        onSubmit={handleSubmit}
      >
        <div className="flex w-full">
          <div className="mb-4 w-full ml-2">
            <label
              htmlFor="cardNumber"
              className="block text-sm font-medium text-gray-700"
            >
              {t("CreditCardNumber")}
            </label>
            <input
              type="text"
              id="cardNumber"
              name="cardNumber"
              value={formData.cardNumber}
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded-lg bg-white text-black
              focus:outline-none focus:ring-2 focus:ring-yellow-500"
              placeholder="•••• •••• •••• ••••"
              required
            />
          </div>

          <div className="mb-4 w-full">
            <label
              htmlFor="cvv"
              className="block text-sm font-medium text-gray-700"
            >
              {t("CVV")}
            </label>
            <input
              type="password"
              id="cvv"
              name="cvv"
              value={formData.cvv}
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded-lg bg-white text-black 
              focus:outline-none focus:ring-2 focus:ring-yellow-500"
              placeholder="•••"
              required
            />
          </div>
        </div>

        <div className="mb-4 flex space-x-4 rtl:space-x-reverse">
          <div className="flex-1">
            <label
              htmlFor="expiryMonth"
              className="block text-sm font-medium text-gray-700"
            >
              {t("ExpiryMonth")}
            </label>
            <select
              id="expiryMonth"
              name="expiryMonth"
              value={formData.expiryMonth}
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded-lg bg-white text-black 
              focus:outline-none focus:ring-2 focus:ring-yellow-500"
              required
            >
              <option value="" disabled>
                {t("SelectMonth")}
              </option>
              {[...Array(12).keys()].map((month) => (
                <option key={month + 1} value={month + 1}>
                  {month + 1}
                </option>
              ))}
            </select>
          </div>

          <div className="flex-1">
            <label
              htmlFor="expiryYear"
              className="block text-sm font-medium text-gray-700"
            >
              {t("ExpiryYear")}
            </label>
            <select
              id="expiryYear"
              name="expiryYear"
              value={formData.expiryYear}
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded-lg bg-white text-black 
              focus:outline-none focus:ring-2 focus:ring-yellow-500"
              required
            >
              <option value="" disabled>
                {t("SelectYear")}
              </option>
              {[...Array(10).keys()].map((year) => (
                <option key={year} value={new Date().getFullYear() + year}>
                  {new Date().getFullYear() + year}
                </option>
              ))}
            </select>
          </div>
        </div>

        <button
          type="submit"
          className="w-full py-2 px-4 bg-yellow-500 text-white font-bold rounded-lg hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400"
        >
          {t("SubmitPayment")}
        </button>
      </form>

      {/* Display payment state */}
      {paymentState.loading && <p>Processing payment...</p>}
      {
  paymentState.error && (
    <div>
      {/* Display the general error message */}
      <p className="text-red-500">{paymentState.error.Message}</p>

      {/* Display validation errors if they exist */}
      {paymentState.error.ValidationErrors && paymentState.error.ValidationErrors.length > 0 && (
        <ul className="text-red-500">
          {paymentState.error.ValidationErrors.map((error, index) => (
            <li key={index}>{error.Name}: {error.Error}</li>
          ))}
        </ul>
      )}
    </div>
  )
}
      {paymentState.paymentDetails && (
        <div className="mt-4">
          <p>Payment Successful!</p>
          <pre>{JSON.stringify(paymentState.paymentDetails, null, 2)}</pre>
        </div>
      )}

      <div className="mt-6 flex justify-center space-x-4 rtl:space-x-reverse">
        <img
          src={VisaImageOne}
          alt="Visa"
          className="h-6"
        />
        <img
          src={VisaImageTwo}
          alt="Visa"
          className="h-6"
        />
        <img
          src={VisaImageThree}
          alt="Mastercard"
          className="h-6"
        />
        <img
          src={VisaImageFour}
          alt="Discover"
          className="h-6"
        />
        <img
          src={VisaImageFive}
          alt="American Express"
          className="h-6"
        />
      </div>
    </div>
  );
};

export default PaymentForm;
