import React from 'react';

const CustomTitleAndSubTitle =({title ,subTitle})=>{
    return(
        <div>
          <h3 className='text-lg text-gray-700'>
           {title}
           </h3>
           <h3 className='text-xl text-black'>
           {subTitle}
           </h3>
        </div>
    )
}

export default CustomTitleAndSubTitle;