import React from "react";
import Header from "../component/sharedComponents/header";
import AskSection from '../component/home/askSection';
import FooterUser from '../component/sharedComponents/footer';
import ImportantServices from '../component/home/importantServices';
import  CustomReviewsSection from '../component/home/clientsReviewsSection';
import CalcalateShippingSection from '../component/home/calcalateShippingPriceSection';
import AllShippingSection from '../component/home/allShippingSection';
import ServicesBuySection from '../component/home/servicesBuySection';
import ShoppingEuropeSection from '../component/home/shoppingEuropeSection';
import ShoppingTrukeySection from '../component/home/shoppingTruckeySection';
import ChooseItemsSection from '../component/home/chooseItemsSection';
import ScrollerSection from '../component/home/scrollerSection';
const Home =()=>{
    return (
        <div className="">
        <Header />
        <ScrollerSection/>
        <ChooseItemsSection/>
        <ShoppingTrukeySection/>
        <ShoppingEuropeSection/>
        <ServicesBuySection/>
        <AllShippingSection/>
        <CalcalateShippingSection/>
        < CustomReviewsSection/>
        <ImportantServices/>
        <AskSection/>
        <FooterUser/>
        </div>
    )
}
export default Home;