import React from 'react';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline';
const CustomArrowDown =()=>{
    return(
    <div>
    <ChevronDoubleDownIcon className='w-6 h-6 text-black'/>   
    </div>
    )
}

export default CustomArrowDown;