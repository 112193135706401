import React from 'react';

const CustomLines = () => {
  return (
    <div className="flex justify-between items-center  ">
      {/* Line 1 */}
      <div className="bg-black w-[2px] h-[100px] mr-20 md:mr-40"></div>
      {/* Space Between */}
      <div className="bg-black w-[2px] h-[100px]"></div>
    </div>
  );
};

export default CustomLines;
