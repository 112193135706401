import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import API_BASE_URL from '../constent';

// 1. Fetch all Main European Websites
export const fetchMainEuropeanWebsites = createAsyncThunk(
  'websitesEuropean/fetchMainEuropeanWebsites',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/mainEuropWebsites`, {
        headers: {
          Authorization: `Bearer <JWT_TOKEN>`, // Replace with actual JWT token
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'An error occurred'
      );
    }
  }
);

// 2. Fetch all European Websites
export const fetchEuropeanWebsites = createAsyncThunk(
  'websitesEuropean/fetchEuropeanWebsites',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/europWebsites`, {
        headers: {
          Authorization: `Bearer <JWT_TOKEN>`, // Replace with actual JWT token
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'An error occurred'
      );
    }
  }
);

// 3. Fetch European Websites by IDs
export const fetchWebsitesByIds = createAsyncThunk(
  'websitesEuropean/fetchWebsitesByIds',
  async (ids, { rejectWithValue }) => {
    try {
      const responses = await Promise.all(
        ids.map((id) => axios.get(`${API_BASE_URL}/europWebsites/${id}`, {
          headers: {
            Authorization: `Bearer <JWT_TOKEN>`, // Replace with actual JWT token
          },
        }))
      );
      return responses.map((response) => response.data);
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'An error occurred'
      );
    }
  }
);

// 4. Fetch Notifications Count
export const fetchNotificationsCount = createAsyncThunk(
  'websitesEuropean/fetchNotificationsCount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/mainEuropeanWebsites/get/notifications/counts`,
        {
          headers: {
            Authorization: `Bearer <JWT_TOKEN>`, // Replace with actual JWT token
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'An error occurred'
      );
    }
  }
);

// Initial state
const initialState = {
  mainEuropeanWebsites: [],
  europeanWebsites: [],
  notificationsCount: null,
  loading: false,
  error: null,
};

// Slice
const websitesEuropeanSlice = createSlice({
  name: 'websitesEuropean',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    clearNotificationsCount: (state) => {
      state.notificationsCount = null;
    },
    clearWebsites: (state) => {
      state.mainEuropeanWebsites = [];
      state.europeanWebsites = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Main European Websites
      .addCase(fetchMainEuropeanWebsites.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMainEuropeanWebsites.fulfilled, (state, action) => {
        state.loading = false;
        state.mainEuropeanWebsites = action.payload;
      })
      .addCase(fetchMainEuropeanWebsites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch European Websites
      .addCase(fetchEuropeanWebsites.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEuropeanWebsites.fulfilled, (state, action) => {
        state.loading = false;
        state.europeanWebsites = action.payload;
      })
      .addCase(fetchEuropeanWebsites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch Notifications Count
      .addCase(fetchNotificationsCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotificationsCount.fulfilled, (state, action) => {
        state.loading = false;
        state.notificationsCount = action.payload;
      })
      .addCase(fetchNotificationsCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const { clearError, clearNotificationsCount, clearWebsites } = websitesEuropeanSlice.actions;
export default websitesEuropeanSlice.reducer;
