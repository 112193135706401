import React from "react";

export default function CustomInfoInput({
  id,
  label, // Label for the input
  type = "text",
  placeholder,
  value,
  onChange,
  icon,
}) {
  return (
    <div className="relative w-full m-4">
      {/* Arabic Label */}
      {label && (
        <label
          htmlFor={id}
          className="block mb-2 text-sm font-medium text-right text-gray-700"
          dir="rtl" // Set the label direction
        >
          {label}
        </label>
      )}
      <div className="relative">
        {/* Optional Icon */}
        {icon && (
          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-900">
            {icon}
          </span>
        )}
        {/* Arabic Input Field */}
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          dir="rtl" // Set the input text direction
          className="w-full px-10 py-3 text-black text-xs sm:text-lg border border-gray-300 rounded-lg bg-white  placeholder:text-sm  text-right"
        />
      </div>
    </div>
  );
}
