import React from "react";

const Banner = () => {
  return (
    <div className="bg-yellow-400 text-black text-center py-4">
      <p className="text-lg font-bold">عناصر إعادة التغليف</p>
    </div>
  );
};

export default Banner;
