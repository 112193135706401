import React from "react";
import Header from "../component/sharedComponents/header";
import CustomIntroSection from "../component/explainBuyPage/customIntroSection";
import FooterUser from "../component/sharedComponents/footer";
import ChooseCategoryEroupaSection from "../component/chooseCategory/chooseCategoryEroupaSection";
import { useTranslation } from "react-i18next";
const ChooseCategoryEroupaPage =()=>{
  const {t} = useTranslation();
   return (
     <div>
                      <Header/>
<div className="mt-20">
<CustomIntroSection
title={t('customIntro.europeanStores')}
/>
</div>
<ChooseCategoryEroupaSection/>
<FooterUser/>
     </div>
   )

}

export default ChooseCategoryEroupaPage;  