import React, { useRef, useState, useEffect } from "react";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const CustomDropdown = ({ menuList, menuItems, textColor, title, onSelect }) => {
  const buttonRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [selectedItem, setSelectedItem] = useState(menuList.name);

  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth); // Get the button's width
    }
  }, []);

  return (
    <Menu as="div" className="relative inline-block text-right w-full">
      <div>
        <h4 className="text-lg text-gray-800 text-right mb-2" dir="rtl">
          {title}
        </h4>
        <Menu.Button
          ref={buttonRef}
          className={`flex items-center justify-between rounded-md bg-transparent border border-gray-300 p-2 w-full font-medium text-gray-700 rtl`}
          dir="rtl"
        >
          <span className="flex-1 text-right">{selectedItem}</span>
          <ChevronDownIcon className="w-6 h-6 text-black" />
        </Menu.Button>
      </div>

      <Menu.Items
        className="absolute right-0 z-40 mt-1 origin-top-right rounded-md bg-white border border-gray-300 shadow-lg focus:outline-none"
        style={{ width: buttonWidth }}
      >
        {menuItems.map((item) => (
          <Menu.Item key={item.id}>
            {({ active }) => (
              <button
                onClick={() => {
                  setSelectedItem(item.name);
                  onSelect(item); // Pass selected item back to the parent
                }}
                className={`block px-4 py-2 text-sm text-right w-full ${active ? "bg-gray-100 text-gray-900" : "text-gray-700"}`}
              >
                {item.name}
              </button>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

export default CustomDropdown;
