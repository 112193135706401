import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAddressesByUserId } from "../../redux/reducer/userAddressReducer"; // Adjust the import path based on your file structure
import { MapPinIcon } from "@heroicons/react/24/outline";
import CustomTitleAccount from "../accountUser/customTitleAccount";
import CustomInfoInput from "../accountUser/customInfoInput";
import { useTranslation } from "react-i18next";

const AccountAddressBoxCard = () => {
  const [fullAddress, setFullAddress] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");

  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Access the address data and loading/error states from Redux
  const { addresses, loading, error } = useSelector((state) => state.userAddress);

  useEffect(() => {
    // Dispatch the thunk to fetch addresses
    dispatch(getAddressesByUserId());
  }, [dispatch]);

  useEffect(() => {
    if (addresses && addresses.length > 0) {
      // Assume the API returns the first address to display
      const address = addresses[0];
      setFullAddress(address.fullAddress || "");
      setStreet(address.street || "");
      setCity(address.city || "");
      setCountry(address.country || "");
      setPostalCode(address.postalCode || "");
      setFirstName(address.name || ""); // Adjust key names based on the API
      setPhone(address.phoneNumber || ""); // Adjust key names based on the API
    }
  }, [addresses]);

  const handleClick = () => {
    // Handle the click event if needed
  };

  return (
    <div className="w-full px-4 ">
      {/* Title Section */}
      <CustomTitleAccount
        title={t("CurrentAddress")}
        onClick={handleClick}
        icon={<MapPinIcon className="w-8 h-8 text-black" />}
      />
    <div className="flex justify-between">
          <CustomInfoInput
            id="firstName"
            label={t("Name")}
            type="text"
            placeholder={t("Name")}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            icon={<i className="fas fa-user"></i>}
          />
          <CustomInfoInput
            id="phone"
            label={t("PhoneNumber")}
            type="text"
            placeholder={t("PhoneNumber")}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            icon={<i className="fas fa-phone"></i>}
          />
        </div>
      {/* Full Address */}
     <div className="ml-6">
     <CustomInfoInput
        id="fullAddress"
        label={t("FullAddress")}
        type="text"
        placeholder={t("FullAddress")}
        value={fullAddress}
        onChange={(e) => setFullAddress(e.target.value)}
        icon={<i className="fas fa-map-marked-alt"></i>}
      />
     </div>
      <div className="flex justify-between">
        {/* City */}
        <CustomInfoInput
          id="city"
          label={t("City")}
          type="text"
          placeholder={t("City")}
          value={city}
          onChange={(e) => setCity(e.target.value)}
          icon={<i className="fas fa-city"></i>}
        />

        {/* Country */}
        <CustomInfoInput
          id="country"
          label={t("Country")}
          type="text"
          placeholder={t("Country")}
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          icon={<i className="fas fa-globe"></i>}
        />
      </div>

      {/* Loading and Error States */}
      {loading && <p>{t("Loading...")}</p>}
      {error && <p className="text-red-500">{t("Error")}: {error}</p>}
    </div>
  );
};

export default AccountAddressBoxCard;
