import React from 'react';
import { ChartBarIcon } from '@heroicons/react/24/outline';
import CustomTitleTerms from '../termsAndConditions/customTitleTerms';
import CustomTermPoints from '../termsAndConditions/customTermPoints';
import CustomTextingCard from '../termsAndConditions/customCard';
import { useTranslation } from 'react-i18next';

const ContainerCookiesPolicy = () => {
  const { t, i18n } = useTranslation();

  // Check if the current language is Arabic
  const isRTL = i18n.language === 'ar';

  return (
    <div
      className={`px-5 lg:px-40 bg-white ${isRTL ? 'rtl' : 'ltr'}`} // Set direction
      dir={isRTL ? 'rtl' : 'ltr'} // Add dir attribute for accessibility
    >
      <div
        className={`bg-white ${
          isRTL ? 'text-right' : 'text-left'
        } px-5 lg:px-20 shadow-lg py-10 lg:py-20 space-y-2`}
      >
        {/* Cookies Policy Title */}
        <CustomTitleTerms title={t('cookiesPolicyTitle')} />

        {/* Cookies Policy Points */}
        <CustomTermPoints title={t('cookiesPolicyPoint1')} />
        <CustomTermPoints title={t('cookiesPolicyPoint2')} />
        <CustomTermPoints title={t('cookiesPolicyPoint3')} />
        <CustomTermPoints title={t('cookiesPolicyPoint4')} />

        {/* Announcement */}
        <CustomTextingCard
          Icon={ChartBarIcon}
          title={t('cookiesNoticeTitle')}
          subTitle={t('cookiesNoticeSubtitle')}
        />
      </div>
    </div>
  );
};

export default ContainerCookiesPolicy;
