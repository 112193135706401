import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BASE_URL from "../constent";

// Async thunk to create shopCart item
export const createShopCartItem = createAsyncThunk(
  "shopCart/createShopCartItem",
  async (data, { rejectWithValue }) => {
    try {
      // Get the access token from localStorage
      const token = localStorage.getItem("accessToken");

      if (!token) {
        throw new Error("No access token found");
      }

      const response = await axios.post(
        `${BASE_URL}/shopCart/`,  // API endpoint for creating a cart item
        { ...data },  // The data you're sending to the server
        {
          headers: {
            Authorization: `Bearer ${token}`,  // Add the token to the Authorization header
            "Content-Type": "application/json", // Ensure the request body is JSON
          },
        }
      );

      return response.data;  // Return the response data on success
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);  // Return error if it fails
    }
  }
);

// Async thunk to get shop cart item by ID
export const getShopCartItemById = createAsyncThunk(
  "shopCart/getShopCartItemById",
  async (_, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage

    if (!token) {
      console.error("No token found in localStorage.");
      return rejectWithValue("Authentication token is missing.");
    }

    try {
      const response = await axios.get(`${BASE_URL}/shopCart/getUserCartItems/byId/`, {
        headers: {
          Authorization: `Bearer ${token}`, // Pass token in Authorization header
        },
      });

      return response.data; // Assuming the API returns the list of items
    } catch (error) {
      console.error("Error fetching shop cart item:", error.response?.data || error.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getShopCartItemByIdExpired = createAsyncThunk(
  "shopCart/getShopCartItemByIdExpired",
  async (_, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      console.error("No token found in localStorage.");
      return rejectWithValue("Authentication token is missing.");
    }

    try {
      const response = await axios.get(`${BASE_URL}/shopCart/getUserCartItems/byId/expired`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data; // Assuming the API returns a single item or a list
    } catch (error) {
      console.error("Error fetching shop cart item:", error.response?.data || error.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateShopCartItem = createAsyncThunk(
  "shopCart/updateShopCartItem",
  async ({ id, data }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage
      const response = await axios.put(
        `${BASE_URL}/shopCart/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteShopCartItem = createAsyncThunk(
  "shopCart/deleteShopCartItem",
  async (id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage
      const response = await axios.delete(`${BASE_URL}/shopCart/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add token for authentication
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const submitItemsToPricing = createAsyncThunk(
  'shopCart/submitItemsToPricing',
  async (selectedIds = [], { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage

      // Ensure selectedIds is populated before sending it in the request body
      if (selectedIds.length === 0) {
        throw new Error("No IDs provided");
      }

      // Sending the PUT request to submit items for pricing
      const response = await axios.put(`${BASE_URL}/shopCart/submitToPricing/byArrayIds`, selectedIds, {
        headers: {
          Authorization: `Bearer ${token}`, // Add token in the headers
          'Content-Type': 'application/json',
        },
      });

      return response.data; // Return response data (success message)
    } catch (error) {
      // Handle errors if any and reject the action with an error message
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const resubmitArchivedItem = createAsyncThunk(
  "shopCart/resubmitArchivedItem",
  async ({ itemId, data }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage
      const response = await axios.put(
        `${BASE_URL}/shopCart/moveFromArchive/resubmit/${itemId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Return the success response
    } catch (error) {
      // Handle the error case
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const editresubmitArchivedItem = createAsyncThunk(
  "shopCart/editresubmitArchivedItem",
  async ({ itemId, data }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken"); // Retrieve the token from localStorage

      const response = await axios.put(
        `${BASE_URL}/shopCart/editArchive/byId/${itemId}`,
        data, // The item data you want to update
        {
          headers: {
            Authorization: `Bearer ${token}`, // Bearer token in headers
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message); // Handle error
    }
  }
);


export const getUserCartItems = createAsyncThunk(
  "shopCart/getUserCartItems",
  async (status = [], { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.auth.accessToken; // Access token from auth slice
      const params = status.length ? { status } : {};
      const response = await axios.get(`${BASE_URL}/api/shopCart/byId`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`, // Add token in the headers
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Create the slice
const shopCartSlice = createSlice({
  name: "shopCart",
  initialState: {
    items: [],
    expiredItems: [],
    loading: false,
    isLoadingExpired: false,
    error: null,
    expiredError: null,
  },
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Add case reducers for each thunk
      .addCase(createShopCartItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createShopCartItem.fulfilled, (state, action) => {
        state.loading = false;
        state.newItemId = action.payload.id;
        // Save the id to localStorage for persistence
        localStorage.setItem("newItemId", action.payload.id);
        state.items.push(action.payload);
      })
      .addCase(createShopCartItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getShopCartItemById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getShopCartItemById.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(getShopCartItemById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getShopCartItemByIdExpired.pending ,(state) =>{
        state.isLoadingExpired= true;
        state.expiredError = null;
      })
      .addCase(getShopCartItemByIdExpired.fulfilled , (state ,action)  =>{
        state.isLoadingExpired = false;
        state.expiredItems = action.payload;
      })
      .addCase(getShopCartItemByIdExpired.rejected , (state ,action)=>{
        state.isLoadingExpired = false;
        state.expiredError = action.payload;
      })
      .addCase(getUserCartItems.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(deleteShopCartItem.fulfilled, (state, action) => {
        state.items = state.items.filter((item) => item.id !== action.payload.id);
      })  
       .addCase(submitItemsToPricing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitItemsToPricing.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = action.payload.message; // Assuming the success message is in `message`
      })
      .addCase(submitItemsToPricing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle error here
      })
      .addCase(resubmitArchivedItem.pending, (state) => {
        state.loading = true; // Set loading to true when the request is in progress
        state.error = null; // Reset error on new request
      })
      .addCase(resubmitArchivedItem.fulfilled, (state, action) => {
        state.loading = false; // Set loading to false when the request is successful
        // Handle the success response and update the items or any state as needed
        const updatedItem = action.payload; // Assuming this is the updated item returned
        state.items = state.items.map((item) =>
          item.id === updatedItem.id ? updatedItem : item
        );
      })
      .addCase(resubmitArchivedItem.rejected, (state, action) => {
        state.loading = false; // Set loading to false if the request fails
        state.error = action.payload || action.error.message; // Handle error message
      })
      .addCase(editresubmitArchivedItem.fulfilled, (state, action) => {
        const updatedItem = action.payload; // API response
        state.expiredItems = state.expiredItems.map((item) =>
          item.id === updatedItem.id ? updatedItem : item
        ); // Replace the updated item
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export const { clearError } = shopCartSlice.actions;
export default shopCartSlice.reducer;
