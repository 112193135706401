import React from 'react';
import { MegaphoneIcon, CreditCardIcon } from '@heroicons/react/24/outline';
import CustomBlackButton from '../sharedComponents/customBlackButton';
import { useTranslation } from "react-i18next";

const ImportantAdver = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white my-10 p-10 flex flex-col justify-center items-center">
      {/* Header Section */}
      <div className="flex items-center space-x-2">
        <MegaphoneIcon className=" w-3 h-3 md:w-6 md:h-6 text-black" />
        <h2 className="text-lg md:text-3xl font-normal text-black">
          {t("ImportantAnnouncement")}
                    </h2>
      </div>

      {/* Message Section */}
      <p className="text-xs md:text-lg text-center text-gray-900 mt-4">
{t("Message")}
      </p>

      {/* Button Section */}
      <div className="mt-6">
        <CustomBlackButton children={t("MoreDetails")} icon={CreditCardIcon} />
      </div>
    </div>
  );
};

export default ImportantAdver;
