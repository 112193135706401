import React from "react";
import CustomListBrands from './customListBrands';
import CustomFlags from "../sharedComponents/customFlags";
import CustomBlackButton  from '../sharedComponents/customBlackButton';
import { useTranslation } from "react-i18next";
const CustomShoppngCardDetials = ({ image, title, imageTitle, subTitle, children, onClick, icon: Icon }) => {
  const {t} = useTranslation();
   return (
    <div className="md:w-1/2 flex flex-col items-center justify-center space-y-6 text-center">
      {/* Title */}
      <p className="mt-4 text-gray-700">
        {title}
      </p>

      {/* Custom List Brands */}
      <CustomListBrands />

      {/* Centered Arabic Word */}
      <div className="flex items-center justify-center">
        <h2 className="text-lg text-black">
        {t("fromText")}
        </h2>
      </div>

      {/* Custom Flags */}
      <CustomFlags
        image={image}
        title={imageTitle}
      />

      {/* Subtitle */}
      <p className="mt-4 text-gray-700">
        {subTitle}
      </p>

      {/* Button */}
   <CustomBlackButton 
   onClick={onClick}
   children={children}
   icon ={Icon}
   />
    </div>
  );
};

export default CustomShoppngCardDetials;
