import React from 'react';

const CustomCardWithBox = ({ title, subTitle }) => {
  return (
    <div className="h-40 flex items-center justify-center my-10"> {/* Added a static height */}
      <div className="flex items-center">
   
        {/* Title Section */}
        <div className="bg-custom-yellow border border-black h-[150px]  lg:h-[200px] inline-block px-6 py-2 flex flex-col justify-center  text-right" style={{ direction: 'rtl' }}>
  <h2 className="text-lg lg:text-lg font-bold text-black mb-2">{title}</h2>
  <h2 className="text-sm lg:text-sm font-light text-black">{subTitle}</h2>
</div>

 
      </div>
    </div>
  );
};

export default CustomCardWithBox;
