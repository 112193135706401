import React from "react";
import image from '../../assets/images/Vector (4).png';

const CustomOrderInstructionCard = ({ title }) => {
    return (
        <div className="bg-white flex items-center justify-center p-4 m-4">
            <div className="text-center p-4">
                <img
                    src={image}
                    alt="image"
                    className="w-12 h-12 mx-auto"
                />
                <p className="text-lg text-black mt-2">
                    {title}
                </p>
            </div>
        </div>
    );
};

export default CustomOrderInstructionCard;
