import React from 'react';
import { ChevronDoubleLeftIcon } from '@heroicons/react/24/outline';
const CustomArrowLeft =()=>{
    return(
        <div>
         <ChevronDoubleLeftIcon className='w-6 h-6 text-black'/>
        </div>
    )
}

export default CustomArrowLeft;