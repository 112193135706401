import React, { useState } from "react";
import { CheckIcon } from "@heroicons/react/24/outline";

const CustomBannerShipment = ({ orderNumber }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(""); // State for selected shipping company
  
    // Shipping companies options
    const shippingCompanies = [
      { value: "company1", label: "شركة 1" },
      { value: "company2", label: "شركة 2" },
      { value: "company3", label: "شركة 3" },
      { value: "company4", label: "شركة 4" },
    ];
  
    return (
      <div
        className={`flex w-full items-center justify-between p-1 rounded-md  ${
          isChecked ? "bg-custom-yellow text-black" : "bg-white text-black"
        }`}
      >
        {/* Order Number */}
        <div className="font-bold">
          <span className="text-sm text-gray-800">رقم الطلب </span>
          <span className="text-xs text-gray-800">{orderNumber}</span>
        </div>
  
        {/* Shipping Company Dropdown */}
        <div className="flex items-center space-x-2">
  
          {/* Dropdown */}
          <select
            value={selectedCompany}
            onChange={(e) => setSelectedCompany(e.target.value)}
            className="border-2 border-gray-300 rounded-md p-1 text-sm bg-transparent"
          >
            <option value="" disabled hidden> يرجى اختيار شركات الشحن</option>
            {shippingCompanies.map((company) => (
              <option key={company.value} value={company.value}>
                {company.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };
  

export default CustomBannerShipment;
