import React from "react";

const CustomTitleTerms =({title})=>{
    return(
<h2 className="
text-lg
lg:text-3xl text-black ">
    {title}
</h2>
        
    )
}

export default CustomTitleTerms;