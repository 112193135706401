import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API_URL from "../constent";

// Async Thunks

// 1. Login User
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await axios.post(`${API_URL}/users/login`, { email, password });

      if (response.data.success) {
        return {
          user: response.data.user,
          accessToken: response.data.accessToken,
          userId :response.data.userId
        };
      } else {
        return thunkAPI.rejectWithValue({ message: response.data.status || "Login failed" });
      }
    } catch (error) {
      const message = error.response?.data?.message || "Login failed";
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// 2. Register User
export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (userData, thunkAPI) => {
    try {
      const response = await axios.post(`${API_URL}/users/signup`, userData);
      return response.data;
    } catch (error) {
      const message = error.response?.data?.message || "Registration failed";
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// Forgot Password Thunk
export const forgotPassword = createAsyncThunk(
    "auth/forgotPassword",
    async ({ email, lang }, thunkAPI) => {
      try {
        const response = await axios.post(`${API_URL}/users/forgot-password`, { email, lang });
        return response.data;
      } catch (error) {
        const message = error.response?.data?.message || "Failed to send reset email";
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

// 4. Reset Password
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ token, password }, thunkAPI) => {
    try {
      const response = await axios.post(`${API_URL}/users/reset-password`, { token, password });
      return response.data;
    } catch (error) {
      const message = error.response?.data?.message || "Failed to reset password";
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
export const logoutUser = createAsyncThunk(
  "jwtCheck/logoutUser",
  async (_, { rejectWithValue }) => {
    try {
      // Make API call to logout
      const response = await axios.post(`${API_URL}/users/logout`);
      return response.data; // Assuming the response contains some success message or status
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred while logging out");
    }
  }
);

// 5. Validate JWT
export const validateJWT = createAsyncThunk(
  "auth/validateJWT",
  async (token, thunkAPI) => {
    try {
      const response = await axios.get(`${API_URL}/users/check/JWT`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      const message = error.response?.data?.message || "Invalid or expired token";
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// Auth Slice
const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    accessToken: null,
    userId:null,
    loading: false,
    error: null,
    message: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.accessToken = null;
      state.error = null;
      state.message = null;
    },
    resetForgotPasswordState: (state) => {
        state.loading = false;
        state.success = false;
        state.error = null;
      },
  },
  extraReducers: (builder) => {
    builder
      // Login User
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.accessToken = action.payload.accessToken;
        localStorage.setItem("accessToken", action.payload.accessToken); // Save token
        localStorage.setItem("userId", action.payload.user.id); // Store user ID
        state.message = "Login successful!";
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Login failed";
      })

      // Register User
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.message = "Registration successful!";
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Registration failed";
      })

      // Forgot Password
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      // Forgot Password Fulfilled
      .addCase(forgotPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      // Forgot Password Rejected
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload?.message || "An error occurred.";
      })
      // Reset Password
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.message = "Password reset successful.";
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to reset password";
      })

      // Validate JWT
      .addCase(validateJWT.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(validateJWT.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.accessToken = action.payload.accessToken;
        state.message = "JWT is valid.";
      })
      .addCase(validateJWT.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Invalid or expired token";
      });
  },
});

export const { logout ,resetForgotPasswordState } = authSlice.actions;
export default authSlice.reducer;
