import React, { useState } from "react";
import { ChevronDoubleDownIcon, CalculatorIcon } from '@heroicons/react/24/outline';
import CalcuImage from '../../assets/images/mobileimage.png';
import CustomDropdown from '../sharedComponents/customDropDown';
import CustomCheckBox from '../sharedComponents/customCheckBox';
import CustomInput from "../sharedComponents/customInput";
import CustomButton from "../sharedComponents/customButton";
import CustomTitle from '../sharedComponents/customTitle';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch and useSelector
import { checkShipmentCost } from '../../redux/reducer/shipmentCostReducer'; // Import the action
import { useTranslation } from "react-i18next";


const CalcalateShippingSection = () => {
  const [inputValue, setInputValue] = useState(""); // State for weight
  const [selectedFrom, setSelectedFrom] = useState(null); // State for location from
  const [selectedTo, setSelectedTo] = useState(null); // State for location to
  const [hasDimensions, setHasDimensions] = useState(false); // State for dimensions checkbox
  const { t } = useTranslation();
  const dispatch = useDispatch(); 
  const { cost = null, loading = false, error = null } = useSelector((state) => state.shipment || {}); 
  const [formData, setFormData] = useState({
    quantity: "",
    weight: "",
    height: "",
    width: "",
    length: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Menu items for dropdowns (location options)
  const menuListLocationFrom = { name: t("calculateShippingSection.selectArea") };
  const menuItemsLocationFrom = [
    { id: 1, name: t("Europa"), link: '#' },
    { id: 2, name: t("Turkey"), link: '#' },
  ];

  const menuListLocationTo = { name: t("calculateShippingSection.selectArea") };
  const menuItemsLocationTo = [
    { id: 1, name: t("countries.SA"), link: "#" },
    { id: 2, name: t("countries.AE"), link: "#" },
    { id: 3, name: t("countries.EG"), link: "#" },
    { id: 4, name: t("countries.QA"), link: "#" },
    { id: 5, name: t("countries.KW"), link: "#" },
    { id: 6, name: t("countries.OM"), link: "#" },
    { id: 7, name: t("countries.BH"), link: "#" },
    { id: 8, name: t("countries.JO"), link: "#" },
    { id: 9, name: t("countries.LB"), link: "#" },
    { id: 10, name: t("countries.SY"), link: "#" },
    { id: 11, name: t("countries.IQ"), link: "#" },
    { id: 12, name: t("countries.YE"), link: "#" },
    { id: 13, name: t("countries.MA"), link: "#" },
    { id: 14, name: t("countries.DZ"), link: "#" },
    { id: 15, name: t("countries.TN"), link: "#" },
    { id: 16, name: t("countries.LY"), link: "#" },
    { id: 17, name: t("countries.SD"), link: "#" },
    { id: 18, name: t("countries.PS"), link: "#" },
    { id: 19, name: t("countries.SO"), link: "#" },
    { id: 20, name: t("countries.DJ"), link: "#" },
    { id: 21, name: t("countries.MR"), link: "#" },
    { id: 22, name: t("countries.KM"), link: "#" }
  ];

  const handleCheckPrice = () => {
    const shipmentData = {
      weight: inputValue,
      zone: selectedFrom,  // Pass the actual selected value from the dropdown
      country: selectedTo, // Pass the actual selected value from the dropdown
      hasDimensions: hasDimensions,
    };

    dispatch(checkShipmentCost(shipmentData)); // Dispatch the action
  };

  return (
    <div className="bg-white px-4 lg:px-custom-padding pt-5 ">
      <CustomTitle title={t("calculateShippingSection.title")} />
      <div className="md:flex items-center justify-center">
        {/* Left Half - Image */}
        <div className="w-full md:w-1/2 flex items-center justify-center">
          <img src={CalcuImage} alt="Shipping" className="mb-4 w-full object-cover" />
        </div>

        {/* Right Half - Dropdowns and Checkbox */}
        <div className="w-full md:w-1/2 p-4 flex-col">
          <CustomDropdown
            title={t("calculateShippingSection.shippingFrom")}
            menuItems={menuItemsLocationFrom}
            menuList={menuListLocationFrom}
            textColor="custom-gray"
            onSelect={(item) => setSelectedFrom(item.name)} // Update selected value
          />
          <CustomDropdown
            title={t("calculateShippingSection.shippingTo")}
            menuItems={menuItemsLocationTo}
            menuList={menuListLocationTo}
            textColor="custom-gray"
            onSelect={(item) => setSelectedTo(item.name)} // Update selected value
          />
          <CustomInput
            title={t("calculateShippingSection.enterWeight")}
            placeholder={t("calculateShippingSection.enterText")}
            textColor="gray-800"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)} // Handle weight input change
          />
          <div>
      <CustomCheckBox
        title="Enter Dimensions" // Translate or use i18n as needed
        onChange={() => setHasDimensions(!hasDimensions)} // Toggle checkbox
      />

{hasDimensions && (
  <div className="space-y-4 mt-4" dir={"ar" ? "rtl" : "ltr"}>
    {/* Quantity and Weight */}
    <div className="flex gap-4">
      <div className="flex flex-col">
        <label className="text-black mb-2" htmlFor="quantity">
         {t("quantity")}
        </label>
        <input
          type="number"
          id="quantity"
          name="quantity"
          value={formData.quantity}
          onChange={handleInputChange}
          className="input bg-white border text-black border-gray-500 p-2"
        />
      </div>
      <div className="flex flex-col">
        <label className="text-black mb-2" htmlFor="weight">
       {t("weight")}
        </label>
        <input
          type="number"
          id="weight"
          name="weight"
          value={formData.weight}
          onChange={handleInputChange}
          className="input bg-white border text-black border-gray-500 p-2"
        />
      </div>
      <div className="flex flex-col">
        <label className="text-black mb-2" htmlFor="height">
         {t("height")}
        </label>
        <input
          type="number"
          id="height"
          name="height"
          value={formData.height}
          onChange={handleInputChange}
          className="input bg-white border text-black border-gray-500 p-2"
        />
      </div>
    </div>

    {/* Height, Width, and Length */}
    <div className="flex gap-4">
    
      <div className="flex flex-col">
        <label className="text-black mb-2" htmlFor="width">
      {t("width")}
        </label>
        <input
          type="number"
          id="width"
          name="width"
          value={formData.width}
          onChange={handleInputChange}
          className="input bg-white border text-black border-gray-500 p-2"
        />
      </div>
      <div className="flex flex-col">
        <label className="text-black mb-2" htmlFor="length">
          {t("length")}
        </label>
        <input
          type="number"
          id="length"
          name="length"
          value={formData.length}
          onChange={handleInputChange}
          className="input bg-white border text-black border-gray-500 p-2"
        />
      </div>
    </div>
  </div>
)}

    </div>

          <div className="flex flex-col items-center justify-center h-full">
            <ChevronDoubleDownIcon className="w-6 h-6 text-black mb-2" />
            <CustomButton
              children={t("calculateShippingSection.checkPrice")}
              icon={CalculatorIcon}
              onClick={handleCheckPrice} // Call handleCheckPrice when button is clicked
            />
          </div>

          {/* Display loading, cost, and error */}
          {loading && <p>{t("calculateShippingSection.loading")}</p>}
          {error && <p className="text-red-500">{t("calculateShippingSection.error")}: {error}</p>}
          {cost && <p>{t("calculateShippingSection.shipmentCost")}: {cost}</p>}
        </div>
      </div>
    </div>
  );
};

export default CalcalateShippingSection;
