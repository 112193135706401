import React from "react";
import { MenuItem } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CustomUnloginMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLoginClick = () => {
    // Navigate to LoginPage and force reload
    navigate("/LoginPage");
    window.location.reload(); // Force reload after navigation
  };

  const handleRegisterClick = () => {
    // Navigate to RegistrationPage and force reload
    navigate("/RegistrationPage");
    window.location.reload(); // Force reload after navigation
  };

  return (
    <div>
      <MenuItem>
        <button
          onClick={handleLoginClick}
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        >
          {t("login")}
        </button>
      </MenuItem>
      <MenuItem>
        <button
          onClick={handleRegisterClick}
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        >
          {t("register")}
        </button>
      </MenuItem>
    </div>
  );
};

export default CustomUnloginMenu;
