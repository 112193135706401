import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import ShopCartExpiredItemsList from './finishedListCard';
import { getShopCartItemByIdExpired, resubmitArchivedItem, editresubmitArchivedItem ,deleteShopCartItem} from '../../redux/reducer/shopCartReducer';
import PopupModal from '../sharedComponents/customPopUp'; // Import the PopupModal
import EditItemModal from './editFinishedtems';
const FinishedOrders = () => {
  const dispatch = useDispatch();
  const { expiredItems, isLoadingExpired, expiredError } = useSelector((state) => state.shopCart);

  const [popupMessage, setPopupMessage] = useState(''); // Manage the message for the popup
  const [isPopupVisible, setPopupVisible] = useState(false); // Control visibility of the popup
  const [editItem, setEditItem] = useState(null); // Item data to edit

  useEffect(() => {
    dispatch(getShopCartItemByIdExpired()); // Fetch expired items on mount
  }, [dispatch]);

  const onClickedRefund = async (id) => {
    try {
      // Fetch the specific item data you want to send (you may already have it in your expiredItems array)
      const itemData = expiredItems.find(item => item._id === id);
      
      if (!itemData) {
        throw new Error("Item not found");
      }
  
      // Send the required data (e.g., itemId and any other necessary data)
      await dispatch(resubmitArchivedItem({
        itemId: id,
        data: {
          name: itemData.name,
          price: itemData.price,
          productSize: itemData.productSize,
          productCount: itemData.productCount,
          link: itemData.link,
        }
      }));
  
      setPopupMessage('Item refunded successfully!');
      setPopupVisible(true);
    } catch (error) {
      setPopupMessage('Failed to refund the item.');
      setPopupVisible(true);
    }
  };

  // Handle Delete Function
  const handleDelete = (itemId) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      dispatch(deleteShopCartItem(itemId))
        .unwrap()
        .then(() => alert("Item deleted successfully!"))
        .catch((error) => alert(error || "Failed to delete item."));
    }
  };
  const onClickedEdit = (item) => {
    if (!item._id) {
      console.error("Selected item does not have an ID:", item);
      return;
    }
    setEditItem(item);
  };
  

  const onSaveEditedItem = async (data) => {
    try {
      const itemId = data._id || (editItem && editItem._id); // Use `_id`
      if (!itemId) {
        throw new Error("No item selected for editing.");
      }
  
      console.log("Saving edited item:", { itemId, data });
  
      await dispatch(editresubmitArchivedItem({ itemId, data }));
  
      setPopupMessage("Item updated successfully!");
      setPopupVisible(true);
      setEditItem(null); // Close the edit modal after saving
    } catch (error) {
      console.error("Error while saving the edited item:", error);
      setPopupMessage("Failed to update the item.");
      setPopupVisible(true);
    }
  };
  

  const onClosePopup = () => {
    setPopupVisible(false);
    setEditItem(null);
  };

  if (isLoadingExpired) return <p>Loading...</p>;
  if (expiredError) return <p>Error: {expiredError}</p>;

  return (
    <div className="p-6 w-full space-y-6">
      {/* Displaying the list of expired items */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {expiredItems.length > 0 ? (
          expiredItems.map((item) => (
            <ShopCartExpiredItemsList
              key={item.id}
              image={item.images && item.images[0]?.url ? item.images[0].url : 'https://via.placeholder.com/64'}
              title={item.name}
              size={item.productSize}
              count={item.productCount}
              price={item.price}
              link={item.link}
              status={item.status}
              onClickedEdit={() => onClickedEdit(item)} // Ensure you're passing the item correctly
              onClickedRefund={() => onClickedRefund(item._id)} 
              onClickedDelete={() => handleDelete(item._id)}

            />
          ))
        ) : (
          <p>No items in the cart.</p>
        )}
      </div>

      {/* Show the popup message if visible */}
      {isPopupVisible && (
        <PopupModal
          message={popupMessage}
          onClose={() => setPopupVisible(false)} // Close the popup
        />
      )}
          {/* Edit Item Modal */}
          {editItem && (
  <EditItemModal
    item={editItem}
    onSave={onSaveEditedItem}
    onClose={onClosePopup}
  />
)}
    </div>
  );
};

export default FinishedOrders;
