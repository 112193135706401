import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomBuyForMeCard from '../buyForMe/customBuyForMeCard';
import { getUserOrders, deleteOrder } from '../../redux/reducer/buyForMeReducer';
import CustomEmptyCart from '../sharedComponents/customEmptyCart';
const CustomBuyForMeCardList = () => {
  const dispatch = useDispatch();
  const { orders, loading, error } = useSelector((state) => state.buyForMeOrders);

  useEffect(() => {
    // Fetch orders when the component mounts
    dispatch(getUserOrders());
  }, [dispatch]);

  const handleCheckboxChange = (id) => {
    // Implement checkbox toggle logic if needed
  };

  const handleDelete = (id) => {
    dispatch(deleteOrder(id));
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (orders.length === 0) {
    return <CustomEmptyCart/>;
  }

  return (
    <div className="grid grid-cols-3 gap-4">
      {orders.map((order) => (
        <CustomBuyForMeCard
          key={order._id}
          image={order.image}
          title={order.title}
          size={order.size}
          count={order.count}
          price={order.price}
          status={order.status}
          checked={order.checked}
          onChange={() => handleCheckboxChange(order._id)}
          del={() => handleDelete(order._id)}
          isEditable={true}
        />
      ))}
    </div>
  );
};

export default CustomBuyForMeCardList;
