import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API_BASE_URL from '../constent';
// Async Thunk for creating payment
export const createPayment = createAsyncThunk(
  "payment/createPayment",
  async (paymentData, { rejectWithValue }) => {
    try {
      const response = await axios.post(  `${API_BASE_URL}/payment-endpoint/create-payment`,  paymentData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred while creating the payment");
    }
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    paymentDetails: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentDetails = action.payload;
      })
      .addCase(createPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default paymentSlice.reducer;
