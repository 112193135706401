import React from 'react';
import CopyableListInput from './CustomInputAndTitle';
import { useTranslation } from 'react-i18next';
const CustomEropaSection =()=>{
    const {t} = useTranslation()
    return(
        <div>
           <CopyableListInput label={t( "FullName")}
           title='Markaship e.U'
           />
           <CopyableListInput label={t( "Name")}
           title='Markaship'
           />
           <CopyableListInput label={t("Surname")}
           title='e.U.'
           />
           <CopyableListInput label={t( "City")}
           title='Vienna'
           />
           <CopyableListInput
           label={t( "Province")}
           title='Vienna'
           />
           <CopyableListInput label={t( "PostalCode")}
           title='16110'
           />
           <CopyableListInput label={t( "FullAddress")}
           title='odunluk mah. Ortaç sk. No: 11 A. Nilüfer/ bursa Box: 377086'
           />
           <CopyableListInput label={t( "CompanyName")}
           title='Turkish corner mobilya inşaat ithalat ihracat'
           />
           <CopyableListInput label={t("PhoneNumber")}
           title='05331586084'
           />
           <CopyableListInput label={t(  "TaxCircle")}
           title='8711102117'
           />
           <CopyableListInput label={t("TaxNumber")}
           title='8711102117'
           />

        </div>
    )
}

export default CustomEropaSection;