import React from 'react';
import {PhoneIcon}
 from '@heroicons/react/24/outline';
import image from '../../assets/images/image.png';
import CustomButton from '../sharedComponents/customButton';
import { useTranslation } from 'react-i18next';
const AskSection = () => {
  const {t} = useTranslation();
  return (
    <div className="relative w-full h-[400px]"> {/* Adjust height as needed */}
      {/* Image */}
      <img
        src={image}
        alt="intro"
        className="w-full h-full object-cover"
      />

      {/* Overlay Content */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white bg-black bg-opacity-50 p-4">
        <h1 className="text-3xl font-bold mb-4">{t('askSection.haveQuestion')}</h1>
        <h1 className="text-3xl font-bold mb-4">{t('askSection.hereToHelp')}</h1>

        <CustomButton 
      children={t('askSection.contactUs')} 
  icon={PhoneIcon} // Pass the component directly
/>
      </div>
    </div>
  );
};

export default AskSection;
