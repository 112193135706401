import React from 'react';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
const CustomArrowRight =()=>{
    return(
        <div>
         <ChevronDoubleRightIcon className='w-6 h-6 text-black'/>
        </div>
    )
}

export default CustomArrowRight;