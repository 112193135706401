import React, { useState } from 'react';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline';

const CustomCategoryCard = ({ image, title }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave} 
            className="cursor-pointer w-full flex flex-col  items-center justify-center text-center"
        >
            <div className="relative">
                {isHovered && (
                    <ChevronDoubleDownIcon className="w-6 h-6 text-black absolute top-[-30px]" />
                )}
            </div>
            <img
                src={image}
                alt="image"
                className="w-full h-40"
            />
            <div
                className={`w-full px-4 inline-block ${
                    isHovered ? 'bg-black' : 'bg-custom-yellow'
                }`}
            >
                <h2 className={`text-sm lg:text-lg py-2 ${isHovered ? 'text-white' : 'text-black'}`}>
                    {title}
                </h2>
            </div>
        </div>
    );
};

export default CustomCategoryCard;
