import React from 'react';
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import CustomTitle from '../sharedComponents/customTitle';
import CustomShoppngCardDetials from './customShoppingCardData';
import CustomShoppingCard from './customShoppingCrad';
import imageEroupa from '../../assets/images/shoppingOrouba.png';
import imageFlag from '../../assets/images/oroba.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const ShoppingEuropeSection =()=>{
    const Navigate = useNavigate();
    const {t} = useTranslation();
    const handleClick =() => {
        Navigate('/ChooseCategoryEroupaPage')
    }
    return(
        <div className="bg-white px-4 lg:px-custom-padding pt-5 pb-10 ">

        <CustomTitle
        title={t('shoppingEuropeSection.title')} 
        />
        <div className='md:flex'>
        <CustomShoppngCardDetials
    title={t('shoppingEuropeSection.cardTitle1')}
    image={imageFlag}
    onClick={handleClick}
    icon={ArchiveBoxIcon}
    imageTitle={t('shoppingEuropeSection.flagImageTitle')}
    children={t('shoppingEuropeSection.buttonLabel')}
    subTitle={t('shoppingEuropeSection.cardSubtitle1')} />
        <CustomShoppingCard
        image={imageEroupa}
        title={t('shoppingEuropeSection.cardTitle2')}
        subTitle={t('shoppingEuropeSection.cardSubtitle2')} />
        </div>

    </div>
    )
}

export default ShoppingEuropeSection;