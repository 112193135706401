import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import API_BASE_URL from '../constent';

// 1. Fetch all Main Turkish Websites
export const fetchMainTurkishWebsites = createAsyncThunk(
  'websitesTurkish/fetchMainTurkishWebsites',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/mainTurkishWebsites`, {
   
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'An error occurred'
      );
    }
  }
);

// 2. Fetch all Turkish Websites
export const fetchTurkishWebsites = createAsyncThunk(
  'websitesTurkish/fetchTurkishWebsites',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/turkishWebsites`, {
    
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'An error occurred'
      );
    }
  }
);
// 2. Fetch Turkish Websites by IDs
export const fetchWebsitesByIds = createAsyncThunk(
  'websitesTurkish/fetchWebsitesByIds',
  async (ids, { rejectWithValue }) => {
    try {
      const responses = await Promise.all(
        ids.map((id) => axios.get(`${API_BASE_URL}/turkishWebsites/${id}`))
      );
      return responses.map((response) => response.data);
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'An error occurred'
      );
    }
  }
);

// 3. Fetch Notifications Count
export const fetchNotificationsCount = createAsyncThunk(
  'websitesTurkish/fetchNotificationsCount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/mainTurkishWebsites/get/notifications/counts`,
     
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'An error occurred'
      );
    }
  }
);

// Initial state
const initialState = {
    mainTurkishWebsites: [],
    turkishWebsites: [],
    notificationsCount: null,
    loading: false,
    error: null,
  };
  

// Slice
const websitesSlice = createSlice({
  name: 'websitesTurkish',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    clearNotificationsCount: (state) => {
      state.notificationsCount = null;
    },
    clearWebsites: (state) => {
      state.mainTurkishWebsites = [];
      state.turkishWebsites = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Main Turkish Websites
      .addCase(fetchMainTurkishWebsites.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMainTurkishWebsites.fulfilled, (state, action) => {
        state.loading = false;
        state.mainTurkishWebsites = action.payload;
      })
      .addCase(fetchMainTurkishWebsites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch Turkish Websites
      .addCase(fetchTurkishWebsites.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTurkishWebsites.fulfilled, (state, action) => {
        state.loading = false;
        state.turkishWebsites = action.payload;
      })
      .addCase(fetchTurkishWebsites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch Notifications Count
      .addCase(fetchNotificationsCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotificationsCount.fulfilled, (state, action) => {
        state.loading = false;
        state.notificationsCount = action.payload;
      })
      .addCase(fetchNotificationsCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const { clearError, clearNotificationsCount, clearWebsites } = websitesSlice.actions;
export default websitesSlice.reducer;
