import React, { useState } from 'react';
import { PencilIcon, XMarkIcon ,PencilSquareIcon ,ArrowPathIcon} from '@heroicons/react/24/outline';


const CustomCardFinished = ({ image, title, size, count, price, isEditable,status , link  ,onClickedEdit ,onClickedDelete,onClickedRefund}) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="relative cursor-pointer flex flex-col items-center justify-center bg-white
              overflow-hidden w-[250px] border"
        >
            <div className="relative w-full h-[200px] flex items-center justify-center">
                <img
                    src={image}
                    alt={title}
                    className="object-contain max-w-full max-h-full"
                />
                {isHovered && isEditable && (
                    <div className="absolute top-2 right-2 flex gap-2">
                        <button className="bg-yellow-500 p-2 rounded-full">
                            <PencilIcon className="w-4 h-4 text-black" />
                        </button>
                        <button className="bg-black p-2 rounded-full">
                            <XMarkIcon className="w-4 h-4 text-white" />
                        </button>
                    </div>
                )}
            </div>
            <div
                className="flex flex-col items-start justify-center w-full p-4 transition-colors duration-300  bg-yellow-400 text-black" >
<h2 className="font-bold text-sm overflow-hidden text-ellipsis whitespace-nowrap">{title}</h2>
<h2 className="font-bold text-sm">
    <a 
      href={link} 
      target="_blank" 
      rel="noopener noreferrer" 
      className="hover:underline text-blue-500"
    >
      {link}
    </a>
  </h2>
                <div className="flex justify-between w-full">
                    <p className="text-xs">Size: {size}</p>
                    <p className="text-xs">Count: {count}</p>
                </div>
                <p className="text-lg font-bold mt-2">{price} TL</p>
                <p className="text-lg font-bold mt-2">{status} الحالة</p>
                <div className="flex items-center justify-between w-full mt-2">
                <button 
    className="p-2 items-center rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-red-500"
    onClick={onClickedEdit}
     aria-label="Edit item"
  >
    <PencilSquareIcon className="w-5 h-5 text-black" />
  </button>

  <button 
    className="p-2 items-center rounded-full bg-white  focus:outline-none focus:ring-2 focus:ring-red-500"
    onClick={onClickedDelete}
  >
    <XMarkIcon className="w-5 h-5 text-red-600" />
  </button>

  <button 
    className="p-2 items-center rounded-full bg-white hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500"
    onClick={onClickedRefund}
  >
    <ArrowPathIcon className="w-5 h-5 text-green-500" />
  </button>
</div>

            </div>
        </div>
    );
};

export default CustomCardFinished;
