import React from 'react';
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import CustomTitle from '../sharedComponents/customTitle';
import CustomShoppngCardDetials from './customShoppingCardData';
import CustomShoppingCard from './customShoppingCrad';
import imageTrukey from '../../assets/images/truckeyShoppingImage.png';
import imageFlag from '../../assets/images/turkey.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const ShoppingTrukeySection =()=>{
    const  navigate = useNavigate();
    const {t} =useTranslation();
    const handleClick = () => {
   navigate('/ChooseCategoryTrukeyPage')
     }
    return(
        <div className="bg-white px-4 lg:px-custom-padding pt-5 pb-10 ">

        <CustomTitle
        title={t('shoppingTurkeySection.title')}
        />
        <div className='md:flex'>
        <CustomShoppingCard
        image={imageTrukey}
        title={t('shoppingTurkeySection.cardTitle1')}
        subTitle={t('shoppingTurkeySection.cardSubtitle1')} />
        <CustomShoppngCardDetials
      title={t('shoppingTurkeySection.cardTitle2')}
      image={imageFlag}
      icon={ArchiveBoxIcon}
      onClick={handleClick}
      imageTitle={t('shoppingTurkeySection.flagImageTitle')}
      children={t('shoppingTurkeySection.buttonLabel')}
      subTitle={t('shoppingTurkeySection.cardSubtitle2')} />
    
        </div>

    </div>
    )
}

export default ShoppingTrukeySection;