import React from "react";
import CustomIntroSection from "../component/explainBuyPage/customIntroSection";
import Header from "../component/sharedComponents/header";
import BlockedItemsSection  from '../component/explainBuyPage/blockedItemsSection';
import HowItWorksSections from '../component/explainBuyPage/howItWorksSection';
import AskSection from "../component/home/askSection";
import FooterUser from "../component/sharedComponents/footer";
import { useTranslation } from "react-i18next";
const ExplainBuyPage =()=>{
    const {t} =useTranslation();
    return (
        <div>
            <Header/>
<div className="mt-20">
<CustomIntroSection
title={t('introSection.title')}
/>
</div>
<BlockedItemsSection />
<HowItWorksSections/>
<AskSection/>
<FooterUser/>
        </div>
    )
}

export default ExplainBuyPage;