import React from "react";

const CustomShoppingCard = ({ image, title, subTitle }) => {
  return (
    <div className="md:w-1/2 flex flex-col items-center justify-center text-center space-y-4">
      <img
        src={image}
        alt="image"
        className="h-[350px] object-contain"
      />
      <h2 className="text-lg font-semibold text-black">
        {title}
      </h2>
      <p className="text-sm font-normal text-gray-800">
        {subTitle}
      </p>
    </div>
  );
};

export default CustomShoppingCard;
