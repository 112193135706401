import React from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

const TwoRowButtons = ({ onclickPerv, onclickNext, isCartEmpty }) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-between w-full mt-5">
      <button
        onClick={onclickPerv}
        className="bg-custom-yellow text-black py-3 px-6 rounded-custom
                text-base sm:text-sm md:text-base lg:text-sm xl:text-sm inline-flex items-center justify-center
                transition duration-300 ease-in-out transform hover:scale-105 
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color 
                whitespace-nowrap"
      >
        <ArrowRightIcon className="w-4 text-black ml-2" />
        {t("Previous")}
      </button>
      
      {/* Conditionally render the Next button based on isCartEmpty */}
      {!isCartEmpty && (
        <button
          onClick={onclickNext}
          className="bg-black text-white py-3 px-6 rounded-custom
                  text-base sm:text-sm md:text-base lg:text-sm xl:text-sm inline-flex items-center justify-center
                  transition duration-300 ease-in-out transform hover:scale-105 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color 
                  whitespace-nowrap"
        >
          {t("Next")}
          <ArrowLeftIcon className="w-4 text-white mr-2" />
        </button>
      )}
    </div>
  );
};

export default TwoRowButtons;
