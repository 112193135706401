import React, { useState } from "react";

export default function CustomInput({
  id,
  type = "text",
  placeholder,
  value,
  onChange,
  icon,
  isPassword = false,
  className = "",
}) {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  return (
    <div className="relative w-full">
      {icon && (
        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-900">
          {icon}
        </span>
      )}
    <input
  id={id}
  type={isPassword && !isPasswordVisible ? "password" : type}
  placeholder={placeholder}
  value={value}
  onChange={onChange}
  className={`w-full px-10 py-3 text-black text-xs sm:text-lg border border-gray-300 rounded-lg bg-white 
  placeholder:text-base xxxs:placeholder:text-sm sm:placeholder:text-lg ${className}`}
/>

      {isPassword && (
        <span
          className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer text-gray-900"
          onClick={() => setPasswordVisible(!isPasswordVisible)}
        >
          {isPasswordVisible ? "👁️" : "👁️‍🗨️"}
        </span>
      )}
    </div>
  );
}
