import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BASE_URL from "../constent"; // Ensure your BASE_URL is correct

// Async thunk to fetch shipment details by ID
export const getShipmentDetailsById = createAsyncThunk(
  "myShipment/getShipmentDetailsById",
  async (_,{ rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        throw new Error("Authentication token is missing.");
      }

      const response = await axios.get(`${BASE_URL}/myShipment/getUserShipments/byUserId/success`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data; // Shipment details
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Async thunk to mark a shipment payment as successful
export const markPaymentSuccess = createAsyncThunk(
  "myShipment/markPaymentSuccess",
  async (shipmentId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        throw new Error("Authentication token is missing.");
      }

      const response = await axios.get(
        `${BASE_URL}/myshipment/paymentSuccess/${shipmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data; // Updated shipment details
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Async thunk to fetch all shipments for the authenticated user
export const getUserShipments = createAsyncThunk(
  "myShipment/getUserShipments",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        throw new Error("Authentication token is missing.");
      }

      const response = await axios.get(`${BASE_URL}/myshipment/getUserShipments/byUserId`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data; // List of user shipments
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Async thunk to fetch successful shipments for the authenticated user
export const getSuccessfulShipments = createAsyncThunk(
  "myShipment/getSuccessfulShipments",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        throw new Error("Authentication token is missing.");
      }

      const response = await axios.get(
        `${BASE_URL}/myshipment/getUserShipments/byUserId/success`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data; // List of successful shipments
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Create the slice
const myShipmentSlice = createSlice({
  name: "myShipment",
  initialState: {
    shipments: [],
    successfulShipments: [],
    shipmentDetails: null,
    loading: false,
    error: null,
  },
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get shipment details by ID
      .addCase(getShipmentDetailsById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getShipmentDetailsById.fulfilled, (state, action) => {
        state.loading = false;
        state.shipmentDetails = action.payload;
      })
      .addCase(getShipmentDetailsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Mark payment success
      .addCase(markPaymentSuccess.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(markPaymentSuccess.fulfilled, (state, action) => {
        state.loading = false;
        // Update the shipment in the list, if needed
        state.shipmentDetails = action.payload;
      })
      .addCase(markPaymentSuccess.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Get user shipments
      .addCase(getUserShipments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserShipments.fulfilled, (state, action) => {
        state.loading = false;
        state.shipments = action.payload;
      })
      .addCase(getUserShipments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Get successful shipments
      .addCase(getSuccessfulShipments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSuccessfulShipments.fulfilled, (state, action) => {
        state.loading = false;
        state.successfulShipments = action.payload;
      })
      .addCase(getSuccessfulShipments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearError } = myShipmentSlice.actions;
export default myShipmentSlice.reducer;

