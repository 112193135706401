import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserBoxOrders } from "../../redux/reducer/shipandShopOrdersReducer"; // Adjust the path
import CustomBuyForMeCard from "./boxOrderCard"; // Adjust the path
import { useTranslation } from "react-i18next";
import RepackagingPopup from "./rePackingOrder";

const ListOfBoxCardOrders = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userBoxOrders, loading, error } = useSelector((state) => state.shipAndShop);

  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    dispatch(getUserBoxOrders());
  }, [dispatch]);

  if (loading) {
    return <p>{t("Loading...")}</p>;
  }

  if (error) {
    return <p className="text-red-500">{t("Failed to load orders:")} {error}</p>;
  }

  const handleRepackageClick = (order) => {
    setSelectedOrder(order); // Open the popup with selected order data
  };

  const handleClosePopup = () => {
    setSelectedOrder(null); // Close the popup
  };

  return (
    <div>
      <div className="p-8 grid grid-cols-1 md:grid-cols-3 gap-8 w-full">
        {userBoxOrders?.map((order) => (
          <div key={order.id} className="flex flex-col gap-4">
            <CustomBuyForMeCard
              order={order.orderNumber}
              image={order.image}
              title={order.title}
              size={order.size}
              count={order.count}
              price={order.price}
              isEditable={order.isEditable}
              onRepackage={() => handleRepackageClick(order)} // Pass the handler
            />
          </div>
        ))}
      </div>

      {/* Popup for Repackaging */}
      {selectedOrder && (
        <RepackagingPopup order={selectedOrder} onClose={handleClosePopup} />
      )}
    </div>
  );
};

export default ListOfBoxCardOrders;
