import React from 'react';
import Header from '../component/sharedComponents/header';
import LoginForm from '../component/auth/loginForm';
import FooterUser from '../component/sharedComponents/footer';

const LoginPage =()=>{
    return(
        <div>
<Header/>
<div className='mt-20'>
<LoginForm/>
</div>
<FooterUser/>
        </div>
    )
}

export default LoginPage;