import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HomeIcon, TruckIcon, ShoppingCartIcon } from "@heroicons/react/24/outline";

const NavBarMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(null);
  const [menuWidths, setMenuWidths] = useState({});
  const menuRef = useRef([]);
  const closeTimeout = useRef(null);

  const menuData = {
    countShipping: {
      name: t("count_shipping"),
      path: "/CountShippingPage",
      icon: <TruckIcon className="w-4 h-4 mr-1 hidden md:block" />, // Hidden on mobile, visible on md and larger
    },
    buyForMe: {
      name: t("buy_for_me"),
      path: "/ExplainBuyPage",
      icon: <ShoppingCartIcon className="w-4 h-4 mr-1 hidden md:block" />, // Hidden on mobile, visible on md and larger
    },
    home: {
      name: t("home"),
      path: "/",
      icon: <HomeIcon className="w-4 h-4 mr-1 hidden md:block" />, // Hidden on mobile, visible on md and larger
    },
  };

  useEffect(() => {
    const widths = {};
    menuRef.current.forEach((ref, index) => {
      if (ref) {
        widths[Object.keys(menuData)[index]] = ref.offsetWidth;
      }
    });
    setMenuWidths(widths);
  }, [menuData]);

  const handleMouseEnter = (menuName) => {
    clearTimeout(closeTimeout.current);
    setOpenMenu(menuName);
  };

  const handleMouseLeave = () => {
    closeTimeout.current = setTimeout(() => {
      setOpenMenu(null);
    }, 200);
  };

  const handleClick = (e, path) => {
    e.preventDefault();
    navigate(path);
    window.location.href = path;
  };

  return (
    <div className="relative">
      <div className="lg:flex justify-center py-2">
        {Object.keys(menuData).map((key, index) => (
          <div
            key={key}
            className="relative"
            onMouseEnter={() => handleMouseEnter(menuData[key].name)}
            onMouseLeave={handleMouseLeave}
          >
            <a
              href={menuData[key].path}
              ref={(el) => (menuRef.current[index] = el)}
              className={`lg:flex items-center gap-1 text-white font-semibold rounded focus:outline-none
                sm:mr-2 lg:mr-4 xl:mr-4 ${
                  openMenu === menuData[key].name ? "text-custom-yellow" : "hover:text-custom-yellow"
                }
              `}
              onClick={(e) => handleClick(e, menuData[key].path)}
            >
          
              {menuData[key].name}
              {menuData[key].icon}
            </a>
            <div
              style={{
                width: `${menuWidths[menuData[key].name] || 50}px`,
              }}
              className={`h-[3px] mx-auto hidden md:block ${
                openMenu === menuData[key].name ? "bg-custom-yellow" : "hover:bg-custom-yellow"
              }`}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NavBarMenu;
