import React, { useState } from 'react';
import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon ,BriefcaseIcon,ArrowUturnDownIcon } from '@heroicons/react/24/outline';
import image1 from '../../assets/images/introsection.png'; // Assuming two images for the slider
import image2 from '../../assets/images/slider.png';
import ImageYellowBox from '../../assets/images/yellowBox.png';
import CustomButton from '../sharedComponents/customButton';
import { useTranslation } from 'react-i18next';

const ScrollerSection = () => {
  const { t } = useTranslation();
  
  // State for tracking the current slide
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const images = [image1, image2]; // Array of images for the slider
  const texts = [
    { heading: t('scrollerSection.favoriteStore'), subheading: t('scrollerSection.toYourDoorstep') },
    { heading: t("enjoyableShoppingJourney"), subheading: t( "startWithOneStepNow") }
  ]; // Array of text content for each slide
  
  // Handle left arrow click
  const goToPreviousSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  // Handle right arrow click
  const goToNextSlide = () => {
    setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  // Handle dot click
  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
<div>
<div className="lg:flex bg-black w-full justify-between py-5 px-4 mt-10 lg:px-custom-padding">
            <button
          onClick={goToNextSlide}
          className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-black text-white py-2 px-8 rounded-full"
        >
          
          <ChevronDoubleRightIcon className="w-8 h-10" />
        </button>
        <img src={images[currentSlide]} alt="slider" className="h-[500px] lg:w-1/2" />
        
    
     

      <div className="flex flex-col items-end justify-center">
        <div className="bg-custom-yellow inline-block px-4 md:px-8 py-2">
          <h2 className="text-lg lg:text-3xl text-black items-end">{texts[currentSlide].heading}</h2>
        </div>
        <div className="flex items-center">
          <div className="bg-white inline-block px-4 md:px-8 py-2 relative top-[-18px] md:top-[-18px] lg:top-[-14px]">
            <h2 className="text-lg lg:text-3xl text-black">{texts[currentSlide].subheading}</h2>
          </div>
          <img
            src={ImageYellowBox}
            alt="box"
            className="w-20 h-20 relative top-[-20px] left-[-10px]"
          />
        </div>
        <h2 className="text-sm text-white mb-10">{t('scrollerSection.shopGlobally')}</h2>
        
        <div className="flex space-x-2">
          <button
            className="bg-black ml-2 text-white py-2 px-4 rounded-custom border border-white
            text-base sm:text-sm md:text-base lg:text-sm xl:text-sm inline-flex items-center justify-center
            transition duration-300 ease-in-out transform hover:scale-105
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color
            whitespace-nowrap"
          >
            <ArrowUturnDownIcon className="w-4 text-white mr-1 ml-1" />
            {t('scrollerSection.discoverServices')}
          </button>
          <CustomButton children={t('scrollerSection.contactUs')} icon={BriefcaseIcon} />
        </div>

     
      </div>
          {/* Arrow Navigation */}
          <button
          onClick={goToPreviousSlide}
          className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-black text-white py-2 px-8  rounded-full"
        >
           <ChevronDoubleRightIcon className="w-8 h-10 transform rotate-180" />

        </button>
    </div>
       {/* Dot Navigation */}
       <div className="flex justify-center space-x-2 mt-4 mb-4">
       {images.map((_, index) => (
         <button
           key={index}
           onClick={() => goToSlide(index)}
           className={`w-2.5 h-2.5 rounded-full ${currentSlide === index ? 'bg-custom-yellow' : 'bg-white'}`}
         />
       ))}
     </div>
</div>
  );
};

export default ScrollerSection;
