import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllServices } from "../../redux/reducer/ourServicesReducer"; // Adjust the path
import CustomCardWithBox from "../sharedComponents/customCardWithBox"; // Adjust the path
import CustomBigText from '../sharedComponents/customBigText';
import CustomLines from "../home/customLines";
const ContainerOurServices = () => {
  const dispatch = useDispatch();
  const { services, status, error } = useSelector((state) => state.services); // Ensure `state.services` is correct

  // Dispatch fetchAllServices only if services are not already fetched
  React.useEffect(() => {
    if (status === "idle") {
      dispatch(fetchAllServices());
    }
  }, [dispatch, status]);

  if (status === "loading") {
    return <p>Loading...</p>;
  }

  if (status === "failed") {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="bg-white flex flex-col items-center justify-center text-center px-4 
    lg:px-custom-padding ">
      <CustomLines />
      <CustomBigText title='تعرف علي خدمتنا' />
    <div className="lg:px-custom-padding bg-white py-10">
      {services.length === 0 ? (
        <p>No services available</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {services.map((service) => (
            <div key={service._id} className="flex">
              <CustomCardWithBox
                title={service.title.ar}
                subTitle={service.shortDes.ar}
              />
            </div>
          ))}
        </div>
      )}
    </div>
    </div>
  );
};

export default ContainerOurServices;
 