import React from "react";
import Header  from "../component/sharedComponents/header";
import CustomIntroSection from "../component/explainBuyPage/customIntroSection";
import BlockedItemsSection from "../component/explainBuyPage/blockedItemsSection";
import AskSection from "../component/home/askSection";
import FooterUser from "../component/sharedComponents/footer";
import CountOrdersSection from "../component/countShipping/countOrdersSection";
import { useTranslation } from "react-i18next";
const CountShippingPage =()=>{
   const {t} = useTranslation();
   return(
  <div>
              <Header/>
<div className="mt-20">
<CustomIntroSection
title={t("customIntroSection.title")}
/>
</div>
<BlockedItemsSection />
<CountOrdersSection/>
<AskSection/>
<FooterUser/>
  </div>
   )
}
export default CountShippingPage;