import React from 'react';

const CustomBlackButton =({children, onClick, icon: Icon})=>{
    return (
        <div>
    <button
        onClick={onClick}
        className="bg-black text-white py-2 px-4 rounded-custom
          text-base text-xs md:text-sm inline-flex items-center justify-center
          transition duration-300 ease-in-out transform hover:scale-105 
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color 
          whitespace-nowrap"
      >
        {Icon && (
          <Icon className="w-2 md:w-4 text-white mr-1" />
        )}
        {children}
      </button>
        </div>
    )
}

export default CustomBlackButton;