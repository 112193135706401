import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PhoneIcon, WrenchIcon } from "@heroicons/react/24/outline";

const NavBarMenuContactUsOurService = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(null);
  const [menuWidths, setMenuWidths] = useState({});
  const menuRef = useRef([]);
  const closeTimeout = useRef(null);

  const menuData = {
    contactUs: {
      name: t("contact_us"),
      path: "/ContactUsPage",
      icon: <PhoneIcon className="w-4 h-4 mr-5 hidden md:block" />, // Hidden on mobile, visible on md and larger
    },
    ourService: {
      name: t("our_services"),
      path: "/OurServicesPage",
      icon: <WrenchIcon className="w-4 h-4 mr-1  hidden md:block" />, // Hidden on mobile, visible on md and larger
    },
  };

  useEffect(() => {
    const widths = {};
    menuRef.current.forEach((ref, index) => {
      if (ref) {
        widths[Object.keys(menuData)[index]] = ref.offsetWidth;
      }
    });
    setMenuWidths(widths);
  }, [menuData]);

  const handleMouseEnter = (menuName) => {
    clearTimeout(closeTimeout.current);
    setOpenMenu(menuName);
  };

  const handleMouseLeave = () => {
    closeTimeout.current = setTimeout(() => {
      setOpenMenu(null);
    }, 200);
  };

  const handleClick = (e, path) => {
    e.preventDefault();
    navigate(path);
    window.location.href = path;
  };

  return (
    <div className="relative">
      <div className="lg:flex justify-center py-2">
        {Object.keys(menuData).map((key, index) => (
          <div
            key={key}
            className="relative "
            onMouseEnter={() => handleMouseEnter(menuData[key].name)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex flex-col">
              <a
                href={menuData[key].path}
                ref={(el) => (menuRef.current[index] = el)}
                className={`lg:flex  gap-1 text-white font-semibold rounded focus:outline-none   sm:mr-1 lg:mr-1 xl:mr-1
                ${
                  openMenu === menuData[key].name
                    ? "text-custom-yellow"
                    : "hover:text-custom-yellow"
                }
                `}
                onClick={(e) => handleClick(e, menuData[key].path)}
              >
                {menuData[key].name}
                {menuData[key].icon}
              </a>
              <div
                style={{ width: `${menuWidths[menuData[key].name] || 50}px` }}
                className={`h-[3px] mx-auto hidden md:block ${
                  openMenu === menuData[key].name
                    ? "bg-custom-yellow"
                    : "hover:bg-custom-yellow"
                }`}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NavBarMenuContactUsOurService;
