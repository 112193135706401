import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkJWT } from "../../redux/reducer/jwtCheckReducer";
import CopyableListInput from "../cartAddress/CustomInputAndTitle";
import CustomCardCount from "./customCardCount";
import { useTranslation } from "react-i18next";

const BoxSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userDetails, loading, error } = useSelector((state) => state.jwtCheck);

  useEffect(() => {
    dispatch(checkJWT()); // Trigger JWT check on component mount
  }, [dispatch]);

  // Extract `boxNumber` safely from `userDetails`
  const boxNumber = userDetails?.boxNumber || t("Unknown");

  if (loading) {
    return <p>{t("loading")}...</p>;
  }

  if (error) {
    return <p className="text-red-500">{t("error_occurred")}: {error}</p>;
  }

  return (
    <div className="w-full">
      {/* Box Number Section */}
      <div className="flex items-center space-x-2 mb-6">
        <h3 className="text-lg text-black">{t("BoxNumber")}</h3>
        <CopyableListInput title={boxNumber} />
      </div>

      {/* Card Counts Section */}
      <div className="flex w-full space-x-4">
        <div className="w-2/3">
          <CustomCardCount
            title={t("MyBox")}
            count="0"
            subTitle={t("Piece")}
            className="flex-1"
          />
        </div>
        <div className="w-2/3">
          <CustomCardCount
            title={t("Shipments")}
            count="0"
            subTitle={t("Shipment")}
            className="flex-1"
          />
        </div>
        <div className="w-2/3">
          <CustomCardCount
            title={t("Notifications")}
            count="0"
            subTitle={t("NewNotification")}
            className="flex-1"
          />
        </div>
      </div>
    </div>
  );
};

export default BoxSection;
