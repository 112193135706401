// src/redux/reducer/notificationReducer.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BASE_URL from '../constent';

// Fetch notifications
export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) throw new Error("No access token found");

      const response = await axios.get(`${BASE_URL}/notification/userNoty/getNotys`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Mark notification as read
export const editNotificationToRead = createAsyncThunk(
  "notifications/editNotificationToRead",
  async (notiId, thunkAPI) => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) throw new Error("No access token found");

      const response = await axios.put(`${BASE_URL}/notification/editToRead/${notiId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Delete notification
export const deleteNotification = createAsyncThunk(
  "notifications/deleteNotification",
  async (notiId, thunkAPI) => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) throw new Error("No access token found");

      await axios.delete(`${BASE_URL}/notification/${notiId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return notiId; // Return the deleted notification ID
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Initial state and reducers
const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    notifications: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Action to clear the error state
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle getNotifications
      .addCase(getNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload;
        state.loading = false;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch notifications";
      })
      // Handle editNotificationToRead
      .addCase(editNotificationToRead.pending, (state) => {
        state.loading = true;
      })
      .addCase(editNotificationToRead.fulfilled, (state, action) => {
        const updatedNotifications = state.notifications.map((notification) =>
          notification.id === action.payload.id
            ? { ...notification, read: true }
            : notification
        );
        state.notifications = updatedNotifications;
        state.loading = false;
      })
      .addCase(editNotificationToRead.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to update notification";
      })
      // Handle deleteNotification
      .addCase(deleteNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.notifications = state.notifications.filter(
          (notification) => notification.id !== action.payload
        );
        state.loading = false;
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to delete notification";
      });
  },
});

// Export the clearError action
export const { clearError } = notificationSlice.actions;

// Export the reducer
export default notificationSlice.reducer;
