import React from "react";
import { StarIcon } from '@heroicons/react/24/solid';

const StarReview = () => {
  return (
    <div className="flex items-center">
      <div className="flex space-x-2">
        <StarIcon className="h-3 w-3 lg:h-5 lg:w-5 text-custom-yellow " />
        <StarIcon className="h-3 w-3 lg:h-5 lg:w-5 text-custom-yellow" />
        <StarIcon className="h-3 w-3 lg:h-5 lg:w-5 text-custom-yellow" />
        <StarIcon className="h-3 w-3 lg:h-5 lg:w-5 text-custom-yellow" />
        <StarIcon className="h-3 w-3 lg:h-5 lg:w-5 text-custom-yellow" />
      </div>
    </div>
  );
};

export default StarReview;
