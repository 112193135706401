import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API_BASE_URL from "../constent"; // Adjust base URL

// Thunks for asynchronous actions
export const fetchAllServices = createAsyncThunk(
  "services/fetchAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/ourServices`);
      return response.data; // Assuming this returns the array of services
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

// Slice for services
const servicesSlice = createSlice({
  name: "services",
  initialState: {
    services: [],  // Make sure services is initialized as an empty array
    status: "idle", // idle | loading | succeeded | failed
    error: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null; // Clears the error
    },
    clearServices: (state) => {
      state.services = []; // Resets the services array
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllServices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllServices.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.services = action.payload; // Ensure payload is the array of services
      })
      .addCase(fetchAllServices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// Export the synchronous actions
export const { clearError, clearServices } = servicesSlice.actions;

// Export the reducer
export default servicesSlice.reducer;
