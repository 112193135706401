import React from "react";
import Header from "../component/sharedComponents/header";
import FooterUser from "../component/sharedComponents/footer";
import SidebarUser from "../component/accountUser/sideBarUsers";
const AccountUser =()=>{
    return(
        <div>
        <Header/>
      <div className="mt-20">
      <SidebarUser/>
      </div>
<FooterUser/>
        </div>
    )
}

export default AccountUser;