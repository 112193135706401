import React, { useState } from 'react';
import { PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';
import CustomBanner from './customBanner';
const CustomBuyForMeCard = ({ image, title, size, count, price, isEditable ,order }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="relative cursor-pointer flex flex-col items-center justify-center bg-white
              overflow-hidden w-full  border"
        >
           <CustomBanner orderNumber={order} />
          
            <div className="relative w-full h-[200px] flex items-center justify-center">
       
                <img
                    src={image}
                    alt={title}
                    className="object-contain max-w-full max-h-full"
                />
                {isHovered && isEditable && (
                    <div className="absolute top-2 right-2 flex gap-2">
                        <button className="bg-yellow-500 p-2 rounded-full">
                            <PencilIcon className="w-4 h-4 text-black" />
                        </button>
                        <button className="bg-black p-2 rounded-full">
                            <XMarkIcon className="w-4 h-4 text-white" />
                        </button>
                    </div>
                )}
            </div>
            <div
                className={`flex flex-col items-start justify-center w-full p-4 transition-colors duration-300 ${
                    isHovered ? 'bg-black text-white' : 'bg-yellow-400 text-black'
                }`}
            >
                <h2 className="font-bold text-sm">{title}</h2>
            <div className='flex justify-between w-full'>
            <p className="text-xs">Size: {size}</p>
            <p className="text-xs">Count: {count}</p>
            </div>
                <p className="text-lg font-bold mt-2">{price} TL</p>
            </div>
        </div>
    );
};

export default CustomBuyForMeCard;
