import React from 'react';
import { NoSymbolIcon } from '@heroicons/react/24/outline';
import CustomBlockCard from '../explainBuyPage/customBlockCards';
import ImageBlockOne from '../../assets/images/blockOne.png';
import ImageBlockTwo from '../../assets/images/blockTwo.png';
import ImageBlockedThree from '../../assets/images/blockThree.png';
import ImageBlockedFour from '../../assets/images/blockFour.png';
import { useTranslation } from 'react-i18next';
const BlockedItems =()=>{
      const {t} =useTranslation();
    return(
        <div className='w-full pb-5'>
            <div className="flex items-center space-x-2">
        <NoSymbolIcon className="w-3 h-3 md:w-6 md:h-6 text-black" />
        <h2 className="text-lg md:text-3xl font-normal text-black">
            {t("ProhibitedItemsTitle")}
        </h2>
      </div>   
   <div className='w-full md:w-full md:flex md:space-x-4  mt-20 mb-20 '>
    <div className='w-full md:w-1/4'>
    <CustomBlockCard
      image={ImageBlockOne}
      title={t("WeaponsAndExplosives")}
      />
    </div>
             
   <div className='w-full md:w-1/4'>
   <CustomBlockCard
      image={ImageBlockTwo}
      title={t( "ValuableMaterials")}
      />
   </div>
 <div className=' w-full md:w-1/4'>
 <CustomBlockCard
      image={ImageBlockedThree}
      title={t("MedicalMaterials")}
      />
 </div>
<div className='w-full  md:w-1/4'>
<CustomBlockCard
      image={ImageBlockedFour}
      title={t("CounterfeitBrands")}
      />
</div>
   
     </div>
        </div>
    )
}

export default BlockedItems;