import React,{useState} from 'react';
import { MapPinIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import BoxOne from '../../assets/images/boxOne.png';
import BoxTwo from '../../assets/images/boxTwo.png';

const CustomContactUsCard =({FlagImage,title,Email,phoneNumberOne ,phoneNumberTwo,phoneNumberThree,Location,visible })=>{
 

    return (
        <div className="flex items-center justify-center w-full">
      <div className="flex items-center">
     
        {/* Title Section */}
        <div className="bg-white border border-black h-[300px]
         px-6 py-2  flex-col justify-center  text-right" style={{ direction: 'rtl' }}>
<div className='mb-2 flex flex-col items-center justify-center'>
  <img
    src={FlagImage}
    alt='flag'
    className='w-20 h-10 '  // Add bottom margin to space the image from the title
  />
  <div className='w-1/2 bg-custom-yellow px-1 py-1 md:px-2 md:py-2 text-center'>
    <h2 className="text-lg md:text-2xl text-black mb-2">{title}</h2>
  </div>
</div>

 <p className="text-sm text-gray-500 mb-6 flex">
              <EnvelopeIcon className="h-4 w-4 ml-1 text-custom-yellow" />
                <a href="mailto:info@example.com" className="text-gray-500 hover:underline">{Email}</a>
              </p>
  <p className="text-sm text-gray-500 mb-6 flex">
              <PhoneIcon className="h-4 w-4 ml-1 text-custom-yellow" />

                <a href="tel:+447418351039" className="text-gray-500 hover:underline">{phoneNumberOne}</a>
              </p>
              {visible && (
        <div className="transition-all duration-300 mb-6 opacity-100 h-auto">
          <p className="text-sm text-gray-700 flex items-center space-x-2">
            <PhoneIcon className="h-5 w-5 text-custom-yellow" />
            <a
              href={`tel:${phoneNumberTwo}`}
              className="text-gray-700 hover:underline"
            >
              {phoneNumberTwo}
            </a>
          </p>
        </div>
      )}
       
              <p className="text-sm text-gray-500 mb-6 flex">
              <MapPinIcon className="h-4 w-4 ml-1 text-custom-yellow" />

                <a
                  href="https://www.google.com/maps/search/?api=1&query=72+Gameat+Al-dewal+Al-Arabia,+Mohandessien"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-500 hover:underline"
                >
                {Location}
                </a>
              </p>
</div>

      </div>
    </div>
    )
}

export default CustomContactUsCard;