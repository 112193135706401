import React from "react";

const Steps = ({ activeStep }) => {
  const steps = [
    { id: 1, label: "اختر العناصر للشحن" },
    { id: 2, label: "اختر عنوان الشحن" },
    { id: 3, label: "ملخص الطلب" },
  ];

  return (
    <div className="flex justify-between items-center gap-6 bg-yellow-50 p-6">
      {steps.map((step) => (
        <div key={step.id} className="flex flex-col items-center justify-center">
          <div
            className={`flex items-center justify-center w-16 h-16 border-2 ${
              activeStep === step.id
                ? "bg-yellow-400 text-white border-yellow-400"
                : "bg-white text-black border-gray-300"
            }`}
          >
            <span className="text-lg font-bold">{step.id}</span>
          </div>
          <p className="text-sm text-center mt-1 text-black">{step.label}</p>
        </div>
      ))}
    </div>
  );
};

export default Steps;
