import React from 'react';
import BoxOne from '../../assets/images/boxOne.png';
import BoxTwo from '../../assets/images/boxTwo.png';

const CustomTitleWihBox =({title})=>{

    return(
      <div className="flex items-center justify-center">
      <div className="flex items-center">
        {/* BoxOne */}
        <img
          src={BoxTwo}
          alt="box"
          className="w-20 h-20 relative top-[-20px] right-[30px] lg:right-[-20px]"
        />
        {/* Title Section */}
        <div className="bg-custom-yellow inline-block px-4 md:px-8 py-2">
          <h2 className="text-lg lg:text-3xl text-black">{title}</h2>
        </div>
        {/* BoxTwo */}
        <img
          src={BoxOne}
          alt="box"
          className="w-20 h-20 relative top-[-20px] left-[30px] lg:left-[-20px]"
        />
      </div>
    </div>
    )
}

export default CustomTitleWihBox;