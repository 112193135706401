import React from "react";
import { useTranslation } from "react-i18next";
import ImageKwuait from '../../assets/images/flagKuawit.jpg';
import ImageEuropa from '../../assets/images/flagErupa.png';
const LanguageToggleButton = () => {
  const { i18n } = useTranslation();

  // Function to toggle between Arabic and English
  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'ar' : 'en';
    i18n.changeLanguage(newLanguage);
    document.documentElement.dir = newLanguage === 'ar' ? 'ltr'  : 'rtl';
  };
  // Get the correct flag image based on the current language
  const flagImage = i18n.language === "en" ? ImageEuropa : ImageKwuait;

  return (
    <button
      onClick={toggleLanguage}
      className="relative flex items-center justify-center  "
    >
      {/* Flag */}
      <img
        src={flagImage}
        alt={i18n.language === "en" ? "English Flag" : "Kuwait Flag"}
        className=" w-10 h-6 "
      />
    </button>
  );
};

export default LanguageToggleButton;
