import React from 'react';
import Header from '../component/sharedComponents/header';
import FooterUser from '../component/sharedComponents/footer';
import ContainerPrivcyAndPolicy from '../component/privcyPolicy/privacyPolicyContainer';
import CustomIntroSection from '../component/explainBuyPage/customIntroSection';
import { useTranslation } from 'react-i18next';
const PrivacyPolicyPage =()=>{
  const {t} = useTranslation();
    return (
        <div>
  <Header/>
 <div className='mt-20'>
 <CustomIntroSection
    title={t("privacyPolicy")}
    />
 </div>
  <ContainerPrivcyAndPolicy/>
  <FooterUser/>
        </div>
    )
}
export default PrivacyPolicyPage;