import React, { useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline"; // Optional: Use heroicons or other icons

export default function CustomPasswordInput({
  id,
  label,
  placeholder,
  value,
  onChange,
}) {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="relative w-full m-4">
      {/* Arabic Label */}
      {label && (
        <label
          htmlFor={id}
          className="block mb-2 text-sm font-medium text-right text-gray-700"
          dir="rtl" // Set label direction
        >
          {label}
        </label>
      )}
      <div className="relative">
        {/* Password Input Field */}
        <input
          id={id}
          type={isPasswordVisible ? "text" : "password"} // Toggle between text and password
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          dir="rtl" // Set input text direction
          className="w-full px-10 py-3 text-black text-xs sm:text-lg border border-gray-300 rounded-lg bg-white placeholder:text-base xxxs:placeholder:text-sm sm:placeholder:text-lg text-right"
        />
        {/* Toggle Button */}
        <span
          className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-700 cursor-pointer"
          onClick={togglePasswordVisibility}
        >
          {isPasswordVisible ? (
            <EyeSlashIcon className="h-5 w-5" /> // Icon for hidden password
          ) : (
            <EyeIcon className="h-5 w-5" /> // Icon for visible password
          )}
        </span>
      </div>
    </div>
  );
}
