import React from 'react';
import CopyableListInput from './CustomInputAndTitle';
import { useTranslation } from 'react-i18next';
const CustomTrukeySection =()=>{
  const {t} =useTranslation()
    return(
        <div>
           <CopyableListInput label={t( "FullName")}
           title='Turkishcorner'
           />
           <CopyableListInput label={t( "Name")}
           title='Turkish'
           />
           <CopyableListInput label={t("Surname")}
           title='Corner'
           />
           <CopyableListInput label={t( "City")}
           title='Bursa'
           />
           <CopyableListInput
            label={t( "Province")}
             title='Nilüfer'
           />
           <CopyableListInput label={t( "PostalCode")}
             title='16110'
           />
           <CopyableListInput label={t( "FullAddress")}
             title='odunluk mah. Ortaç sk. No: 11 A. Nilüfer/ bursa Box: 377086'

           />
           <CopyableListInput label={t( "CompanyName")}
            title='Turkish corner mobilya inşaat ithalat ihracat'

           />
           <CopyableListInput label={t("PhoneNumber")}
                 title='05331586084'
           />
           <CopyableListInput label={t(  "TaxCircle")}
         title='8711102117'
           />
           <CopyableListInput  label={t("TaxNumber")}
           title='8711102117'
           />

        </div>
    )
}

export default CustomTrukeySection;