// src/redux/slices/jwtCheckSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API_BASE_URL from "../constent";


// Async thunk for JWT check
export const checkJWT = createAsyncThunk(
    "jwtCheck/checkJWT",
    async (_, { rejectWithValue }) => {
      try {
        // Retrieve the token from localStorage
        const token = localStorage.getItem("accessToken");
  
        // Check if the token exists, throw an error if not found
        if (!token) throw new Error("No access token found");
  
        // Send the token to the server for verification
        const response = await axios.get(`${API_BASE_URL}/users/check/JWT`, {  
             headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        // Return the response data
        return response.data.user;
      } catch (error) {
        // Handle errors and reject with the appropriate error message
        return rejectWithValue(error.response?.data || error.message || "An error occurred while checking JWT");
      }
    }
  );
// Slice
const jwtCheckSlice = createSlice({
  name: "jwtCheck",
  initialState: {
    isValid: null, // Represents whether the token is valid
    userDetails: null, // User data (if included in the response)
    loading: false,
    error: null,
  },
  reducers: {
    resetJWTCheck: (state) => {
      state.isValid = null;
      state.userDetails = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(checkJWT.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkJWT.fulfilled, (state, action) => {
        state.loading = false;
        state.isValid = true; // Assuming this field is used for validation
        state.userDetails = action.payload; // Ensure this contains the correct `boxNumber`
      })
      .addCase(checkJWT.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const { resetJWTCheck } = jwtCheckSlice.actions;
export default jwtCheckSlice.reducer;
