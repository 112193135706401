import React from 'react';
import ImageBackground from '../../assets/images/Rectangle 42405.png';
import BoxOne from '../../assets/images/boxOne.png';
import BoxTwo from '../../assets/images/boxTwo.png';

const CustomIntroSection = ({ title }) => {
  return (
    <div className="relative w-full h-[300px]">
      {/* Background Image */}
      <img
        src={ImageBackground}
        alt="Your Image Description"
        className="w-full h-full object-cover"
      />
      {/* Centered Content */}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="flex items-center">
          {/* BoxOne */}
          <img
            src={BoxTwo}
            alt="box"
            className="w-20 h-20 relative top-[-20px] right-[-20px]"
          />
          {/* Title Section */}
          <div className="bg-custom-yellow inline-block px-8 py-2">
            <h2 className="text-lg md:text-3xl text-black">{title}</h2>
          </div>
          {/* BoxTwo */}
          <img
            src={BoxOne}
            alt="box"
            className="w-20 h-20 relative top-[-20px] left-[-20px]"
          />
        </div>
      </div>
    </div>
  );
};

export default CustomIntroSection;
