import React, { useState, useEffect } from "react";

const EditItemModal = ({ item, onSave, onClose }) => {
  const [formData, setFormData] = useState({
    name: item.name,
    productSize: item.productSize,
    productCount: item.productCount,
    id: item.id, // Ensure the ID is included
  });

  useEffect(() => {
    setFormData({
      name: item.name,
      productSize: item.productSize,
      productCount: item.productCount,
      id: item.id,
    });
  }, [item]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data to save:", formData); // Log the form data
    onSave(formData);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h3 className="text-xl mb-4">Edit Item</h3>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label className="block">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-2 border rounded bg-white"
                required
              />
            </div>
            <div className="flex space-x-4">
              <div>
                <label className="block">Product Size</label>
                <input
                  type="text"
                  name="productSize"
                  value={formData.productSize}
                  onChange={handleChange}
                  className="w-full p-2 border rounded bg-white"
                  required
                />
              </div>
              <div>
                <label className="block">Product Count</label>
                <input
                  type="number"
                  name="productCount"
                  value={formData.productCount}
                  onChange={handleChange}
                  className="w-full p-2 border rounded bg-white"
                  required
                />
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <button
                type="button"
                onClick={onClose}
                className="bg-gray-500 text-white p-2 rounded"
              >
                Cancel
              </button>
              <button type="submit" className="bg-blue-500 text-white p-2 rounded">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditItemModal;
