import React, { useState } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

const CustomCategoryCard = ({ image, title }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave} 
            className="cursor-pointer flex flex-col items-center justify-center text-center w-30"
        >
            <img
                src={image}
                alt="image"
                className="w-30 h-40"
            />
            <div
                className={`w-full py-2 ${isHovered ? 'bg-black' : 'bg-custom-yellow'}`}
            >
                <h2 className={`flex items-center justify-center text-lg text-center ${isHovered ? 'text-white' : 'text-black'}`}>
                    {isHovered && (
                        <div className="flex ">
                            <ChevronLeftIcon className="w-3 h-3 text-white" />
                            <ChevronLeftIcon className="w-3 h-3 text-white" />
                            <ChevronLeftIcon className="w-3 h-3 text-white" />
                        </div>
                    )}
                    <span className="mx-1">{title}</span>
                </h2>
            </div>
        </div>
    );
};

export default CustomCategoryCard;
