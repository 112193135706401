import React from 'react'
import { useTranslation } from 'react-i18next';
const NodeTitle =()=>{
  const {t} = useTranslation();
    return (
        <div className="mt-4">
        <p className="text-red-500 text-right">
          <span className="text-red-500 font-bold">*</span> {t("NoteBuyForMe")}
        </p>
      </div>
    )
}

export default NodeTitle;