import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../reducer/authReducer";
import  userAddressReducer from '../reducer/userAddressReducer';
import userOurServices from '../reducer/ourServicesReducer';
import userMainTurkishWebsites from '../reducer/turkishWebsitesMainReducer';
import websitesEuropeanReducer from '../reducer/europaWebsitesMainReducer';
import shopCartReducer from '../reducer/shopCartReducer';
import buyForMeOrdersReducer from '../reducer/buyForMeReducer';
import paymentReducer from "../reducer/paymentReducer";
import myShipmentReducer from "../reducer/myShipmentReducer";
import shipAndShopReducer from '../reducer/shipandShopOrdersReducer';
import paymentShipmentReducer from "../reducer/paymentShipmentReducer";
import CheckJwtReducer  from '../reducer/jwtCheckReducer';
import notificatioReducer from '../reducer/notificationReducer';
import  shipmentCostReducer from '../reducer/shipmentCostReducer';
import contactReducer from '../reducer/contactUsReducer';
import currenciesReducer from '../reducer/currancyReducer';
export const store = configureStore({
  reducer: {
    auth: authReducer,
    userAddress: userAddressReducer,
    services: userOurServices, 
    websites: userMainTurkishWebsites,
    websitesEuropean: websitesEuropeanReducer, // Add the European websites slice here
    shopCart: shopCartReducer,
    buyForMeOrders: buyForMeOrdersReducer, // Ensure this matches your slice name
    payment : paymentReducer,
    myShipment : myShipmentReducer,
    shipAndShop: shipAndShopReducer,
    shipmentCostPayment: paymentShipmentReducer,
    jwtCheck :CheckJwtReducer ,
    notifications: notificatioReducer,
    shipmentCost : shipmentCostReducer,
    contact: contactReducer,
    currencies: currenciesReducer
  },
});
