import React, { useState } from 'react';
import CustomTrukeySection from './customTrukeySection';
import CustomEropaSection from './customEroupaSection';
import { useTranslation } from 'react-i18next';
export default function CartChooseButton() {
  const [selectedMenuItem, setSelectedMenuItem] = useState('CustomTrukeySection');
  const {t} =useTranslation();
  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  return (
    <div className="w-full flex flex-col items-center mt-10 md:mr-10">
      {/* Buttons Section */}
      <div className="flex mb-6 space-x-4">
        <button
          className={`text-lg px-6 py-3 font-bold rounded-md focus:outline-none transition-colors ${
            selectedMenuItem === 'CustomTrukeySection' ? 'bg-custom-yellow text-black' : 'bg-white text-black'
          }`}
          onClick={() => handleMenuItemClick('CustomTrukeySection')}
        >
     {t("TurkishBox")}
        </button>
        <button
          className={`text-lg px-6 py-3 font-bold rounded-md focus:outline-none transition-colors ${
            selectedMenuItem === 'CustomEropaSection' ? 'bg-custom-yellow text-black' : 'bg-white text-black'
          }`}
          onClick={() => handleMenuItemClick('CustomEropaSection')}
        >
      {t("EuropeBox")}
        </button>
      </div>

      {/* Content Section */}
      <div className="w-full">
        {selectedMenuItem === 'CustomTrukeySection' && (
          <div>
            <CustomTrukeySection />
          </div>
        )}
        {selectedMenuItem === 'CustomEropaSection' && (
          <div>
            <CustomEropaSection />
          </div>
        )}
      </div>
    </div>
  );
}
