import React from "react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
const CustomTitleAccount =({ onClick,title ,icon})=>{
    const {t} =useTranslation()
    return(
        <div className="flex justify-between">
         
    <div className="flex">
    {icon && <span className=" pointer-events-none">{icon}</span>}

        <h2 className="text-lg md:text-3xl font-normal text-black">
            {title}
        </h2>
     
    </div>
    <button
      onClick={onClick}
      className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-black
       bg-transparent rounded-lg  focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      {/* Static Text */}
      <span>
        {t("edit")}
        </span> {/* Arabic text for "Edit" */}
      <PencilSquareIcon className="w-4 h-4 text-black"/>

    </button>
        </div>
    )
}

export default CustomTitleAccount;