import React from "react";

const CustomBigText = ({ title }) => {
  return (
    <div className="bg-custom-yellow inline-block px-4 md:px-8 py-2">
      <h2 className="text-sm md:text-3xl text-black">
        {title}
      </h2>
    </div>
  );
};

export default CustomBigText;
