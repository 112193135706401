import React from "react";
import { useLocation } from "react-router-dom";

const IframePage = () => {
  const location = useLocation();
  const { iframeSrc } = location.state || {}; // Extract the iframe source from state

  if (!iframeSrc) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-xl font-semibold text-red-600">
          No website link provided.
        </p>
      </div>
    );
  }

  return (
    <div className=" h-screen mx-20 py-20" >
      <iframe
        src={iframeSrc}
        className="w-full h-full"
        title="Embedded Website"
        allowFullScreen
      />
    </div>
  );
};

export default IframePage;
