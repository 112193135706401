import React, { useEffect } from "react";
import {
  UserIcon,
  ShoppingCartIcon,
  Bars4Icon,
  XMarkIcon,
  BellIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AvaterImage from '../../assets/images/av.jpg';
import { checkJWT } from "../../redux/reducer/jwtCheckReducer";
import { logoutUser } from "../../redux/reducer/authReducer";
const ValidMennItem = () => {
    const { t, i18n } = useTranslation();
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isValid, userDetails, loading, error } = useSelector((state) => state.jwtCheck);
  
    useEffect(() => {
      // Check if the token exists before dispatching checkJWT
      const token = localStorage.getItem("accessToken");
      if (token) {
        dispatch(checkJWT()); // Trigger JWT check only if token exists
      } else {
        // Set isValid to false if token doesn't exist
        dispatch(checkJWT());
      }
  
      const handleResize = () => setIsMobile(window.innerWidth <= 768);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [dispatch]);
  
    const name = userDetails?.name || t("Unknown");
  
    // Handle loading and error states
    if (loading) {
      return <p>{t("loading")}...</p>;
    }
  
    if (error) {
      return <p className="text-red-500">{t("error_occurred")}: {error}</p>;
    }
  
    const handleLogout = async () => {
      await dispatch(logoutUser());
      localStorage.removeItem("accessToken");
      navigate("/login");
    };
  
    return (
      <div>
        {isValid && (
          <>
            <MenuItem>
              <div className="flex items-center">
                <div className="w-12 h-12 m-2 rounded-full bg-gray-200 overflow-hidden border border-gray-300">
                  <img
                    src={AvaterImage}
                    alt="User Avatar"
                    className="w-full h-full object-cover"
                  />
                </div>
                <h3 className="text-sm md:text-sm text-black">{name}</h3>
              </div>
            </MenuItem>
  
            <MenuItem>
              <Link
                to="/AccountUser"
                onClick={() => {
                    // Force reload after navigation
                    window.location.href = "/AccountUser";
                  }}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                {t("dashboard")}
              </Link>
            </MenuItem>
  
            <MenuItem>
              <a
                onClick={handleLogout}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                {t("logOut")}
              </a>
            </MenuItem>
          </>
        )}
      </div>
    );
  };
  
  export default ValidMennItem;
  