import React from "react";

const CustomBuyForMeButton = ({ children, onClick, icon: Icon }) => {
    return (
        <div className="flex items-center justify-center my-10 ">
            <button
                onClick={onClick}
                className="bg-custom-yellow text-black py-3 px-6 rounded-custom
                text-base sm:text-sm md:text-base lg:text-sm xl:text-sm inline-flex items-center justify-center
                transition duration-300 ease-in-out transform hover:scale-105 
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color 
                whitespace-nowrap"
            >
                {children}
                {Icon && (
                    <Icon className="w-4 text-black mr-2" />
                )}
            </button>
        </div>
    );
};

export default CustomBuyForMeButton;
