import React from "react";
import Header from '../component/sharedComponents/header';
import DataSection from "../component/contactUs/dataSection";
import FooterUser from "../component/sharedComponents/footer";
import CustomIntroSection from "../component/explainBuyPage/customIntroSection";
import { useTranslation } from "react-i18next";

const ContactUsPage =()=>{
    const {t} = useTranslation();
    return(
        <div>
            <Header/>
   <div className="mt-20">
   <CustomIntroSection
    title={t("contactUs")}
    />
   </div>
<DataSection/>
<FooterUser/>
        </div>
    )
}

export default ContactUsPage;