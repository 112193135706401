import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomTitleTerms from '../termsAndConditions/customTitleTerms';
import CustomTermPoints from '../termsAndConditions/customTermPoints';

const ShippingInsuranceContainer = () => {
  const { t, i18n } = useTranslation();

  // Check if the current language is Arabic
  const isRTL = i18n.language === 'ar';

  return (
    <div className={`px-5 lg:px-40 bg-white ${isRTL ? 'rtl' : 'ltr'}`} dir={isRTL ? 'rtl' : 'ltr'}>
      <div className={`bg-white ${isRTL ? 'text-right' : 'text-left'} px-5 lg:px-20 shadow-lg py-10 lg:py-20 space-y-2`}>
        {/* Shipping Insurance */}
        <CustomTitleTerms title={t('shippingInsurance.title')} />
        <CustomTermPoints title={t('shippingInsurance.description')} />

        {/* Shipping Insurance Terms */}
        <CustomTitleTerms title={t('shippingInsurance.termsTitle')} />
        {t('shippingInsurance.terms', { returnObjects: true }).map((term, index) => (
          <CustomTermPoints key={index} title={term} />
        ))}

        {/* Cases Not Covered */}
        <CustomTitleTerms title={t('shippingInsurance.exclusionsTitle')} />
        {t('shippingInsurance.exclusions', { returnObjects: true }).map((exclusion, index) => (
          <CustomTermPoints key={index} title={exclusion} />
        ))}

        {/* Compensation Process */}
        <CustomTitleTerms title={t('shippingInsurance.compensationTitle')} />
        {t('shippingInsurance.compensation', { returnObjects: true }).map((compensation, index) => (
          <CustomTermPoints key={index} title={compensation} />
        ))}
      </div>
    </div>
  );
};

export default ShippingInsuranceContainer;
