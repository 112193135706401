// src/redux/slices/shipmentCostPaymentSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API_BASE_URL from '../constent'
// Async thunk to call the API
export const fetchShipmentCostPayment = createAsyncThunk(
  "shipmentCostPayment/fetchShipmentCostPayment",
  async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/shipment-cost-payment/payment`, payload);
        return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

const shipmentCostPaymentSlice = createSlice({
  name: "shipmentCostPayment",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetShipmentCostPayment: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShipmentCostPayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShipmentCostPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchShipmentCostPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetShipmentCostPayment } = shipmentCostPaymentSlice.actions;
export default shipmentCostPaymentSlice.reducer;
