import React from "react";
import { useTranslation } from "react-i18next";

const CustomTermPoints = ({ title }) => {
  const { i18n } = useTranslation();

  // Check if the current language is Arabic
  const isRTL = i18n.language === "ar";

  return (
    <div className={`p-4 rounded-md ${isRTL ? "text-right" : "text-left"}`}>
      <ul className={`list-disc list-inside ${isRTL ? "pr-5" : "pl-5"}`}>
        <li className="text-black text-lg leading-relaxed">{title}</li>
      </ul>
    </div>
  );
};

export default CustomTermPoints;
