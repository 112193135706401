import React from "react";

const CustomBuyForMeButtonOrders = ({
    children,
    onClick,
    icon: Icon,
    bgColor = "bg-custom-yellow",
    textColor = "text-black",
    iconColor = "text-black"
}) => {
    return (
        <div className="flex items-center justify-center my-10">
            <button
                onClick={onClick}
                className={`${bgColor} ${textColor} py-1 px-2 lg:py-3 lg:px-6 rounded-custom
                    text-base sm:text-sm md:text-base lg:text-sm xl:text-sm inline-flex items-center justify-center
                    transition duration-300 ease-in-out transform hover:scale-105 
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color 
                    whitespace-nowrap`}
            >
                {children}
                {Icon && (
                    <Icon className={`w-4 ${iconColor} mr-2`} />
                )}
            </button>
        </div>
    );
};

export default CustomBuyForMeButtonOrders;
