import React, { useState } from 'react';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline';

const CustomBlockCard = ({ image, title }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave} 
            className="cursor-pointer flex flex-col items-center justify-center text-center mt-5"
        >
            <div className="relative">
                {isHovered && (
                    <ChevronDoubleDownIcon className="w-6 h-6 text-black absolute top-[-30px]" />
                )}
            </div>
            <img
                src={image}
                alt="image"
                className="w-full h-[150px]"
            />
         <div
  className={`px-4 mt-2 inline-block w-full h-[90px] ${
    isHovered ? 'bg-black' : 'bg-custom-yellow'
  } flex items-center justify-center`}
>
  <h2 className={`text-sm md:text-lg ${isHovered ? 'text-white' : 'text-black'}`}>
    {title}
  </h2>
</div>

        </div>
    );
};

export default CustomBlockCard;
