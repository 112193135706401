import React, { useState } from 'react';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline';
const CustomBrandsCard = ({ image, title, link, onCardClick }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onCardClick} // Navigate on click
            className="cursor-pointer flex flex-col items-center justify-center text-center mx-4 w-48" // Fixed width
        >
            <div className="relative">
                {isHovered && (
                    <ChevronDoubleDownIcon className="w-6 h-6 text-black absolute top-[-30px]" />
                )}
            </div>
            {image && (
                <img
                    src={image}
                    alt={title || "Image"}
                    className="w-35 h-40 object-cover rounded-lg"
                />
            )}
        
     
<div
                className={`w-full px-4 inline-block ${
                    isHovered ? 'bg-black' : 'bg-custom-yellow'
                }`}  
            >
                <h2 className={`text-sm lg:text-lg py-2 ${isHovered ? 'text-white' : 'text-black'}`}>
                    {title}
                </h2>
            </div>
        </div>
    );
};

export default CustomBrandsCard;