import React, { useEffect, useState, useRef } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import CustomBigText from '../sharedComponents/customBigText';
import StarReview from '../sharedComponents/customReviewStars';
import image from '../../assets/images/Polygon 6.png';
import imageCircle from '../../assets/images/bd2c8624ccaf55dea33d5488c7f04b8b.png';
import { useTranslation } from 'react-i18next';
const Reviews = [
  {
    id: 1,
    name: 'افضل شركة شحن تعاملت معاهم .. كل شي واضح وتغليف مرتب ونظيف وخدمة عملاء محترمين مره ومتعاونين الله يوففهم',
    subTitle: 'محمد علي '
  },
  {
    id: 2,
    name: 'افضل شركة شحن تعاملت معاهم .. كل شي واضح وتغليف مرتب ونظيف وخدمة عملاء محترمين مره ومتعاونين الله يوففهم',
    subTitle: 'محمد علي '
  },
  {
    id: 3,
    name: 'افضل شركة شحن تعاملت معاهم .. كل شي واضح وتغليف مرتب ونظيف وخدمة عملاء محترمين مره ومتعاونين الله يوففهم',
    subTitle: 'محمد علي '
  },
  {
    id: 4,
    name: 'افضل شركة شحن تعاملت معاهم .. كل شي واضح وتغليف مرتب ونظيف وخدمة عملاء محترمين مره ومتعاونين الله يوففهم',
    subTitle: 'محمد علي '

  },
  {
    id: 5,
    name: 'افضل شركة شحن تعاملت معاهم .. كل شي واضح وتغليف مرتب ونظيف وخدمة عملاء محترمين مره ومتعاونين الله يوففهم',
    subTitle: 'محمد علي '
  },
  {
    id: 6,
    name: 'افضل شركة شحن تعاملت معاهم .. كل شي واضح وتغليف مرتب ونظيف وخدمة عملاء محترمين مره ومتعاونين الله يوففهم',
    subTitle: 'محمد علي '

  },
];
export default function CustomReviewsSection() {
  const scrollRef = useRef(null);
  const [scrollIndex, setScrollIndex] = useState(0);
  const itemsPerScroll = 3; // Number of items per scroll action
  const numberOfDots = Math.ceil(Reviews.length / itemsPerScroll);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [isTablet, setIsTablet] = useState(window.innerWidth >= 640 && window.innerWidth < 1024);
  const {t} = useTranslation();
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
      setIsTablet(window.innerWidth >= 640 && window.innerWidth < 1024);
    };
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const handleClick = () => {
    navigate('/eventInside');
  };

  const scrollOneItem = (direction) => {
    if (scrollRef.current) {
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      const scrollAmount = direction === 'left' ? -itemWidth : itemWidth;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const scrollToIndex = (index) => {
    if (scrollRef.current) {
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      scrollRef.current.scrollTo({ left: index * itemWidth * itemsPerScroll, behavior: 'smooth' });
      setScrollIndex(index);
    }
  };

  const updateScrollIndex = () => {
    if (scrollRef.current) {
      const scrollLeft = scrollRef.current.scrollLeft;
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      const newIndex = Math.floor(scrollLeft / (itemWidth * itemsPerScroll));
      setScrollIndex(newIndex);
    }
  };

  useEffect(() => {
    const handleScroll = () => updateScrollIndex();
    const container = scrollRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="justify-center items-center  pb-2 w-full ">
      <div className="flex flex-col items-center justify-center text-center px-custom-padding">
        <div className="flex justify-between items-center">
          {/* Line 1 */}
          <div className="bg-white w-[2px] h-[100px] mr-20 lg:mr-40"></div>
          {/* Space Between */}
          <div className="bg-white w-[2px] h-[100px]"></div>
        </div>
        <CustomBigText title={t("customerFeedback")} />
        <div className="pt-5 xxxs:px-1 xxs:px-1 xs:px-1 lg:px-8 xl:px-8 1xl:px-8
         11xl:px-8 2xl:px-8 22xl:px-8 3xl:px-8 mx-2 mb-20 justify-center items-center">
          <div className="relative flex items-center">
            {/* Left Arrow */}
            <button
              onClick={() => scrollOneItem('left')}
              className="absolute left-[-40px] lg:left-[-100px]
              mr-4 lg:mr-12 z-10 p-2 text-white bg-primary-color rounded-full shadow-md focus:outline-none"
            >
              <ChevronDoubleLeftIcon className='w-5 h-5 lg:w-10 lg:h-10' />
            </button>

            <div
            ref={scrollRef}
            className="flex overflow-x-auto space-x-6 lg:space-x-6 w-full scrollbar-hidden"
            style={{
              maxWidth: isMobile
                ? "calc(1 * 290px + 1 * 30px)" // Full width for mobile (1 item)
                : isTablet
                ? "calc(2 * 290px + 1 * 30px)" // Width for 2 items on tablet
                : "calc(3 * 290px + 2 * 30px)", // Width for 3 items on desktop
              scrollSnapType: "x mandatory",
            }}
          >
              {Reviews.map((product) => (
             <div className='bg-transparent'>
    <div
                key={product.id}
                className="flex-none w-[290px] sm:w-[45%] lg:w-[290px]
                 bg-white rounded-lg shadow-lg p-4 my-5"
                style={{ scrollSnapAlign: "start" }}
              >
                  <div className="flex flex-col items-center justify-center mt-5">
                    <h2 className="items-center justify-center text-2xl text-center text-white h-8 w-8 bg-custom-yellow rounded-full">,,</h2>
                    <h2
                      className="lg:text-lg xl:text-lg 1xl:text-xl 11xl:text-xl 2xl:text-xl text-gray-700 font-normal cursor-pointer mt-2"
                      onClick={handleClick}
                    >
                      {product.name}
                    </h2>
                    <h2
                      className="lg:text-lg xl:text-lg 1xl:text-xl 11xl:text-xl 2xl:text-xl text-gray-700 font-semibold cursor-pointer mt-2 mb-3"
                      onClick={handleClick}
                    >
                      {product.subTitle}
                    </h2>
                    <StarReview />
                  </div>
                </div>

                <div className='flex items-center justify-center'>
                <img
    src={imageCircle}
    alt='image'
    className='relative bottom-[-10px] rounded-full w-10 h-10'
  />
  <img
    src={image}
    alt='image'
    className='relative top-[-32px]'
  />
</div>


             </div>
              ))}
            </div>

            {/* Right Arrow */}
            <button
              onClick={() => scrollOneItem('right')}
              className="absolute right-[-40px]  lg:right-[-100px] ml-4 lg:ml-12 
              z-10 p-2 text-white bg-primary-color rounded-full shadow-md focus:outline-none"
            >
              <ChevronDoubleRightIcon className='w-5 h-5 lg:w-10 lg:h-10' />
            </button>
          </div>

          {/* Dots Container */}
          <div className="flex justify-center mt-4">
            {Array.from({ length: numberOfDots }).map((_, index) => (
              <div
                key={index}
                onClick={() => scrollToIndex(index)}
                className={`w-3 h-3 mx-1 rounded-full cursor-pointer ${index === scrollIndex ? 'bg-primary-color' : 'bg-gray-300'}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
