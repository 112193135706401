import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/reducer/authReducer"; // Adjust the path as needed
import CustomTitleWihBox from "../sharedComponents/customTitleWithBox";
import CustomInput from "./CustomInput";
import { EnvelopeIcon, LockOpenIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import CustomPasswordInput from "../accountUser/customPasswordInput";
import { useTranslation } from "react-i18next";
export default function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use the useNavigate hook
  const {t} = useTranslation();
  const handleSubmit = (e) => {
    e.preventDefault();

    // Dispatch loginUser with email and password
    dispatch(loginUser({ email, password }))
      .unwrap() // Unwrap the action result to handle the response
      .then((response) => {
        // Save the accessToken and userId to localStorage
        localStorage.setItem("accessToken", response.accessToken);
        localStorage.setItem("userId", response.user._id);

        // Optionally, use the custom handleClick to navigate and reload the page
        handleClick(); 

        // Show a success alert
        alert("Login successful!");
      })
      .catch((error) => {
        // Optionally handle login errors if needed
        console.error("Login failed:", error);
        alert("Login failed! Please check your credentials.");
      });
  };

  // handleClick to perform the navigation and force page reload
  const handleClick = () => {
    window.location.href = "/"; // Redirect to the home page
  };

  return (
    <div
      className="block shadow-lg rounded-lg bg-white p-8 mx-auto md:w-1/2 text-right"
      dir="rtl"
    >
      <CustomTitleWihBox title={t("LoginTitle")} />
      <form onSubmit={handleSubmit}>
        <div className="md:flex">
          {/* Email */}
          <div className="mb-6 md:w-1/2 my-4">
          <label
        
          className="block mb-2 text-sm font-medium text-right text-gray-700"
          dir="rtl" // Set the label direction
        >
          {t("Email")}
        </label>
            <CustomInput
              id="email"
              type="email"
              placeholder={t("EnterYourEmail")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              icon={<EnvelopeIcon />}
              required
            />
          </div>

          {/* Password */}
          <div className="mb-6 md:w-1/2 md:mr-2">
          <CustomPasswordInput
  id="password"
  label={t("Password")}
  placeholder={t("Enteryourpassword")}
  value={password}
  onChange={(e) => setPassword(e.target.value)}
  icon={<LockOpenIcon />} // Optional: Add an icon prop if supported
  required
/>

          </div>
        </div>
        <div
  onClick={() => (window.location.href = "/ForgetPasswordPage")}
  className="text-blue-600 hover:underline cursor-pointer"
>
  {t("ForgotPassword")}
</div>

        {/* Submit Button */}
        <div className="flex justify-center mb-6">
          <button
            type="submit"
            className="block w-full rounded-lg bg-custom-yellow px-6 py-3 text-base font-medium uppercase
              text-black shadow-lg transition duration-150 ease-in-out hover:bg-primary-600 focus:outline-none"
          >
          {t("LoginTitle")}
          </button>
        </div>

        {/* Navigation to Registration */}
        <p className="text-center text-sm text-gray-600">
        {t("NoAccount")}{" "}
          <div
  onClick={() => (window.location.href = "/RegistrationPage")}
  className="text-blue-600 hover:underline cursor-pointer"
>
{t("RegisterHere")}
</div>
        </p>
      </form>
    </div>
  );
}
