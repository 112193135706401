import React from 'react';

const CustomButton = ({ children, onClick, icon: Icon }) => {
  return (
    <button 
      onClick={onClick} 
      className="bg-custom-yellow text-black py-2 px-4 rounded-custom
        text-base sm:text-sm md:text-base lg:text-sm xl:text-sm 1xl:text-sm
        11xl:text-sm 2xl:text-sm 3xl:text-sm inline-flex items-center justify-center
        transition duration-300 ease-in-out transform hover:scale-105 
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color z-0 
        whitespace-nowrap"
    >
          {Icon && (
       <Icon className="w-4 text-black mr-1 ml-1" />
      
      )}
      {children}
    
    </button>
  );
};

export default CustomButton;

