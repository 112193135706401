import React from 'react';
import {ChevronDoubleDownIcon ,InboxIcon} from '@heroicons/react/24/outline'
import CustomLines from "./customLines";
import CustomBigText from "../sharedComponents/customBigText";
import image from '../../assets/images/serviceBuyImage.png';
import CustomFlags from '../../component/sharedComponents/customFlags';
import imageTurkey from '../../assets/images/turkey.png';
import imageOroba from '../../assets/images/oroba.png';
import imageWorld from '../../assets/images/world.png';
import CustomButton from "../sharedComponents/customButton";
import { useTranslation } from 'react-i18next';
const ServicesBuySection =()=>{
  const {t} =useTranslation();
    return (
        <div className="md:flex  px-4 lg:px-custom-padding  justify-between">

           <div className=" justify-start">
           <div className="flex flex-col items-center justify-center">
           <div className="flex justify-between items-center">
          {/* Line 1 */}
          <div className="bg-white w-[2px] h-[100px] mr-20"></div>
          {/* Space Between */}
          <div className="bg-white w-[2px] h-[100px]"></div>
        </div>
  <CustomBigText title={t('servicesBuySection.title')} className="mt-4" />
</div>

<div dir="rtl">
  <p className="text-sm text-white mt-5">
  {t('servicesBuySection.description')}
    </p>
</div>
<div className="flex justify-center items-center gap-8 mt-10">
<CustomFlags 
       image={imageTurkey}
       title={t('servicesBuySection.turkishStores')} 
       />
       <h2 className="text-2xl font-semibold text-white mt-10">{t("allShippingSection.and")}</h2>
                    <CustomFlags 
       image={imageOroba}
       title={t('servicesBuySection.europeanStores')}
       />
      </div>
<div className="flex flex-col items-center justify-center mt-5">
<div dir="rtl">
  <p className="text-sm text-white mt-5">
  {t('servicesBuySection.description')} </p>
</div>
  <ChevronDoubleDownIcon className="w-6 h-6 text-white mb-2 mt-3" />
  <CustomButton 
    children={t('servicesBuySection.buttonLabel')}
    icon={InboxIcon} 
  />
</div>

      </div>
      <div className='md:w-1/2'>
      <img
        src={image}
        alt="image"
        className=" h-[600px] w-full "
        />
      </div>
     </div>
    )
}
export default ServicesBuySection;