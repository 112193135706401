import React, { useState } from 'react';
import { PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';

const CustomBuyForMeCard = ({ image, title, size, count, price, isEditable,status ,checked,onChange ,del}) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="relative cursor-pointer flex flex-col items-center justify-center bg-white
              overflow-hidden w-full  border"
        >  
            <div className="relative w-full h-[200px] flex items-center justify-center">
          
                <img
                    src={image}
                    alt={title}
                    className="object-contain  max-h-full"
                />
                {isHovered && isEditable && (
                    <div className="absolute top-2 right-2 flex gap-2 justify-between">
                        <button className="bg-yellow-500 p-2 rounded-full">
                            <PencilIcon className="w-4 h-4 text-black" />
                        </button>
                        <button className="bg-black p-2 rounded-full"
                        onClick={del}
                        >
                            <XMarkIcon className="w-4 h-4 text-white" />
                        </button>
                    </div>
                )}
            </div>
            <div
                className={`flex flex-col items-start justify-center w-full p-4 transition-colors duration-300 ${
                    isHovered ? 'bg-black text-white' : 'bg-yellow-400 text-black'
                }`}
            >
<h2 className="font-bold text-sm overflow-hidden text-ellipsis whitespace-nowrap">{title}</h2>

            <div className='flex justify-between w-full'>
            <p className="text-xs">Size: {size}</p>
            <p className="text-xs">Count: {count}</p>
            </div>
            <p className='text-xs'>{status}</p>

                <p className="text-lg font-bold mt-2">{price} TL</p>
        <div className='flex'>
        <input
                className='w-5 h-5'
                type="checkbox"
                checked={ checked}
                onChange={onChange}
              />
                        <span className="mr-2">Check</span>
        
        </div>
            </div>
        </div>
    );
};

export default CustomBuyForMeCard;
