import React ,{useState} from 'react';
import { MegaphoneIcon, CreditCardIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
const CustomPrivacySection = () => {
    const [checked, setChecked] = useState(false);
    const {t} = useTranslation()
    const handleChange = () => {
      setChecked(!checked);
    };
  return (
    <div className="bg-white my-10 p-6 flex flex-col justify-center items-center">
      {/* Header Section */}
      <div className="flex items-center space-x-2">
        <MegaphoneIcon className="w-6 h-6 text-black" />
        <h2 className="text-3xl font-normal text-black">
        {t('VerifyOrderQuality')}
        </h2>
      </div>

      {/* Message Section */}
      <p className="text-lg text-gray-600 text-center mt-4">
      {t('OrderInspectionDetails')}
     </p>

            <div className="flex items-center space-x-2">
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        className="form-checkbox h-5 w-5 text-blue-600"
      />
      <label className="text-xl font-medium text-gray-700">
      {checked ? t('Selected') : t('FreeInspectionRequest')}
      </label>
    </div>
    </div>
  );
};

export default CustomPrivacySection;
