import React from 'react';
import Header from '../component/sharedComponents/header';
import FooterUser from '../component/sharedComponents/footer';
import ForgotPasswordForm from '../component/auth/forgetPasswordForm';
const ForgetPasswordPage =()=>{
    return(
        <div>
<Header/>
<ForgotPasswordForm/>
<FooterUser/>
        </div>
    )
}

export default ForgetPasswordPage;