import React, { useEffect, useState } from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import CustomBuyForMeCard from "./customBuyForMeCard";
import { getShopCartItemById } from "../../redux/reducer/shopCartReducer";
import CustomBuyForMeButton from "./customButtonBuyForMe";
import { submitItemsToPricing, deleteShopCartItem } from "../../redux/reducer/shopCartReducer";
import PaymentDetails from "../ordersForBuyForMe/paymentDetials";
import NodeTitle from "../ordersForBuyForMe/noteTitle";
import CustomPrivacySection from "../ordersForBuyForMe/customPrivacySection";
import CustomBuyForMeButtonOrders from "../ordersForBuyForMe/customOrderBuyButton";
import PaymentForm from "../accountUser/accountPaymentSection";
import CustomOrderInstructionCard from "../ordersForBuyForMe/customOrderInstructionCard";
import { useTranslation } from "react-i18next";
const ItemsBuyMeSection = () => {
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = useState([]); // State to track selected item IDs
  const [hasPricedItem, setHasPricedItem] = useState(false); // State to track if any item is "Priced"
  const [timeRemaining, setTimeRemaining] = useState(3600); // 1 hour in seconds
  const [showPaymentForm, setShowPaymentForm] = useState(false); // State to toggle sections
  const {t} =useTranslation();
  const handleButtonClick = () => {
    setShowPaymentForm(true); // Show PaymentForm when button is clicked
  };

  const { items, loading, error } = useSelector((state) => state.shopCart);

  useEffect(() => {
    dispatch(getShopCartItemById());
  }, [dispatch]);

  useEffect(() => {
    // Check if there is any item with status "Priced"
    const pricedItemExists = items?.some((item) => item.status === "Priced");
    setHasPricedItem(pricedItemExists);
  }, [items]);

  // Start countdown timer
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          return 0; // Stop at 0 seconds
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, []);

  // Convert time to HH:MM:SS format
  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h.toString().padStart(2, "0")}:${m
      .toString()
      .padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
  };

  // Calculate total price and total shipping fees
  const totals = items?.reduce(
    (acc, item) => {
      const itemPrice = item.price || 0; // Default to 0 if price is undefined
      const shippingFees = item.DShippingFees || 0; // Default to 0 if DShippingFees is undefined

      acc.totalPrice += itemPrice;
      acc.totalFees += shippingFees;
      return acc;
    },
    { totalPrice: 0, totalFees: 0 }
  );

  const handleSelectItem = (itemId) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(itemId)
        ? prevSelectedIds.filter((id) => id !== itemId)
        : [...prevSelectedIds, itemId]
    );
  };

  const handleDelete = (itemId) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      dispatch(deleteShopCartItem(itemId))
        .unwrap()
        .then(() => alert("Item deleted successfully!"))
        .catch((error) => alert(error || "Failed to delete item."));
    }
  };

  const handleSubmit = () => {
    if (selectedIds.length > 0) {
      dispatch(submitItemsToPricing(selectedIds));
    } else {
      alert("Please select at least one item.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-20 md:mr-10">
        {items?.length > 0 ? (
          items.map((item) => (
            <div key={item._id}>
              <CustomBuyForMeCard
                image={item.images?.[0]?.url || "https://via.placeholder.com/150"}
                title={item.name || "Product Title"}
                size={item.productSize || "N/A"}
                count={item.productCount || "1"}
                price={item.price || "0.00"}
                status={item.status || "pending"}
                isEditable={true}
                checked={selectedIds.includes(item._id)}
                onChange={() => handleSelectItem(item._id)}
                del={() => handleDelete(item._id)}
              />
            </div>
          ))
        ) : (
          <div>No items in the cart.</div>
        )}
      </div>

      {/* Display Total Details */}
      {/* {items?.length > 0 && (
        <div className="mt-4 bg-gray-100 p-4 rounded-md">
          <h3 className="text-lg font-bold">Summary:</h3>
          <p className="text-md">Total Price: {totals.totalPrice} USD</p>
          <p className="text-md">Total Shipping Fees: {totals.totalFees} USD</p>
          <p className="text-md font-bold">
            Grand Total: {totals.totalPrice + totals.totalFees} USD
          </p>
        </div>
      )} */}

      {/* Custom Button */}
      <CustomBuyForMeButton
        children={t("send_for_pricing")}
        icon={ArrowLeftIcon}
        onClick={handleSubmit} // Trigger the submit when the button is clicked
      />
       <div className='md:flex'>
        <CustomOrderInstructionCard
        title={t( "order_instruction_1")}  />
        <CustomOrderInstructionCard
        title={t("order_instruction_2")} />
    </div>
  {/* Conditionally Render PaymentDetails */}
{hasPricedItem && (
  <div className="mt-4 w-full  p-6">
    <PaymentDetails 
      time={formatTime(timeRemaining)}
      status="Priced"
      priceInside="غير محدد"
      totalPrice={totals.totalPrice}
      totalPriceWithFees={totals.totalFees}
      finalPrice={totals.totalPrice + totals.totalFees}
    />
     {showPaymentForm ? (
        <PaymentForm />
      ) : (
        <CustomPrivacySection />
      )}

      {/* Button to Trigger Section Replacement */}
      <div className="mt-4">
        <CustomBuyForMeButtonOrders
          children={t( "proceed_to_payment")}
          icon={ArrowLeftIcon}
          bgColor="bg-custom-yellow"
          onClick={handleButtonClick}
        />
      </div>
       

  </div>
)}
    </div>
  );
};

export default ItemsBuyMeSection;
