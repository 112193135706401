import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CreditCardIcon } from "@heroicons/react/24/outline";
import CustomTitleAndSubTitle from "../myOrders/customTitleAndSubTitle";
import imageShirt from "../../assets/images/shirt.png";
import CustomBuyForMeButtonOrders from "../ordersForBuyForMe/customOrderBuyButton";
import { useTranslation } from "react-i18next";
import CustomEmptyCart from "../sharedComponents/customEmptyCart";
import { getUserBoxOrders} from "../../redux/reducer/shipandShopOrdersReducer";

const CustomOrdersBoxCards = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Select data from Redux store
  const { shipmentDetails, loading, error } = useSelector(
    (state) => state.myShipment
  );

  // Fetch shipment details when the component mounts
  useEffect(() => {
    dispatch(getUserBoxOrders());
  }, [dispatch]);

  // Handle different states
  if (loading) {
    return <p>{t("loading")}...</p>;
  }

  if (error) {
    return <p>{t("error_occurred")}: {error}</p>;
  }

  if (!shipmentDetails || shipmentDetails.length === 0) {
    return <CustomEmptyCart />;
  }

  return (
    <div className="bg-white w-full p-4">
      <h3 className="text-lg text-gray-800">{t("shipment_details")}</h3>
      <div className="space-y-6">
        {/* Render a list of shipment details */}
        {shipmentDetails.map((shipment, index) => (
          <div
            key={index}
            className="border-b border-gray-200 pb-4 mb-4 last:border-none last:pb-0 last:mb-0"
          >
            {/* Display shipment details */}
            <div className="hidden md:flex justify-between">
              <CustomTitleAndSubTitle
                title={t("from")}
                subTitle={shipment?.from || t("unknown")}
              />
              <CustomTitleAndSubTitle
                title={t("shipment_price")}
                subTitle={`${shipment?.price || "0.00"} TL`}
              />
              <CustomTitleAndSubTitle
                title={t("weight")}
                subTitle={`${shipment?.weight || "0"} ${t("kg")}`}
              />
              <CustomTitleAndSubTitle
                title={t("delivery_date")}
                subTitle={shipment?.deliveryDate || t("unknown")}
              />
            </div>

            {/* Mobile view */}
            <div className="flex w-full justify-between md:hidden">
              <CustomTitleAndSubTitle
                title={t("from")}
                subTitle={shipment?.from || t("unknown")}
              />
              <CustomTitleAndSubTitle
                title={t("shipment_price")}
                subTitle={`${shipment?.price || "0.00"} TL`}
              />
            </div>
            <div className="flex w-full justify-between md:hidden">
              <CustomTitleAndSubTitle
                title={t("weight")}
                subTitle={`${shipment?.weight || "0"} ${t("kg")}`}
              />
              <CustomTitleAndSubTitle
                title={t("delivery_date")}
                subTitle={shipment?.deliveryDate || t("unknown")}
              />
            </div>

            <div className="mt-5">
              <img src={imageShirt} alt="image" className="w-20 h-20" />
            </div>
            <div className="flex justify-end">
              <CustomBuyForMeButtonOrders
                children={t("more_details")}
                icon={CreditCardIcon}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomOrdersBoxCards;
