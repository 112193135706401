// redux/shipmentSlice.js
import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BASE_URL from '../constent'; // Assuming constants file for BASE_URL

// POST request to check shipment cost
export const checkShipmentCost = createAsyncThunk(
    "shipment/checkShipmentCost",
    async (shipmentData, thunkAPI) => {
      try {
        console.log("Sending request to:", `${BASE_URL}/shipmentCost/checkprice/cost`);
        console.log("Payload:", shipmentData);
  
        const token = localStorage.getItem("accessToken");
        if (!token) throw new Error("No access token found");
  
        const response = await axios.post(
          `${BASE_URL}/shipmentCost/checkprice/cost`,
          shipmentData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log("Response data:", response.data);
  
        return response.data;
      } catch (error) {
        console.error("Error:", error);
        return thunkAPI.rejectWithValue(error.response?.data || error.message);
      }
    }
  );
  
const initialState = {
  cost: null, // Will hold the calculated shipment cost
  loading: false, // Loading state for the request
  error: null, // Error message in case the request fails
};

const shipmentSlice = createSlice({
  name: 'shipmentCost', // Slice name
  initialState,
  reducers: {
    // Action to clear the error state
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkShipmentCost.pending, (state) => {
        state.loading = true; // Set loading to true when the request starts
        state.error = null; // Clear any previous errors
      })
      .addCase(checkShipmentCost.fulfilled, (state, action) => {
        state.loading = false; // Set loading to false when the request succeeds
        state.cost = action.payload; // Store the calculated shipment cost
      })
      .addCase(checkShipmentCost.rejected, (state, action) => {
        state.loading = false; // Set loading to false when the request fails
        state.error = action.payload; // Store the error message
      });
  },
});

export const { clearError } = shipmentSlice.actions; // Export the clearError action

export default shipmentSlice.reducer;
