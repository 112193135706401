import React, { useEffect } from "react";
import {
  UserIcon,
  ShoppingCartIcon,
  Bars4Icon,
  XMarkIcon,
  BellIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";

import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import CustomButton from "./customButton";
import Logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import NavBarMenu from "./navBarMenu";
import CustomDropDownNavBar from "./customDropDownNavBar";
import LanguageToggleButton from "./languageSwitcherFlags";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AvaterImage from '../../assets/images/av.jpg';
import CustomMenu from "./customMneu";
import NotificationButton from './notificationList';
import NavBarMenuContactUsOurService from './navContactUsOurService';
const Header = () => {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);



  const handleNavigation = () => {
    navigate('/ContactUsPage', { replace: true });
    window.location.reload(); // Force reload after navigation
  };

  
  const logoStyle = {
    height: isMobile ? "40px" : "60px",
    width: isMobile ? "100px" : "200px",
  };
  const iconSize = isMobile ? "w-5 h-5" : "w-6 h-6";

  return (
    <div
      className=" fixed top-0 left-0 bg-black z-50 shadow-md flex flex-row items-center justify-between w-full
         px-4
         lg:px-custom-padding py-2  text-white"
    >
      {/* Icons Section */}
      <div className="flex items-center space-x-4">
        {/* Mobile Menu Button */}
        {isMobile && (
          <button onClick={() => setMenuOpen(!menuOpen)}>
            {menuOpen ? (
              <XMarkIcon className="w-6 h-6 text-white" />
            ) : (
              <Bars4Icon className="w-6 h-6 text-white" />
            )}
          </button>
        )}

        <div className="hidden sm:block">
          <CustomButton
            onClick={handleNavigation}
            children={t("contact_us")}
            icon={PhoneIcon}
          />
        </div>

     <CustomMenu/>

        <NotificationButton/>
        <LanguageToggleButton />
      </div>

      {/* Navigation Menu */}
      <div
        className={`absolute top-16 left-0 z-20 w-full  p-4 space-y-4 ${
          menuOpen ? "block" : "hidden"
        } md:flex md:static md:w-auto md:space-y-0 md:space-x-6 md:p-0`}
      >
        <NavBarMenuContactUsOurService/>
        <CustomDropDownNavBar language={i18n.language} />

        <NavBarMenu />

      </div>

      {/* Logo Section */}
      <div>
        <img src={Logo} alt="Logo" style={logoStyle} />
      </div>
    </div>
  );
};

export default Header;

