import React, { useState } from 'react';
import CustomArrowDown from '../sharedComponents/customArrowDown';

const CustomHowItWorks = ({ image, title, visible }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave} 
            className="cursor-pointer flex flex-col items-center w-full justify-center text-center"
        >
            {/* Card Image */}
            <img
                src={image}
                alt="image"
                className="w-full h-[120px]"
            />
            {/* Title Section */}
            <div
    className={`flex justify-center items-center px-4 w-full h-[120px] cursor-pointer ${
        isHovered ? 'bg-black' : 'bg-custom-yellow'
    }`}
>
    <h2 className={`text-sm text-center ${isHovered ? 'text-white' : 'text-black'}`}>
        {title}
    </h2>
</div>

            {/* Arrow Section */}
            <div className="mt-5">
                {visible ? (
                    <CustomArrowDown />
                ) : (
                    // Placeholder for consistent spacing
                    <div className="h-[24px]" />
                )}
            </div>
        </div>
    );
};

export default CustomHowItWorks;
