import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postContactData } from "../../redux/reducer/contactUsReducer"; // Adjust the import path if necessary
import CustomTitleWihBox from "../sharedComponents/customTitleWithBox";
import { useTranslation } from "react-i18next";
import halfBoxImage from "../../assets/images/halfBox.png";
import halfBoxTwoImage from "../../assets/images/halfBoxCard.png";
import CustomDropdown from "../sharedComponents/customDropDown";

export default function ContactUsFormUser() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedTo, setSelectedTo] = useState(null);
  const { status, error } = useSelector((state) => state.contact);

  // State to store form data
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "",
    companyName: "markaship",
    prefix: "012",
    message: "",
  });

  // Dropdown menu data
  const menuListLocationTo = { name: t("calculateShippingSection.selectArea") };
  const menuItemsLocationTo = [
    { id: 1, name: t("countries.SA"), link: "#" },
    { id: 2, name: t("countries.AE"), link: "#" },
    { id: 3, name: t("countries.EG"), link: "#" },
    { id: 4, name: t("countries.QA"), link: "#" },
    { id: 5, name: t("countries.KW"), link: "#" },
    { id: 6, name: t("countries.OM"), link: "#" },
    { id: 7, name: t("countries.BH"), link: "#" },
    { id: 8, name: t("countries.JO"), link: "#" },
    { id: 9, name: t("countries.LB"), link: "#" },
    { id: 10, name: t("countries.SY"), link: "#" },
    { id: 11, name: t("countries.IQ"), link: "#" },
    { id: 12, name: t("countries.YE"), link: "#" },
    { id: 13, name: t("countries.MA"), link: "#" },
    { id: 14, name: t("countries.DZ"), link: "#" },
    { id: 15, name: t("countries.TN"), link: "#" },
    { id: 16, name: t("countries.LY"), link: "#" },
    { id: 17, name: t("countries.SD"), link: "#" },
    { id: 18, name: t("countries.PS"), link: "#" },
    { id: 19, name: t("countries.SO"), link: "#" },
    { id: 20, name: t("countries.DJ"), link: "#" },
    { id: 21, name: t("countries.MR"), link: "#" },
    { id: 22, name: t("countries.KM"), link: "#" }
  ];

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Allow only numbers, spaces, parentheses, plus sign, and hyphen
  const isNumber = (event) => {
    const key = event.keyCode || event.charCode;
    const keyPressed = String.fromCharCode(key);
    const allowedChars = /^[0-9\s\-\(\)\+]$/;

    if (!allowedChars.test(keyPressed)) {
      event.preventDefault();
    }
  };

  // Validate and handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.message.length < 10) {
      alert(t("Validation.messageTooShort")); // Message must be at least 10 characters
      return;
    }

    if (!formData.country) {
      alert(t("Validation.countryRequired")); // Country is required
      return;
    }

    // Dispatch the action if validation passes
    dispatch(postContactData(formData));
  };

  return (
    <div>
    <div className="flex justify-center items-center" dir="rtl">
      <img
        src={halfBoxTwoImage}
        alt="image"
        className="w-[50px] h-[150px] md:w-[150px] md:h-[300px]"
      />
      <form onSubmit={handleSubmit}>
        {/* Name and Last Name Fields */}
        <div className="flex mb-6">
          <div className="w-1/2 pr-2 ml-2">
            <input
              id="name"
              name="name"
              type="text"
              placeholder={t("FirstName")}
              maxLength="12"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full px-4 py-3 text-xs sm:text-lg border text-black border-gray-300 rounded-lg bg-white placeholder:text-base"
            />
          </div>
          <div className="w-1/2 pl-2">
            <input
              id="lastName"
              name="lastName"
              type="text"
              maxLength="12"
              placeholder={t("LastName")}
              value={formData.lastName}
              onChange={handleInputChange}
              className="w-full px-4 py-3 text-xs sm:text-lg text-black border border-gray-300 rounded-lg bg-white placeholder:text-base"
            />
          </div>
        </div>

        {/* Email and Phone Fields */}
        <div className="flex mb-4">
          <div className="w-1/2 pr-2 ml-2">
            <input
              id="email"
              name="email"
              type="email"
              placeholder={t("email")}
              value={formData.email}
              onChange={handleInputChange}
              className="w-full px-4 py-3 text-xs sm:text-lg text-black border border-gray-300 rounded-lg bg-white placeholder:text-base"
            />
          </div>
          <div className="w-1/2 pl-2">
            <input
              id="phoneNumber"
              name="phoneNumber"
              type="tel"
              placeholder={t("PhoneNumber")}
              value={formData.phoneNumber}
              onChange={handleInputChange}
              onKeyPress={isNumber}
              required
              className="w-full px-4 py-3 text-xs sm:text-lg text-black border border-gray-300 rounded-lg bg-white placeholder:text-base"
            />
          </div>
        </div>

        {/* Country Dropdown */}
        <div className="mb-4 p-2">
          <CustomDropdown
            menuItems={menuItemsLocationTo}
            menuList={menuListLocationTo}
            textColor="custom-gray"
            onSelect={(item) =>
              setFormData({ ...formData, country: item.name }) // Update selected country
            }
          />
        </div>

        {/* Message Field */}
        <div className="w-full pr-2 mb-10">
          <textarea
            id="message"
            name="message"
            placeholder={t("message")}
            value={formData.message}
            onChange={handleInputChange}
            className="w-full px-4 text-xs sm:text-lg text-black border border-gray-300 rounded-lg placeholder:text-base bg-white h-40 resize-none"
          />
        </div>

        {/* Submit Button */}
        <div className="flex justify-center mb-10">
          <button
            type="submit"
            className="block w-1/2 rounded-lg bg-custom-yellow px-8 py-3 text-base font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600"
          >
            {t("send")}
          </button>
        </div>
      </form>
      <img
        src={halfBoxImage}
        className="w-[50px] h-[150px] md:w-[150px] md:h-[300px]"
      />
    </div>
     {/* Pop-up Modal for Loading and Error Messages */}
{status === "loading" && (
  <div
    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    role="dialog"
    aria-modal="true"
  >
    <div className="bg-white rounded-lg p-6 shadow-lg text-center">
      <p className="text-lg font-medium text-gray-700">{t("Sending")}</p>
    </div>
  </div>
)}

{status === "failed" && (
  <div
    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    role="dialog"
    aria-modal="true"
  >
    <div className="bg-white rounded-lg p-6 shadow-lg text-center">
      <p className="text-lg font-medium text-red-500">
        {t("Error")}: {error}
      </p>
      <button
        onClick={() => window.location.reload()} // Or any other action to close/reset
        className="mt-4 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
      >
        {t("Close")}
      </button>
    </div>
  </div>
)}

    </div>
  );
}
