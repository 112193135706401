import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import BASE_URL from '../constent';

// Async thunk for making the POST API call with the token
export const postContactData = createAsyncThunk(
  'contact/postContactData',
  async (contactData, { rejectWithValue }) => {
    try {
      // Get the access token from localStorage
      const token = localStorage.getItem('accessToken');

      if (!token) {
        throw new Error('No access token found');
      }

      // Send the POST request with the token included in the Authorization header
      const response = await axios.post(
        `${BASE_URL}/contact`, 
        contactData, 
        {
          headers: {
            'Authorization': `Bearer ${token}`, // Include token in Authorization header
            'Content-Type': 'application/json'  // Ensure Content-Type is JSON
          }
        }
      );
      return response.data; // Assuming the endpoint returns JSON data
    } catch (error) {
      console.error('Error details:', error); // Log the error
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    data: null,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    resetContactState: (state) => {
      state.data = null;
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postContactData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postContactData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(postContactData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { resetContactState } = contactSlice.actions;
export default contactSlice.reducer;
