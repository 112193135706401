import React, { useState, useEffect } from 'react';
import CustomTitleAccount from '../accountUser/customTitleAccount';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { useSelector, useDispatch } from 'react-redux';
import CustomEmptyCart from '../sharedComponents/customEmptyCart';
import CustomTitleAndSubTitle from '../myOrders/customTitleAndSubTitle';
import CustomBannerShipment from './customShipmentBanner';
import { useTranslation } from 'react-i18next';
import CustomInfoInput from '../accountUser/customInfoInput';
import { getAddressesByUserId } from '../../redux/reducer/userAddressReducer';
import { getUserBoxOrders } from '../../redux/reducer/shipandShopOrdersReducer'; // Import the thunk
import  CustomOrdersBoxCards from './customBoxCards';
const ManageBoxDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Shipment details state (initially empty)
  const [shipmentDetails, setShipmentDetails] = useState(null);

  // State for addresses and personal information
  const [fullAddress, setFullAddress] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [phone, setPhone] = useState('');

  // Redux state for user addresses and box orders
  const { addresses, loading: addressLoading, error: addressError } = useSelector((state) => state.userAddress);
  const { boxOrders, loading: boxLoading, error: boxError } = useSelector((state) => state.shipAndShop);

  useEffect(() => {
    // Fetch user addresses
    dispatch(getAddressesByUserId());
    // Fetch user box orders
    dispatch(getUserBoxOrders());
  }, [dispatch]);

  useEffect(() => {
    if (addresses?.length) {
      const address = addresses[0];
      setFullAddress(address.fullAddress || '');
      setStreet(address.street || '');
      setCity(address.city || '');
      setCountry(address.country || '');
      setPostalCode(address.postalCode || '');
      setFirstName(address.name || '');
      setPhone(address.phoneNumber || '');
    }
  }, [addresses]);

  useEffect(() => {
    if (boxOrders?.length) {
      // Assume the first box order as shipmentDetails (customize as needed)
      const firstBoxOrder = boxOrders[0];
      setShipmentDetails({
        from: firstBoxOrder.zone || 'Unknown',
        price: firstBoxOrder.weight || 0,
        weight: firstBoxOrder.weight || 0,
        deliveryDate: firstBoxOrder.arrivalDate || '',
        shippingCost: 15.0, // Replace with actual shipping cost if available
        packagingCost: 5.0, // Replace with actual packaging cost if available
      });
    }
  }, [boxOrders]);

  const handleClick = () => {
    // Handle the click event
  };

  // Handle loading/error states
  if (addressLoading || boxLoading) return <p>{t('loading')}...</p>;
  if (addressError || boxError) return <p>{t('error_occurred')}: {addressError || boxError}</p>;
  if (!shipmentDetails) return <CustomEmptyCart />;

  // Total cost calculation
  const totalCost = shipmentDetails.price + shipmentDetails.shippingCost + shipmentDetails.packagingCost;

  return (
    <div>
      <div className="bg-white">
        <CustomBannerShipment />
        <div className="md:flex justify-between p-4">
          <CustomTitleAndSubTitle title="Source" subTitle={shipmentDetails.from || t('unknown')} />
          <CustomTitleAndSubTitle title="Price" subTitle={`${shipmentDetails.price || 0} TL`} />
          <CustomTitleAndSubTitle title="Weight" subTitle={`${shipmentDetails.weight || 0} ${t('kg')}`} />
        </div>
        <div className="md:flex justify-between p-4">
          <CustomTitleAndSubTitle title="Packaging Cost" subTitle={`${shipmentDetails.packagingCost || 0} TL`} />
          <CustomTitleAndSubTitle title="Shipping Cost" subTitle={`${shipmentDetails.shippingCost || 0} TL`} />
          <CustomTitleAndSubTitle title="Total Price" subTitle={`${totalCost || 0} TL`} />
        </div>
      </div>

      <div className="w-full px-4">
        <CustomTitleAccount
          title={t('CurrentAddress')}
          onClick={handleClick}
          icon={<MapPinIcon className="w-8 h-8 text-black" />}
        />
        <div className="flex justify-between">
          <CustomInfoInput
            id="firstName"
            label={t('Name')}
            type="text"
            placeholder={t('Name')}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            icon={<i className="fas fa-user"></i>}
          />
          <CustomInfoInput
            id="phone"
            label={t('PhoneNumber')}
            type="text"
            placeholder={t('PhoneNumber')}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            icon={<i className="fas fa-phone"></i>}
          />
        </div>
        <div className="ml-6">
          <CustomInfoInput
            id="fullAddress"
            label={t('FullAddress')}
            type="text"
            placeholder={t('FullAddress')}
            value={fullAddress}
            onChange={(e) => setFullAddress(e.target.value)}
            icon={<i className="fas fa-map-marked-alt"></i>}
          />
        </div>
        <div className="flex justify-between">
          <CustomInfoInput
            id="city"
            label={t('City')}
            type="text"
            placeholder={t('City')}
            value={city}
            onChange={(e) => setCity(e.target.value)}
            icon={<i className="fas fa-city"></i>}
          />
          <CustomInfoInput
            id="country"
            label={t('Country')}
            type="text"
            placeholder={t('Country')}
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            icon={<i className="fas fa-globe"></i>}
          />
        </div>
      </div>
      < CustomOrdersBoxCards/>

    </div>
  );
};

export default ManageBoxDetails;
