import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAddressesByUserId } from "../../redux/reducer/userAddressReducer"; // Adjust the import path based on your file structure
import { MapPinIcon } from "@heroicons/react/24/outline";
import CustomTitleAccount from "./customTitleAccount";
import CustomInfoInput from "./customInfoInput";
import { useTranslation } from "react-i18next";

const AccountAddressSection = () => {
  const [fullAddress, setFullAddress] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Access the address data and loading/error states from Redux
  const { addresses, loading, error } = useSelector((state) => state.userAddress);

  useEffect(() => {
    // Dispatch the thunk to fetch addresses
    dispatch(getAddressesByUserId());
  }, [dispatch]);

  useEffect(() => {
    if (addresses && addresses.length > 0) {
      // Assume the API returns the first address to display
      const address = addresses[0];
      setFullAddress(address.fullAddress || "");
      setStreet(address.street || "");
      setCity(address.city || "");
      setCountry(address.country || "");
      setPostalCode(address.postalCode || "");
    }
  }, [addresses]);

  const handleClick = () => {
    // Handle the click event if needed
  };

  return (
    <div className="w-full ">
      {/* Title Section */}
      <CustomTitleAccount
        title={t("CurrentAddress")}
        onClick={handleClick}
        icon={<MapPinIcon className="w-8 h-8 text-black" />}
      />

      {/* Full Address */}
      <div className="flex space-x-2">
      <CustomInfoInput
        id="fullAddress"
        label={t("FullAddress")}
        type="text"
        placeholder={t("FullAddress")}
        value={fullAddress}
        onChange={(e) => setFullAddress(e.target.value)}
        icon={<i className="fas fa-map-marked-alt"></i>}
      />
</div>
      {/* Street */}
      <div className="flex space-x-2">
      <CustomInfoInput
        id="street"
        label={t("Street")}
        type="text"
        placeholder={t("Street")}
        value={street}
        onChange={(e) => setStreet(e.target.value)}
        icon={<i className="fas fa-road"></i>}
      />
</div>
      <div className="flex space-x-2">
        {/* City */}
        <CustomInfoInput
          id="city"
          label={t("City")}
          type="text"
          placeholder={t("City")}
          value={city}
          onChange={(e) => setCity(e.target.value)}
          icon={<i className="fas fa-city"></i>}
        />

        {/* Country */}
        <CustomInfoInput
          id="country"
          label={t("Country")}
          type="text"
          placeholder={t("Country")}
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          icon={<i className="fas fa-globe"></i>}
        />
      </div>

      <div className="flex space-x-2">
        {/* Postal Code */}
        <CustomInfoInput
          id="postalCode"
          label={t("PostalCode")}
          type="text"
          placeholder={t("PostalCode")}
          value={postalCode}
          onChange={(e) => setPostalCode(e.target.value)}
          icon={<i className="fas fa-mail-bulk"></i>}
        />
      </div>

      {/* Loading and Error States */}
      {loading && <p>{t("Loading...")}</p>}
      {error && <p className="text-red-500">{t("Error")}: {error}</p>}
    </div>
  );
};

export default AccountAddressSection;
