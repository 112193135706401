import React from "react";
import { ChevronDoubleDownIcon, InboxIcon } from '@heroicons/react/24/outline';
import CustomLines from "./customLines";
import CustomBigText from "../sharedComponents/customBigText";
import image from '../../assets/images/allShipping.png';
import CustomFlags from '../../component/sharedComponents/customFlags';
import imageTurkey from '../../assets/images/turkey.png';
import imageOroba from '../../assets/images/oroba.png';
import imageWorld from '../../assets/images/world.png';
import CustomButton from "../sharedComponents/customButton";
import { useTranslation } from "react-i18next";
const AllShippingSection = () => {
    const {t} =useTranslation();
    return (
        <div className="bg-white md:flex  items-center justify-center px-4 lg:px-custom-padding pb-10 ">
    <div className="hidden md:block w-1/2">
  <img
    src={image}
    alt="image"
    className="h-[600px]  mb-8"
  />
</div>

            <div className="w-full md:w-1/2 flex flex-col items-center text-center">
                <div className="flex flex-col items-center justify-center">
                    <CustomLines />
                    <CustomBigText title={t("allShippingSection.serviceUpdates")} className="mt-4" />
                </div>
                <div dir="rtl" className="mt-5">
                    <p className="text-sm text-gray-600">
                    {t("allShippingSection.serviceDescription1")}
                    </p>
                </div>
                <div className="flex justify-center items-center gap-8 mt-10">
                    <CustomFlags 
                        image={imageTurkey}
                        title={t("allShippingSection.turkish")}
                    />
                    <h2 className="text-2xl font-semibold text-black mt-10">{t("allShippingSection.and")}</h2>
                    <CustomFlags 
                        image={imageOroba}
                        title={t("allShippingSection.european")}
                    />
                </div>
                <div dir="rtl" className="mt-5">
                    <p className="text-sm text-gray-600">
                    {t("allShippingSection.serviceDescription2")}
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center mt-5">
                    <ChevronDoubleDownIcon className="w-6 h-6 text-black mb-2" />
                    <img src={imageWorld} alt="world" className="w-20 h-20" />
                    <div className="bg-custom-yellow inline-block px-8 py-2 mt-2">
                        <h2 className="text-xs text-black">{t("allShippingSection.worldwide")}</h2>
                    </div>
                    <ChevronDoubleDownIcon className="w-6 h-6 text-black mb-2 mt-3" />
                    <CustomButton 
                        children={t("allShippingSection.learnMore")}
                        icon={InboxIcon} 
                    />
                </div>
            </div>
            <div className="md:hidden">
            <img
                src={image}
                alt="image"
                className="h-[600px] md:w-1/2"
            />
            </div>
        </div>
    );
};

export default AllShippingSection;
