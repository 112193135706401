import React, { useEffect } from "react";
import {
  UserIcon,
  ShoppingCartIcon,
  Bars4Icon,
  XMarkIcon,
  BellIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import ValidMennItem from "./validmenuItem";
import CustomUnloginMenu from "./customUnloginMenu";
import CustomDropDownNavBarCurrancy from './customDropDownCurrancy';
const CustomMenu = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

  const iconSize = isMobile ? "w-5 h-5" : "w-6 h-6";

  return (
    <div>
   <Menu>
  <MenuButton className="flex items-center ">
    <UserIcon className={`${iconSize} text-white`} />
  </MenuButton>
  <MenuItems className="absolute left-10 md:left-40 top-12 z-10 mt-2 w-48 bg-white shadow-lg">
    <ValidMennItem/>
    <CustomDropDownNavBarCurrancy/>
    <CustomUnloginMenu/>
  </MenuItems>
</Menu>

    </div>
  );
};

export default CustomMenu;
