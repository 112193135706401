import React from "react";
import Header from "../component/sharedComponents/header";
import CustomIntroSection from "../component/explainBuyPage/customIntroSection";
import FooterUser from "../component/sharedComponents/footer";
import ChooseCategoryTrukeySection from "../component/chooseCategory/ChooseCategoryTrukeySection";
import { useTranslation } from "react-i18next";
const ChooseCategoryPage =()=>{
  const {t} = useTranslation();
   return (
     <div>
                      <Header/>
<div className="mt-20">
<CustomIntroSection
title={t('customIntro.trukeyStores')}
/>
</div>
<ChooseCategoryTrukeySection/>
<FooterUser/>
     </div>
   )

}

export default ChooseCategoryPage;  