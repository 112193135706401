import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchWebsitesByIds } from "../../redux/reducer/europaWebsitesMainReducer"; // Update path if necessary
import CustomBrandsCard from "./customBrandsCard";
import CustomLines from "../home/customLines";
import CustomBigText from "../sharedComponents/customBigText";

// Image imports
import ImageShoes from "../../assets/images/shoes.png";
import ImageGlasses from "../../assets/images/glasses.png";
import ImageClothes from "../../assets/images/clothes.png";
import ImageOclock from "../../assets/images/oclock.png";
import ImageBagTravel from "../../assets/images/bag.png";
import ImageWomenBag from "../../assets/images/womenBag.png";
import ImagePerfum from "../../assets/images/perfum.png";
import ImageAccessories from "../../assets/images/access.png";
import ImageDefault from "../../assets/images/access.png";
import { useTranslation } from "react-i18next";
import CustomTitle from "../sharedComponents/customTitle";
import CustomLogosCard from './customLogosCard';
import outletImage from '../../assets/images/outlet.png';
const ShoppingBrandsWebsitesEroupa = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const categoryImageMapping = {
    "أحذية": ImageShoes,
    "glasses": ImageGlasses,
    "ملابس": ImageClothes,
    "ساعات": ImageOclock,
    "bag": ImageBagTravel,
    "women bag": ImageWomenBag,
    "العطور": ImagePerfum,
    "مستلزمات رياضية": ImageBagTravel,
    "اكسسوارات": ImageAccessories,
    "أوتليت": outletImage, // Arabic for Outlet
    "Outlet": outletImage, // English for Outlet
  };

  useEffect(() => {
    const ids = Array.isArray(location.state?.websites) ? location.state.websites : [];
    if (!ids.length) {
      setError("No IDs provided for this category.");
      setLoading(false);
      return;
    }

    dispatch(fetchWebsitesByIds(ids))
      .then((result) => {
        if (result.payload && result.payload.length) {
          const groupedCategories = result.payload.reduce((acc, website, index) => {
            const category = website.category?.ar || website.category?.en || "Uncategorized";

            if (!acc[category]) acc[category] = [];
            acc[category].push({ ...website, id: website.id || website._id || index });

            // Special case: Group "Outlet" separately if the website has outlet-specific properties
            if (category.toLowerCase() === "outlet" || category === "أوتليت") {
              if (!acc["Outlet"]) acc["Outlet"] = [];
              acc["Outlet"].push({ ...website, id: website.id || website._id || index });
            }

            return acc;
          }, {});

          setCategories(groupedCategories);
        } else {
          setError("No websites found for the provided IDs.");
        }
      })
      .catch((error) => setError("Error fetching websites: " + error.message))
      .finally(() => setLoading(false));
  }, [dispatch, location.state]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-xl font-semibold text-gray-600">Loading websites...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-xl font-semibold text-red-600">{error}</p>
      </div>
    );
  }

  if (!Object.keys(categories).length) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-xl font-semibold text-gray-600">No websites available.</p>
      </div>
    );
  }

  return (
    <div className="bg-white px-6 md:px-20 pb-5 flex flex-col items-center justify-center text-center">
      <CustomLines />
      <CustomBigText title={t("shopping_choices_title")} />
      <p className="my-8 text-gray-700">{t("shopping_experience")}</p>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-8">
        {Object.keys(categories).map((category) => {
          const image = categoryImageMapping[category] || ImageAccessories;
          const categoryObject = categories[category][0]?.category || {};

          const title =
            i18n.language === "ar"
              ? categoryObject.ar || "فئة غير معروفة"
              : categoryObject.en || "Unknown Category";

          return (
            <CustomBrandsCard
              key={category}
              title={title}
              image={image}
              onCardClick={() => setSelectedCategory(selectedCategory === category ? null : category)}
            />
          );
        })}
      </div>

      {selectedCategory && (
    <div className="bg-white px-6 md:px-20 pb-5 flex flex-col items-center justify-center text-center mt-20">
      <div className="flex items-center w-full">
  {/* Left Divider Line */}
  <div className="bg-black h-[2px] flex-1"></div>

  {/* Center Title */}
  <CustomBigText title={t("BrandTitle")} className="mx-4 text-black" />

  {/* Right Divider Line */}
  <div className="bg-black h-[2px] flex-1"></div>
</div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 md:gap-6 mt-5">
            {categories[selectedCategory].map((website, index) => {
              const title =
                i18n.language === "ar"
                  ? website.name?.ar || "موقع غير مسمى"
                  : website.name?.en || "Unnamed Website";
              return (
                <CustomLogosCard
                  key={website.id || index}
                  title={title}
                  image={website.logo?.url || ImageAccessories}
                  onCardClick={() => {
                    if (website && website.link) {
                      navigate("/IFramePage", { state: { iframeSrc: website.link } });
                      setTimeout(() => window.location.reload(), 0);
                    } else {
                      alert("Cannot navigate. The website link is missing or invalid.");
                    }
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};


export default ShoppingBrandsWebsitesEroupa;
