import  React from 'react';
import Header from '../component/sharedComponents/header';
import FooterUser from '../component/sharedComponents/footer';
import ShippingInsuranceContainer  from '../component/ShippingInsurance/containerShoppingInsurance';
const ShippingInsurancePage =()=>{
    return (
        <div>
     <Header/>
   <div className='mt-20'>
   <ShippingInsuranceContainer />
   </div>
     <FooterUser/>
        </div>
    )
}

export default ShippingInsurancePage;