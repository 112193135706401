import React from "react";

const CustomCardImportantServices =({title ,image})=>{
    return (
<div className="bg-black items-center pb-5">
    <img
    src={image}
    alt="image"
    className="w-full p-5 h-[250px]"
    />
    <p>
        {title}
    </p>
</div>
    )
}
export default CustomCardImportantServices;