import React from 'react';

const CustomTextingCard = ({title ,subTitle,Icon})=>{
    return (
        <div class="bg-white p-6 rounded-lg shadow-md text-center  mx-auto">
  <h2 class="text-lg font-semibold text-gray-800 mb-4 flex items-center justify-center">

{title}
{Icon && <Icon className="w-5 h-5 text-gray-700 ml-2" />}

  </h2>
  <p class="text-sm text-gray-600 leading-relaxed">
{subTitle}
  </p>
</div>

    )
}

export default CustomTextingCard;