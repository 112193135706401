import React ,{useState} from 'react';
import CustomTitle from '../sharedComponents/customTitle';
import CustomHowItWorks from '../explainBuyPage/howItWorksCard';
import CustomArrowLeft from '../sharedComponents/customArrowLeft';
import ImageOne from '../../assets/images/buyOne.png';
import ImageTwo from '../../assets/images/buyTwo.png';
import ImageThree from '../../assets/images/buyThree.png';
import ImageFour from '../../assets/images/buyFour.png';
import ImageFive from '../../assets/images/buyFive.png';
import ImageSix from '../../assets/images/buysix.png';
import ImageSeven from '../../assets/images/buySeven.png';
import ImageEight from '../../assets/images/buyEight.png';
import ImageNine from '../../assets/images/buyNine.png';
import ImageTen from '../../assets/images/buyTen.png';
import ImageEleven from '../../assets/images/buyEleven.png';
import ImageTwelve from '../../assets/images/buyTwelve.png';
import CustomArrowDown from '../sharedComponents/customArrowDown';
import CustomArrowRight from '../sharedComponents/customArrowRight';
import { useTranslation } from 'react-i18next';

const HowItWorksSections =()=>{
    const [isVisible, setIsVisible] = useState(true);
    const {t} =useTranslation();
    const toggleVisibility = () => {
        setIsVisible((prev) => !prev);
    };
    return (
        <div className='bg-white px-4 md:px-custom-padding'>
<CustomTitle title={t('howItWorks.title')} />
<div className="md:flex w-full justify-center items-center md:space-x-20 mt-10">
  <div className="md:w-1/3 flex justify-center">
    <CustomHowItWorks
      image={ImageOne}
      title={t('howItWorks.step1')}
      visible={isVisible}
    />
  </div>
  <div className=" flex justify-center items-center hidden sm:block">
    <CustomArrowLeft />
  </div>
  <div className="md:w-1/3 flex justify-center">
    <CustomHowItWorks
      image={ImageTwo}
      title={t('howItWorks.step2')}
       />
  </div>
  <div className="flex justify-center items-center hidden sm:block">
    <CustomArrowLeft />
  </div>
  <div className="md:w-1/3 flex justify-center">
    <CustomHowItWorks
      image={ImageThree}
      title={t('howItWorks.step3')}
    />
  </div>
</div>

<div className="md:flex w-full justify-center items-center md:space-x-20 md:mt-5">
  <div className="md:w-1/3 flex justify-center">
    <CustomHowItWorks
      image={ImageFour}
      title={t('howItWorks.step4')}
    />
  </div>
  <div className="flex justify-center items-center hidden sm:block">
    <CustomArrowRight />
  </div>
  <div className="md:w-1/3 flex justify-center">
    <CustomHowItWorks
      image={ImageFive}
      title={t('howItWorks.step5')}
    />
  </div>
  <div className="flex justify-center items-center hidden sm:block">
    <CustomArrowRight />
  </div>
  <div className="md:w-1/3 flex justify-center">
    <CustomHowItWorks
      image={ImageSix}
      visible={isVisible}
      title={t('howItWorks.step6')}
    />
  </div>
</div>

<div className="md:flex w-full justify-center items-center md:space-x-20 md:mt-5">
  <div className="md:w-1/3 flex justify-center">
    <CustomHowItWorks
      image={ImageSeven}
      visible={isVisible}
      title={t('howItWorks.step7')}
    />
  </div>
  <div className="flex justify-center items-center hidden sm:block">
    <CustomArrowLeft />
  </div>
  <div className="md:w-1/3 flex justify-center">
    <CustomHowItWorks
      image={ImageEight}
      title={t('howItWorks.step8')} />
  </div>
  <div className="flex justify-center items-center hidden sm:block">
    <CustomArrowLeft />
  </div>
  <div className="md:w-1/3 flex justify-center">
    <CustomHowItWorks
      image={ImageNine}
      title={t('howItWorks.step9')}/>
  </div>
</div>

<div className="md:flex w-full justify-center items-center md:space-x-20 md:mt-10">
  <div className="md:w-1/3 flex justify-center">
    <CustomHowItWorks
      image={ImageTen}
      title={t('howItWorks.step10')} />
  </div>
  <div className="flex justify-center items-center hidden sm:block">
    <CustomArrowRight />
  </div>
  <div className="md:w-1/3 flex justify-center">
    <CustomHowItWorks
      image={ImageEight}
      title={t('howItWorks.step11')}
    />
  </div>
  <div className="flex justify-center items-center hidden sm:block">
    <CustomArrowRight />
  </div>
  <div className=" md:w-1/3 flex justify-center">
    <CustomHowItWorks
      image={ImageTwelve}
      title={t('howItWorks.step12')}
    />
  </div>
</div>
        </div>
    )
}
export default HowItWorksSections;