import React, { useState } from "react";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import CustomTitleAccount from "./customTitleAccount";
import CustomPasswordInput from "./customPasswordInput"; // Make sure the path is correct
import { useTranslation } from "react-i18next";
const AccountPasswordSection = () => {
  const [password, setPassword] = useState("");
  const { t } = useTranslation();

  return (
    <div className="w-full ">
      <CustomTitleAccount 
      title={t("ChangePassword")}
      icon={<LockClosedIcon
      className="w-8 h-8 text-black"
      />}
      />
      <div className="w-1/2">
        <CustomPasswordInput
          id="password1"
          label={t("Password")}
          placeholder={t( "Enteryourpassword" )}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="w-full flex">
        <CustomPasswordInput
          id="password2"
          label={t("Password")}
          placeholder={t( "Enteryourpassword" )}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <CustomPasswordInput
          id="password3"
          label={t("Password")}
          placeholder={t( "Enteryourpassword" )}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
    </div>
  );
};

export default AccountPasswordSection;
