import React, { useEffect, useState } from "react";
import { CreditCardIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { getUserBoxOrders } from "../../redux/reducer/shipandShopOrdersReducer"; // Adjust the path
import { useTranslation } from "react-i18next";
import CustomTitleAndSubTitle from "../myOrders/customTitleAndSubTitle";
import imageShirt from "../../assets/images/shirt.png";
import CustomBuyForMeButtonOrders from "../ordersForBuyForMe/customOrderBuyButton";
import CustomEmptyCart from "../sharedComponents/customEmptyCart";
import CustomBannerTransparent from "./transpaerntBanner";

// Helper function to format date (Optional)
const formatDate = (dateString) => {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options); // Format to 'YYYY-MM-DD'
};

const CustomOrdersShipments = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // State to store shipment details
  const [shipmentDetails, setShipmentDetails] = useState({
    from: "Istanbul, Turkey",
    price: 0,
    weight: 0,
    count: 0,
    deliveryDate: "", // Initially empty, will be updated with dynamic data
  });

  // Fetch user box orders from the Redux store
  const { userBoxOrders, loading, error } = useSelector((state) => state.shipAndShop);

  useEffect(() => {
    dispatch(getUserBoxOrders());
  }, [dispatch]);

  useEffect(() => {
    if (userBoxOrders) {
      // Calculate sum of weights and counts from the orders
      const totalWeight = userBoxOrders.reduce((sum, order) => sum + (order.weight || 0), 0);
      const totalCount = userBoxOrders.reduce((sum, order) => sum + (parseInt(order.count) || 0), 0);

      // Extract the first arrivalDate from the orders (you can adjust this if needed)
      const firstArrivalDate = userBoxOrders[0]?.arrivalDate || ""; // Get the first arrival date if available

      // Set the shipment details with calculated totals
      setShipmentDetails({
        from: "Istanbul, Turkey", // You can replace this with a dynamic value if needed
        price: totalCount * 30, // Assuming a price of 30 per item (replace as needed)
        weight: totalWeight,
        count: totalCount,
        deliveryDate: firstArrivalDate ? formatDate(firstArrivalDate) : "N/A", // Format the arrivalDate to deliveryDate
      });
    }
  }, [userBoxOrders]);

  // Handle loading and error states
  if (loading) {
    return <p>{t("loading")}...</p>;
  }

  if (error) {
    return <p>{t("error_occurred")}: {error}</p>;
  }

  // If no shipment details or empty orders
  if (!userBoxOrders || userBoxOrders.length === 0) {
    return <CustomEmptyCart />; // Return empty cart component if no orders are present
  }

  return (
    <div className="bg-white w-full p-4">
      <h3 className="text-lg text-gray-800">{t("shipment_details")}</h3>
      <div className="w-full">
        <CustomBannerTransparent />
        {/* Display shipment details */}
        <div className="hidden md:flex justify-between">
          <CustomTitleAndSubTitle
            title={t("from")}
            subTitle={shipmentDetails.from || t("unknown")}
          />
          <CustomTitleAndSubTitle
            title={t("shipment_price")}
            subTitle={`${shipmentDetails.price || "0.00"} TL`}
          />
          <CustomTitleAndSubTitle
            title={t("weight")}
            subTitle={`${shipmentDetails.weight || "0"} ${t("kg")}`}
          />
          <CustomTitleAndSubTitle
            title={t("delivery_date")}
            subTitle={shipmentDetails.deliveryDate || t("unknown")}
          />
        </div>

        {/* Mobile view */}
        <div className="flex w-full justify-between md:hidden md:block">
          <CustomTitleAndSubTitle
            title={t("from")}
            subTitle={shipmentDetails.from || t("unknown")}
          />
          <CustomTitleAndSubTitle
            title={t("shipment_price")}
            subTitle={`${shipmentDetails.price || "0.00"} TL`}
          />
        </div>
        <div className="flex w-full justify-between md:hidden md-block">
          <CustomTitleAndSubTitle
            title={t("weight")}
            subTitle={`${shipmentDetails.weight || "0"} ${t("kg")}`}
          />
          <CustomTitleAndSubTitle
            title={t("delivery_date")}
            subTitle={shipmentDetails.deliveryDate || t("unknown")}
          />
        </div>
      </div>

      <div className="mt-5">
        <img src={imageShirt} alt="image" className="w-20 h-20" />
      </div>

      <div className="flex justify-end">
        <CustomBuyForMeButtonOrders
          children={t("more_details")}
          icon={CreditCardIcon}
        />
      </div>
    </div>
  );
};

export default CustomOrdersShipments;
