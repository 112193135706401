import React, { useState, useEffect, useRef } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const menuItems = {
  en: [
    { id: 1, label: 'Turkish Sites', href: '/ChooseCategoryTrukeyPage' },
    { id: 2, label: 'European Sites', href: '/ChooseCategoryEroupaPage' },
  ],
  ar: [
    { id: 1, label: 'المواقع التركيه', href: '/ChooseCategoryTrukeyPage' },
    { id: 2, label: 'المواقع الاوروبيه', href: '/ChooseCategoryEroupaPage' },
  ],
};

const CustomDropDownNavBar = ({ language = 'en' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Ensure we are fetching the right language items
  const items = menuItems[language] || menuItems.en;

  return (
    <div style={{ position: 'relative' }}>
      <button
        ref={buttonRef}
        className=" mt-2"
        onClick={toggleDropdown}
        style={{
          display: 'flex',
          background: 'transparent',
          border: 'none',
          color: 'white',
        }}
      >
        <ChevronDownIcon className="w-4 h-4 mt-2 mr-1  text-white" />
        <span className="text-white">
          {language === 'ar' ? 'مواقع للتسوق' : 'Shopping Sites'}
        </span>
      </button>
      {isOpen && (
        <ul
          ref={dropdownRef}
          className="z-50 rounded-custom"
          style={{
            listStyle: 'none',
            margin: 0,
            top: 40,
            right: 0,
            position: 'absolute',
            zIndex: 10,
            background: 'black',
            borderRadius: '5px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            color: 'white',
          }}
        >
          {items.map((item) => (
            <li
              key={item.id}
              style={{
                padding: '10px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '5px',
                color: 'white',
              }}
              onClick={() => {
                setIsOpen(false);
                window.location.href = item.href;
              }}
            >
              <span>{item.label}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropDownNavBar;
