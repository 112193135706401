import React from "react";

const CustomInput = ({ placeholder, textColor, onChange, value ,title}) => {
  return (
    <div className="relative w-full ">
<h4
  className="text-lg text-gray-800 text-right mb-2"
  dir="rtl"
>         {title}
        </h4>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`w-full p-2 border border-gray-300 rounded-md 
          bg-transparent text-${textColor} text-right rtl focus:outline-none 
          focus:ring-2 focus:ring-gray-400`}
        dir="rtl" // Enable RTL direction for Arabic style
      />
    </div>
  );
};

export default CustomInput;
