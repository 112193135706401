import React from "react";
import { useTranslation } from "react-i18next";
import NodeTitle from "./noteTitle";
const PaymentDetails = ({time , status,totalPrice, totalPriceWithFees ,finalPrice ,priceInside}) => {
  const {t} = useTranslation();
  return (
    <div className=" flex justify-center items-center ">
      <div className="w-full  p-6  space-y-8" dir="rtl">
        {/* Header Section */}
        <div className="grid grid-cols-2 gap-8">
          <div className="flex flex-col">
            <span className="text-gray-500 font-medium mb-1 text-right"> {t("time_left_to_pay")}</span>
            <div className="border p-4 rounded-md text-right bg-white">
              <span className="text-black font-bold text-lg">{time}</span>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-500 font-medium mb-1 text-right">{t("status")}</span>
            <div className="border p-4 rounded-md text-right bg-white">
              <span className="text-black font-bold text-lg">{status}</span>
            </div>
          </div>
        </div>

        {/* Pricing Section */}
        <div className="grid grid-cols-3 gap-8">
          <div className="flex flex-col">
            <span className="text-gray-500 font-medium mb-1 text-right">{t("total_purchase_price")}</span>
            <div className="border p-4 rounded-md text-right bg-white">
              <span className="text-black font-bold text-lg">{totalPrice}</span>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-500 font-medium mb-1 text-right">{t("fees_and_commissions")}</span>
            <div className="border p-4 rounded-md text-right bg-white">
              <span className="text-black font-bold text-lg">{totalPriceWithFees}</span>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-500 font-medium mb-1 text-right">{t("internal_shipping_fees")}</span>
            <div className="border p-4 rounded-md text-right bg-white">
              <span className="text-black font-bold text-lg">{priceInside}</span>
            </div>
          </div>
        </div>

        {/* Total Section */}
        <div className="flex flex-col">
          <span className="text-gray-500 font-medium mb-1 text-right">{t("final_total")}</span>
          <div className="border p-4 rounded-md text-right bg-white">
            <span className="text-black font-bold text-2xl">{finalPrice}</span>
          </div>
        </div>
        <NodeTitle />
      </div>
    </div>
  );
};

export default PaymentDetails;
