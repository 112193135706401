import React from 'react';
import { NewspaperIcon } from '@heroicons/react/24/outline';
import CustomLines from './customLines';
import CustomBigText from '../sharedComponents/customBigText';
import ImageBagTravel from '../../assets/images/bag.png';
import ImageGlasses from '../../assets/images/glasses.png';
import ImageClothes from '../../assets/images/clothes.png';
import ImageOclock from '../../assets/images/oclock.png';
import ImageWowenBage from '../../assets/images/womenBag.png';
import ImageShoses from '../../assets/images/shoes.png';
import CustomCategoryCard from '../sharedComponents/customCategoryCards';
import CustomBlackButton from '../sharedComponents/customBlackButton';
import { useTranslation } from 'react-i18next';

const ChooseItemsSection = () => {
  const {t} = useTranslation();
  return (
    <div className="bg-white flex flex-col items-center justify-center text-center px-4 pb-10 lg:px-custom-padding">
      <CustomLines />
      <div>
        <CustomBigText title={t("shoppingSection.title")} />
        <p className="mt-4 text-gray-700">
        {t("shoppingSection.description")}
        </p>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-6 lg:gap-4 gap-2 mt-20 mb-20 w-full">
        <CustomCategoryCard image={ImageBagTravel} title={t("categories.travelTools")} />
        <CustomCategoryCard image={ImageGlasses} title= {t("categories.glasses")} />
        <CustomCategoryCard image={ImageOclock} title={t("categories.luxuryWatches")}  />
        <CustomCategoryCard image={ImageClothes} title={t("categories.clothes")}  />
        <CustomCategoryCard image={ImageWowenBage} title={t("categories.bags")}/>
        <CustomCategoryCard image={ImageShoses} title={t("categories.shoes")}  />
      </div>
      <CustomBlackButton
        onClick={() => console.log('black')}
        children={t("common.learnMore")}
        icon={NewspaperIcon}
      />
    </div>
  );
};

export default ChooseItemsSection;
