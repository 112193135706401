import React from "react";

const MessageAlert = ({ type, message }) => {
  if (!message) return null;

  const alertStyles = {
    success: "bg-green-100 text-green-800 border-green-300",
    error: "bg-red-100 text-red-800 border-red-300",
  };

  return (
    <div
      className={`p-4 mb-4 rounded border ${alertStyles[type]} text-center`}
      role="alert"
    >
      {message}
    </div>
  );
};

export default MessageAlert;
