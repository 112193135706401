import React, { useState } from "react";
import { CheckIcon } from "@heroicons/react/24/outline";

const CustomBanner = ({ orderNumber }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div
      className={`flex w-full items-center justify-between p-1 rounded-md shadow-md ${
        isChecked ? "bg-custom-yellow text-black" : "bg-gray-300 text-black"
      }`}
    >
        {/* Order Number */}
        <div className="font-bold">
        <span className="text-sm text-gray-800">رقم الطلب </span>
        <span className="text-xs text-gray-800" >{orderNumber}</span>
      </div>
      {/* Message */}
      <div className="flex items-center space-x-2 cursor-pointer">
      <div
        className={`relative w-5 h-5 rounded-md flex items-center justify-center border-2 ${
          isChecked ? "bg-green-600 border-green-600" : "bg-white border-gray-300"
        }`}
        onClick={() => setIsChecked(!isChecked)}
      >
        {isChecked && <CheckIcon className="w-4 h-4 text-white" />}
        <input
          type="checkbox"
          className="absolute inset-0 opacity-0 cursor-pointer "
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
      </div>
      <span
        className={`text-sm font-medium  ${
          isChecked ? "text-green-600" : "text-black"
        }`}
        onClick={() => setIsChecked(!isChecked)}
      >
        {isChecked ? "تم إعادة التغليف" : " إعادة التغليف"}
      </span>
    </div>

    
    </div>
  );
};

export default CustomBanner;
