import React, { useEffect, useState, useRef } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import imageOne from '../../assets/images/image 135.png';
import imageTwo from '../../assets/images/image 136.png';
import imageThree from '../../assets/images/image 137.png';

const Reviews = [
  { id: 1, image: imageOne },
  { id: 2, image: imageTwo },
  { id: 3, image: imageThree },
  { id: 4, image: imageOne },
  { id: 5, image: imageTwo },
  { id: 6, image: imageThree },
];

export default function CustomListBrands() {
  const scrollRef = useRef(null);
  const [scrollIndex, setScrollIndex] = useState(0);
  const itemsPerScroll = 3; // Number of items per scroll action
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/eventInside');
  };

  const scrollOneItem = (direction) => {
    if (scrollRef.current) {
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      const scrollAmount = direction === 'left' ? -itemWidth : itemWidth;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const updateScrollIndex = () => {
    if (scrollRef.current) {
      const scrollLeft = scrollRef.current.scrollLeft;
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      const newIndex = Math.floor(scrollLeft / itemWidth);
      setScrollIndex(newIndex);
    }
  };

  useEffect(() => {
    const handleScroll = () => updateScrollIndex();
    const container = scrollRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="justify-center items-center pb-2">
      <div className="flex flex-col items-center justify-center text-center px-custom-padding">
        <div className="relative flex items-center w-full max-w-[200px] md:max-w-[300px] mx-auto">
          {/* Left Arrow */}
          <button
            onClick={() => scrollOneItem('left')}
            className="absolute left-[-50px] z-10 p-2   rounded-full
              focus:outline-none"
          >
            <ChevronDoubleLeftIcon className="w-6 h-6 text-black" />
          </button>

          <div
            ref={scrollRef}
            className="flex overflow-x-auto space-x-6 scrollbar-hidden"
            style={{
              scrollSnapType: 'x mandatory',
              maxWidth: '900px', // Width for 3 items (adjust per item size)
            }}
          >
            {Reviews.map((product) => (
              <div
                key={product.id}
                className="flex-none w-[70px] rounded-lg p-4"
                style={{ scrollSnapAlign: 'start' }}
              >
                <img src={product.image} alt="brand" className="w-full h-auto" />
              </div>
            ))}
          </div>

          {/* Right Arrow */}
          <button
            onClick={() => scrollOneItem('right')}
            className="absolute right-[-50px] z-10 p-2 
             rounded-full  focus:outline-none"
          >
            <ChevronDoubleRightIcon className="w-6 h-6 text-black" />
          </button>
        </div>
      </div>
    </div>
  );
}
