import React from 'react';
import img from '../../assets/images/arrow.png';

const CustomLink = ({ to, children }) => {
  const handleClick = (e) => {
    e.preventDefault(); // Prevent React Router's SPA behavior
    window.location.href = to; // Force full page reload
  };

  return (
    <a
      href={to}
      onClick={handleClick}
      className="flex xxxs:text-xs xxs:text-xs xs:text-xs sm:text-sm mid:text-sm md:text-sm lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 22xl:text-lg 3xl:text-lg items-center text-big-text-color hover:text-big-text-color mb-2"
    >
      <img
        src={img}
        alt="arrow"
        className="ml-2"
      />
      {children}
    </a>
  );
};

export default CustomLink;
