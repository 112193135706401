import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { registerUser } from "../../redux/reducer/authReducer"; // Adjust the path as needed
import CustomTitleWihBox from "../sharedComponents/customTitleWithBox";
import CustomInput from "./CustomInput";
import CustomPasswordInput from "../accountUser/customPasswordInput";
import { EnvelopeIcon, LockOpenIcon, UserIcon, PhoneIcon, GlobeAltIcon, HashtagIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function RegistrationForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [boxNumber, setBoxNumber] = useState(() => Math.floor(100000 + Math.random() * 900000)); // Generate random 6-digit number
  const [country, setCountry] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [selectedCountryIso, setSelectedCountryIso] = useState("");
   const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    const userData = { 
      email, 
      password, 
      name, 
      boxNumber, 
      phone, 
      country, 
      phoneCode, 
      selectedCountryIso 
    };

    dispatch(registerUser(userData))
      .unwrap()
      .then(() => {
        alert("Registration successful!");
        handleClick(); // Trigger page reload and redirect after registration
      })
      .catch((error) => {
        console.error(error);
        alert(error?.message || "Registration failed.");
      });
  };

  // handleClick to force the reload and navigate
  const handleClick = () => {
    window.location.href = "/LoginPage"; // Redirect to the login page
  };

  return (
    <div className="block shadow-lg rounded-lg bg-white p-8 mx-auto md:w-1/2 text-right" dir="rtl">
      <CustomTitleWihBox title={t( "RegisterNewAccount")} />
      <form onSubmit={handleSubmit}>
        {/* Name */}
        <div className="mb-6">
          <CustomInput
            id="name"
            placeholder={t("FullName")}
            value={name}
            onChange={(e) => setName(e.target.value)}
            icon={<UserIcon />}
            required
          />
        </div>

        {/* Email */}
        <div className="mb-6">
          <CustomInput
            id="email"
            type="email"
            placeholder={t("email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            icon={<EnvelopeIcon />}
            required
          />
        </div>

        {/* Phone */}
        <div className="mb-6">
          <CustomInput
            id="phone"
            type="tel"
            placeholder={t("PhoneNumber")}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            icon={<PhoneIcon />}
          />
        </div>

        {/* Password & Confirm Password */}
        <div className="flex">
          <div className="mb-6 w-1/2">
       
            <CustomPasswordInput
            id="password"
            type="password"
            label={t("Password")}
            placeholder={t("Enteryourpassword")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            icon={<LockOpenIcon />} // Optional: Add an icon prop if supported
            required
          />
          </div>
          <div className="mb-6 w-1/2 mr-2">
          <CustomPasswordInput
          id="confirm-password"
          type="password"
          label={t("Password")}
          placeholder={t("Enteryourpassword")}
          value={confirmPassword}
          onChange={(e) => setPassword(e.target.value)}
          icon={<LockOpenIcon />} // Optional: Add an icon prop if supported
          required
        />
        
          

          </div>
        </div>

        {/* Box Number */}
        <div className="mb-6">
          <CustomInput
            id="box-number"
            type="text"
            placeholder={t("BoxNumber")}
            value={boxNumber}
            onChange={(e) => setBoxNumber(e.target.value)}
            icon={<HashtagIcon />}
            required
          />
        </div>

        {/* Country Information */}
        <div className="mb-6">
          <CustomInput
            id="country"
            type="text"
            placeholder={t("country")}
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            icon={<GlobeAltIcon />}
          />
        </div>

        {/* Phone Code & Selected Country ISO */}
        <div className="flex">
          <div className="mb-6 w-1/2">
            {/* Arabic Label */}
   
        <label
          className="block mb-2 text-sm font-medium text-right text-gray-700"
          dir="rtl" // Set the label direction
        >
          {t("PhoneCode")}
        </label>
   
            <CustomInput
              id="phone-code"
              type="text"
              placeholder={t("PhoneCodeOptional")}
              value={phoneCode}
              onChange={(e) => setPhoneCode(e.target.value)}
              icon={<PhoneIcon />}
            />
          </div>
          <div className="mb-6 w-1/2 mr-2">
            <CustomInput
              id="country-iso"
              type="text"
              placeholder={t("ISOCountryCodeOptional")}
              value={selectedCountryIso}
              onChange={(e) => setSelectedCountryIso(e.target.value)}
              icon={<GlobeAltIcon />}
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-center mb-6">
          <button
            type="submit"
            className="block w-full rounded-lg bg-custom-yellow px-6 py-3 text-base font-medium uppercase text-black shadow-lg transition duration-150 ease-in-out hover:bg-primary-600 focus:outline-none"
          >
            {t("Register")}
          </button>
        </div>

        {/* Navigation to Login */}
        <p className="text-center text-sm text-gray-600">
        {t("AlreadyHaveAccount")}{" "}
        <div
  onClick={() => (window.location.href = "/LoginPage")}
  className="text-blue-600 hover:underline cursor-pointer"
>
  {t("LoginHere")}
</div>
        </p>
      </form>
    </div>
  );
}
