import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAddress, clearError, clearSuccessMessage } from "../../redux/reducer/userAddressReducer";
import { useTranslation } from "react-i18next";

const CustomBigButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userId = useSelector((state) => state.auth.user?.id || localStorage.getItem("userId"));
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    country: "",
    phoneNumber: "",
    city: "",
    postalCode: "",
    street: "",
    buildingNumber: "",
    flatNumber: "",
    fullAddress: "",
  });

  const dispatch = useDispatch();
  const { loading, error, successMessage } = useSelector((state) => state.userAddress);

  // Sync userId with formData whenever it changes
  useEffect(() => {
    if (userId) {
      setFormData((prevData) => ({ ...prevData, userId }));
    }
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Include userId in the payload
    if (userId) {
      dispatch(createAddress({ ...formData, userId }))
        .unwrap()
        .then(() => {
          alert("تم إضافة العنوان بنجاح!");
          closeModal();
        })
        .catch((err) => {
          console.error("Error adding address:", err);
        });
    } else {
      alert("User ID is required to add an address.");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFormData({
      name: "",
      country: "",
      phoneNumber: "",
      city: "",
      postalCode: "",
      street: "",
      buildingNumber: "",
      flatNumber: "",
      fullAddress: "",
    });
    dispatch(clearError());
    dispatch(clearSuccessMessage());
  };

  return (
    <div className="pb-5">
      <button
        type="button"
        onClick={() => setIsModalOpen(true)}
        className="w-full py-2 px-4 bg-custom-yellow text-white font-bold rounded-lg hover:bg-custom-yellow focus:outline-none focus:ring-2 focus:ring-custom-yellow"
      >
       { t("Enteranotheraddress")}
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
            <h2 className="text-lg font-bold mb-4">أدخل العنوان</h2>
            {error && <p className="text-red-500">{error}</p>}
            {successMessage && <p className="text-green-500">{successMessage}</p>}
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-4">
                {Object.keys(formData).map((key, index) =>
                  key !== "userId" ? (
                    <div key={index}>
                      <label htmlFor={key} className="block text-sm font-medium text-gray-700">
                        {key === "fullAddress" ? "وصف العنوان بالكامل" : key}
                      </label>
                      <input
                        type={key === "fullAddress" ? "textarea" : "text"}
                        id={key}
                        name={key}
                        value={formData[key]}
                        onChange={handleChange}
                        className="mt-1 p-2 w-full border rounded-lg bg-white text-black"
                        required
                      />
                    </div>
                  ) : null
                )}
              </div>
              <div className="mt-4 flex justify-between">
                <button
                  type="submit"
                  className={`py-2 px-4 ${
                    loading ? "bg-custom-yellow" : "bg-custom-yellow hover:bg-custom-yellow"
                  } text-white font-bold rounded-lg focus:outline-none focus:ring-2 focus:ring-custom`}
                  disabled={loading}
                >
                  {loading ? "جاري الإضافة..." : "أضف العنوان"}
                </button>
                <button
                  type="button"
                  onClick={closeModal}
                  className="py-2 px-4 bg-gray-300 text-black font-bold rounded-lg hover:bg-gray-400 focus:outline-none"
                >
                  إلغاء
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomBigButton;
