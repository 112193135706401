import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import CustomLink from './customLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faWhatsapp,faTelegram,faTiktok ,faSnapchat} from '@fortawesome/free-brands-svg-icons';
import { MapPinIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

export default function FooterUser() {
  const { t } = useTranslation();
 
  return (
    <div dir="rtl" className="text-right">
      <hr className="h-0.5 border-t-0 opacity-100 dark:opacity-50" />
      <footer className="text-center lg:text-right px:custom-mobile-padding md:px-custom-padding py-4">
        <div className="mx-6">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
            <div>
              <Link to="/">
                <img src={Logo} alt="Logo" className="w-40 h-20 mb-4" />
              </Link>
              <p className="mb-6 text-sm text-big-text-color">{t('logo_description')}</p>
              <div className="flex items-center">
                <a href="https://api.whatsapp.com/send/?phone=%2B905301485665&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faWhatsapp} className="text-custom-yellow mr-5" />
                </a>
                <a href="https://www.tiktok.com/@markaship_?_t=8p7YWUFQXwm&_r=1" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faTiktok} className="text-custom-yellow mr-5" />
                </a>
                <a href="https://www.snapchat.com/add/markaship?invite_id=Ho6TnrT4&locale=de_AT&share_id=rKrnJoZITN2d05xOyPd2Bg&xp_id=1&sid=2f42ef110d4c4b2e9c44f08b6c6ee823" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faSnapchat} className="text-custom-yellow mr-5" />
                </a>
                <a href="https://www.instagram.com/markaship_?igsh=MW5pcjk1cm9mdjhrYg%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} className="text-custom-yellow mr-5" />
                </a>
          
              </div>
            </div>

            <div>
              <h6 className="mb-6 text-custom-yellow font-semibold uppercase">{t('company')}</h6>
              <div className="mb-2">
                <CustomLink to="/TermsAndConditions">{t('terms_and_conditions')}</CustomLink>
              </div>
              <div className="mb-2">
                <CustomLink to="/PrivacyPolicyPage">{t('privacy_policy')}</CustomLink>
              </div>
              <div className="mb-2">
                <CustomLink to="/CookiesPolicyPage">{t('cookies_policy')}</CustomLink>
              </div>
              <div className="mb-2">
                <CustomLink to="/QualityControlAndShipmentPhotography">{t('refund_policy')}</CustomLink>
              </div>
            </div>

            <div>
              <h6 className="mb-6 text-custom-yellow font-semibold uppercase">{t('quick_links')}</h6>
              <div className="mb-2">
                <CustomLink to="/OurServicesPage">{t('our_services')}</CustomLink>
              </div>
              <div className="mb-2">
                <CustomLink to="/ContactUsPage">{t('contact_us')}</CustomLink>
              </div>
              <div className="mb-2">
                <CustomLink to="/Faq">{t('faq')}</CustomLink>
              </div>
              <div className="mb-2">
                <CustomLink to="/ShippingInsurance">{t('shipping_insurance')}</CustomLink>
              </div>
            </div>

            <div>
              <h6 className="mb-6 text-custom-yellow font-semibold uppercase">{t('about_us')}</h6>
              <p className="text-sm text-gray-500 mb-6 flex">
                <PhoneIcon className="h-4 w-4 ml-1 text-custom-yellow" />
                <a href="tel:+447418351039" className="text-gray-500 hover:underline">+20109998831717</a>
              </p>
              <p className="text-sm text-gray-500 mb-6 flex">
                <EnvelopeIcon className="h-4 w-4 ml-1 text-custom-yellow" />
                <a href="mailto:info@example.com" className="text-gray-500 hover:underline">info@markashipp.com</a>
              </p>
              <p className="text-sm text-gray-500 mb-6 flex">
                <MapPinIcon className="h-4 w-4 ml-1 text-custom-yellow" />
                <a href="https://www.google.com/maps/search/?api=1&query=72+Gameat+Al-dewal+Al-Arabia,+Mohandessien" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:underline">
                  {t('address')}
                </a>
              </p>
            </div>

            <div>
              {/* <h6 className="mb-6 text-custom-yellow font-semibold uppercase">{t('newsletter')}</h6>
              <p className="mb-6 text-sm text-big-text-color">{t('newsletter_description')}</p> */}
              <div className="flex justify-start">
         
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="bg-custom-blue py-2 text-center text-white">
        <footer>
          <span>
            {t('footer_copyright')} 2024
            <a href="https://example.com" className="text-white hover:underline mx-1">{t('footer_brand')}</a>
            {t('footer_rights')}
            <a href="https://targetdigitalsolutions.com" className="text-white hover:underline mx-1">{t('footer_developer')}</a>
          </span>
        </footer>
      </div>
    </div>
  );
}
