import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateOrderDetails } from "../../redux/reducer/shipandShopOrdersReducer"; // Adjust the path
import { useTranslation } from "react-i18next";

const RepackagingPopup = ({ order, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.shipAndShop);

  const [repackagingData, setRepackagingData] = useState({
    orderNumber: order.orderNumber,
    zone: order.zone || "",
    weight: order.weight || 0,
    status: order.status || { en: "Repackaging", ar: "إعادة التغليف" },
    images: order.images || [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRepackagingData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateOrderDetails({ orderId: order._id, updateData: { repackagingItems: [repackagingData] } }));
    onClose(); // Close the popup after submission
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg p-6 w-1/3">
        <h2 className="text-xl font-bold mb-4">{t("Repackage Order")}</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">{t("Zone")}</label>
            <input
              type="text"
              name="zone"
              value={repackagingData.zone}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">{t("Weight")}</label>
            <input
              type="number"
              name="weight"
              value={repackagingData.weight}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">{t("Status (English)")}</label>
            <input
              type="text"
              name="status.en"
              value={repackagingData.status.en}
              onChange={(e) =>
                setRepackagingData((prev) => ({
                  ...prev,
                  status: { ...prev.status, en: e.target.value },
                }))
              }
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">{t("Status (Arabic)")}</label>
            <input
              type="text"
              name="status.ar"
              value={repackagingData.status.ar}
              onChange={(e) =>
                setRepackagingData((prev) => ({
                  ...prev,
                  status: { ...prev.status, ar: e.target.value },
                }))
              }
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              {t("Cancel")}
            </button>
            <button
              type="submit"
              className={`${
                loading ? "bg-gray-400" : "bg-blue-500"
              } text-white px-4 py-2 rounded`}
              disabled={loading}
            >
              {loading ? t("Processing...") : t("Repackage")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RepackagingPopup;
