import React from 'react';
import CustomContactUsCard from './customContactUsCard';
import ImageflagTrukey from '../../assets/images/turkey.png';
import flagErupa from '../../assets/images/oroba.png';
import flagKuait from '../../assets/images/oroba.png';
import ContactUsFormUser from './contactUsForm';
import { useTranslation } from 'react-i18next';

const DataSection = () => {
  const { t } = useTranslation();
  
  return (
    <div className='bg-white py-10 px-4 md:px-custom-padding'>
      <div className='lg:flex mb-20 space-y-2 lg:space-y-0 lg:space-x-4'>
        <div className='w-full md:w-1/3'>
          <CustomContactUsCard
            title={t("Europa")}
            FlagImage={flagErupa}
            Email='info@markaship.com'
            phoneNumberOne='+4367762009102'
       visible={false}
            Location='Amalienstrasse 28/12. Vienna, 1130 / Austria / Europa '
          />
        </div>

        <div className='w-full md:w-1/3'>
          <CustomContactUsCard
            title={t("Turkey")}
            FlagImage={ImageflagTrukey}
            Email='info@markaship.com'
            phoneNumberOne='+905331586084'
            phoneNumberTwo='+905368346085'
            visible={true}
            Location='ODUNLUK MAH. ORTAÇ SK. NO: 11 A NILÜFER/ BURSA/ Türkiye'
          />
        </div>

        <div className='w-full md:w-1/3'>
          <CustomContactUsCard
            title={t("kuwait")}
            FlagImage={flagKuait}
            phoneNumberOne='+96594449324'
            phoneNumberTwo='+96566414164'
            Email='info@markaship.com'
            visible={true}
            Location='Kuwait / محافظة الفروانية / منطقة خيطان / مبنى مجمع هوزان'
          />
        </div>
      </div>
      
      <ContactUsFormUser />
    </div>
  );
}

export default DataSection;
