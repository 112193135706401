import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BASE_URL from '../constent';
// Fetch currencies
export const getCurrencies = createAsyncThunk(
  "currencies/getCurrencies",
  async (_, thunkAPI) => {
    try {
  
      const response = await axios.get(`${BASE_URL}/currencies`,);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Slice
const currenciesSlice = createSlice({
  name: "currencies",
  initialState: {
    currencies: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Reducer to clear the error
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrencies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCurrencies.fulfilled, (state, action) => {
        state.loading = false;
        state.currencies = action.payload;
      })
      .addCase(getCurrencies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the clearError action
export const { clearError } = currenciesSlice.actions;

// Export the reducer
export default currenciesSlice.reducer;
