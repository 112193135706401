import React, { useEffect, useState } from "react";
import Steps from './customSteps';
import Banner from "./banner";
import CustomOrdersShipments from "./customCardShipment";
import TwoRowButtons from "./twoRowButtons";
import AccountAddressSection from '../accountUser/accountAddressSection';  // Assuming you have this component
import CustomBigButton from '../accountUser/customBigButton';
import ManageBoxDetials from './manageBoxDetials';
import AccountAddressBoxCard from './customAddressCard';
import { useDispatch, useSelector } from "react-redux";
import { getUserBoxOrders } from "../../redux/reducer/shipandShopOrdersReducer"; // Adjust the path

const ShipmentItems = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [isCartEmpty, setIsCartEmpty] = useState(true);  // Track cart status

  const dispatch = useDispatch();

  // Fetch user box orders from Redux store
  const { userBoxOrders, loading, error } = useSelector((state) => state.shipAndShop);

  // Fetch user orders when component mounts
  useEffect(() => {
    dispatch(getUserBoxOrders());
  }, [dispatch]);

  // Check if the cart is empty whenever the orders change
  useEffect(() => {
    if (userBoxOrders) {
      setIsCartEmpty(userBoxOrders.length === 0);
    }
  }, [userBoxOrders]);

  const handleNext = () => {
    if (activeStep < 3) setActiveStep(activeStep + 1);
  };

  const handlePrev = () => {
    if (activeStep > 1) setActiveStep(activeStep - 1);
  };

  return (
    <div>
      <Steps activeStep={activeStep} />
   
      {activeStep === 1 && 
        <div>
          <Banner />
          <CustomOrdersShipments />
        </div>
      }
      {activeStep === 2 && 
        <div>
          <AccountAddressBoxCard />
          <CustomBigButton />
        </div>
      }
      {activeStep === 3 && 
        <div>
          <ManageBoxDetials />
          <AccountAddressBoxCard />
        </div>
      }

      {/* Only show the "Next" button if the cart is not empty */}
      <TwoRowButtons
        onclickNext={handleNext}
        onclickPerv={handlePrev}
        isCartEmpty={isCartEmpty}  // Pass the cart empty state here
      />
    </div>
  );
};

export default ShipmentItems;
