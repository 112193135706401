import React from "react";
import Header from "../component/sharedComponents/header";
import FooterUser from "../component/sharedComponents/footer";
import RegistrationForm from "../component/auth/registerationForm";
const RegistrationPage =()=>{
    return(
        <div>
            <Header/>
          <div className="mt-20">
          <RegistrationForm/>
          </div>
            <FooterUser/>
        </div>
    )
}

export default RegistrationPage;