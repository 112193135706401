import React from 'react';
import CustomLines from './customLines';
import CustomBigText from '../sharedComponents/customBigText';
import CustomCardImportantServices from './customCardImportantServices';
import imageOne from '../../assets/images/imageoneimp.png';
import imageTwo from '../../assets/images/imagetwoimp.png';
import imageThree from '../../assets/images/imagethreeimp.png';
import { useTranslation } from 'react-i18next';
const ImportantServices = () => {
  const {t} =useTranslation();
  return (
    <div className="bg-white flex flex-col items-center justify-center text-center px-4 pb-5
    lg:px-custom-padding ">
      <CustomLines />
      <CustomBigText title={t('importantServices.title')} />    
        <p className="mt-4 text-gray-700">
        {t('importantServices.description')}   
           </p>
      <div className="lg:flex  items-center gap-6 w-full mb-10 mt-5 ">
  <div className="w-full sm:w-1/2 lg:w-1/3 mb-2">
    <CustomCardImportantServices image={imageOne}   title={t('importantServices.card1Title')}/>
  </div>
  <div className="w-full sm:w-1/2 lg:w-1/3 mb-2">
    <CustomCardImportantServices image={imageTwo} title={t('importantServices.card2Title')} />
  </div>
  <div className="w-full sm:w-1/2 lg:w-1/3 mb-2">
    <CustomCardImportantServices image={imageThree} title={t('importantServices.card3Title')}/>
  </div>
</div>

    </div>
  );
};

export default ImportantServices;
