import React ,{useState} from 'react';
import CustomTitle from '../sharedComponents/customTitle';
import CustomHowItWorks from '../explainBuyPage/howItWorksCard';
import CustomArrowLeft from '../sharedComponents/customArrowLeft';
import ImageOne from '../../assets/images/buyOne.png';
import ImageTwo from '../../assets/images/buyTwo.png';
import ImageThree from '../../assets/images/buyThree.png';
import ImageFour from '../../assets/images/buyFour.png';
import ImageFive from '../../assets/images/buyFive.png';
import ImageSix from '../../assets/images/buysix.png';
import CustomArrowRight from '../sharedComponents/customArrowRight';
import { useTranslation } from 'react-i18next';
const CountOrdersSection =()=>{
    const [isVisible, setIsVisible] = useState(true);
   const {t} =useTranslation();
    const toggleVisibility = () => {
        setIsVisible((prev) => !prev);
    };
    return (
        <div className='bg-white px-custom-padding'>
<CustomTitle title={t('countOrdersSection.title')} />
<div className="flex w-full justify-center items-center space-x-20 mt-10">
  <div className="w-1/3 flex justify-center">
    <CustomHowItWorks
      image={ImageOne}
      title={t('countOrdersSection.step1')}
      visible={isVisible}
    />
  </div>
  <div className="flex justify-center items-center">
    <CustomArrowLeft />
  </div>
  <div className="w-1/3 flex justify-center">
    <CustomHowItWorks
      image={ImageTwo}
      title={t('countOrdersSection.step2')}
    />
  </div>
  <div className="flex justify-center items-center">
    <CustomArrowLeft />
  </div>
  <div className="w-1/3 flex justify-center">
    <CustomHowItWorks
      image={ImageThree}
      title={t('countOrdersSection.step3')}
    />
  </div>
</div>

<div className="flex w-full justify-center items-center space-x-20 mt-5">
  <div className="w-1/3 flex justify-center">
    <CustomHowItWorks
      image={ImageFour}
      title={t('countOrdersSection.step4')}
       />
  </div>
  <div className="flex justify-center items-center">
    <CustomArrowRight />
  </div>
  <div className="w-1/3 flex justify-center">
    <CustomHowItWorks
      image={ImageFive}
      title={t('countOrdersSection.step5')}
    />
  </div>
  <div className="flex justify-center items-center">
    <CustomArrowRight />
  </div>
  <div className="w-1/3 flex justify-center">
    <CustomHowItWorks
      image={ImageSix}
      title={t('countOrdersSection.step6')}
    />
  </div>


</div>


        </div>
    )
}
export default CountOrdersSection;