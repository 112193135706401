import React from 'react';
import CustomBigText from './customBigText';
const CustomTitle =({title})=>{
    return (
        <div className="flex items-center ">
  <div className="bg-black h-1 flex-1"></div>
  <CustomBigText title={title} className="mx-4" />
  <div className="bg-black h-1 flex-1"></div>
</div>
    )
}

export default CustomTitle;